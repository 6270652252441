import { Navigate, useLocation } from "react-router-dom";
import { useData } from "../contex/contex";
import Loading from "../Components/Loading";

function PrivateRoute({ children, requiredRoles }) {
	const location = useLocation();
	const { user, loading } = useData();
	if (loading) {
		return <Loading isLoading={loading} />;
	}

	if (user && requiredRoles?.some((role) => user?.userRole === role)) {
		return children;
	}
	return <Navigate to='/login' state={{ from: location }} />;
}

export default PrivateRoute;
