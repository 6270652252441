import { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import Swal from "sweetalert2";

const DataContext = createContext();

export function useData() {
	const context = useContext(DataContext);
	if (!context) {
		throw new Error(`Context must be used within a DataProvider`);
	}
	return context;
}

export function DataProvider({ children }) {
	const [user, setUser] = useState({});
	const [loading, setLoading] = useState(true);
	const loggedInUser =
		Cookies.get("user")?.length > 0 ? JSON.parse(Cookies.get("user")) : null;

	const [token, setToken] = useState(loggedInUser?.token || null);

	useEffect(() => {
		if (
			user?.role &&
			loggedInUser?.userRole &&
			loggedInUser?.userRole !== user?.userRole
		) {
			Cookies.remove("user");
			setUser({});
			setToken(null);
		}
	}, [loggedInUser, user]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response =
					loggedInUser &&
					(await axios.get(
						`${process.env.REACT_APP_API_PATH}/singleUser/${loggedInUser?.email}`,
						{
							headers: {
								Authorization: loggedInUser?.token,
							},
						},
					));

				setUser(response.data);
				setToken(loggedInUser?.token);
				setLoading(false);
			} catch (error) {
				console.error("Error fetching user:", error);
				if (
					window.location.pathname === "/login" ||
					window.location.pathname.includes("/dashboard")
				) {
					Swal.fire(
						"Opps!",
						error?.response?.data?.message === "jwt expired"
							? "Your session has expired, please login again"
							: error?.response?.data?.message,
						"error",
					);
				}
				setLoading(false);
			}
		};
		loading && fetchData();
	}, [loggedInUser, loading]);

	return (
		<DataContext.Provider
			value={{
				user,
				loading,
				token,
				setLoading,
			}}>
			{children}
		</DataContext.Provider>
	);
}
