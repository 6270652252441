import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import RateReviewIcon from "@mui/icons-material/RateReview";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useForm } from "react-hook-form";
import { Backdrop, CircularProgress, Rating } from "@mui/material";
import axios from "axios";
import Swal from "sweetalert2";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { useState } from "react";
import { styled } from "@mui/material/styles";

const StyledRating = styled(Rating)({
	"& .MuiRating-iconFilled": {
		color: "#ff6d75",
	},
	"& .MuiRating-iconHover": {
		color: "#ff3d47",
	},
});

export default function AddReviews() {
	const [submitting, setSubmitting] = useState(false);
	const [value, setValue] = useState(2);

	const { register, handleSubmit } = useForm();
	const onSubmit = async (data) => {
		setSubmitting(true);
		const userData = { ...data, postedOn: new Date(), rating: value };
		try {
			const response = await axios.post(
				`${process.env.REACT_APP_API_PATH}/reviews`,
				userData,
			);
			if (response.status) {
				Swal.fire({
					icon: "success",
					title: "Success",
					text: "Review Added Successfully",
				});
			}
			setSubmitting(false);
			window.location.href = "/";
		} catch (error) {
			Swal.fire({
				icon: "error",
				title: "Error",
				text: error.response.data.message,
			});
		}
	};
	return (
		<Container
			component='main'
			maxWidth='xs'
			sx={{
				minHeight: "50vh",
			}}>
			<CssBaseline />
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
				}}>
				<Avatar sx={{ m: 1 }} className='buttonColor'>
					<RateReviewIcon />
				</Avatar>
				<Typography component='h1' variant='h5'>
					Add Review
				</Typography>
				<form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: 3 }}>
					<TextField
						size='small'
						margin='normal'
						required
						fullWidth
						id='displayName'
						label='Your Name'
						name='displayName'
						autoComplete='displayName'
						autoFocus
						{...register("displayName", { required: true })}
					/>
					<TextField
						size='small'
						margin='normal'
						fullWidth
						id='phone'
						label='Phone Number (Optional)'
						name='phone'
						autoComplete='phone'
						autoFocus
						{...register("phone")}
					/>
					<TextField
						size='small'
						margin='normal'
						fullWidth
						id='email'
						label='Email Address (Optional)'
						name='email'
						autoComplete='email'
						autoFocus
						{...register("email")}
					/>
					<StyledRating
						name='simple-controlled'
						value={value}
						onChange={(event, newValue) => {
							setValue(newValue);
						}}
						precision={0.5}
						icon={
							<FavoriteIcon
								sx={{
									fontSize: "40px",
								}}
							/>
						}
						emptyIcon={
							<FavoriteBorderIcon
								sx={{
									fontSize: "40px",
								}}
							/>
						}
					/>
					<TextField
						multiline
						rows={4}
						size='small'
						margin='normal'
						required
						fullWidth
						id='review'
						label='Review'
						name='review'
						autoComplete='review'
						autoFocus
						{...register("review", { required: true })}
					/>
					<Button
						type='submit'
						fullWidth
						variant='contained'
						className='buttonColor'
						sx={{
							my: 2,
						}}>
						Add Review
					</Button>
				</form>
			</Box>
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={submitting}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</Container>
	);
}
