import React from "react";
import { reactLocalStorage } from "reactjs-localstorage";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const OptionCounter = ({ label, count, setCount, isChecked, setChecked }) => {
	return (
		<div
			style={{
				display: "flex",
				alignItems: "center",
				justifyContent: "space-between",
			}}>
			<label style={{ fontSize: "14px" }}>{label}</label>
			<div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
				<button onClick={() => setCount(Math.max(count - 1, 0))}>
					<RemoveIcon />
				</button>
				<span>{count}</span>
				<button onClick={() => setCount(count + 1)}>
					<AddIcon />
				</button>
				<input
					type='checkbox'
					checked={isChecked}
					onChange={(e) => setChecked(e.target.checked)}
					style={{ marginLeft: "8px" }}
				/>
			</div>
		</div>
	);
};

const ExtraOptions = ({ setStep }) => {
	const savedData = reactLocalStorage.getObject("importantOpions");
	const [passengersCount, setPassengersCount] = React.useState(
		savedData.passengersCount || 1,
	);
	const [passengers, setPassengers] = React.useState(
		savedData.passengers || true,
	);
	const [luggageCount, setLuggageCount] = React.useState(
		savedData.luggageCount || 0,
	);
	const [luggage, setLuggage] = React.useState(savedData.luggage || false);
	const [wheelchairCount, setWheelchairCount] = React.useState(
		savedData.wheelchairCount || 0,
	);
	const [wheelchair, setWheelchair] = React.useState(
		savedData.wheelchair || false,
	);
	const [babyCount, setBabyCount] = React.useState(savedData.babyCount || 0);
	const [babySeat, setBabySeat] = React.useState(savedData.babySeat || false);
	const [carSeatCount, setCarSeatCount] = React.useState(
		savedData.carSeatCount || 0,
	);
	const [carSeat, setCarSeat] = React.useState(savedData.carSeat || false);

	const saveAndGo = () => {
		const data = {
			passengers,
			passengersCount,
			luggage,
			luggageCount,
			wheelchair,
			wheelchairCount,
			babySeat,
			babyCount,
			carSeat,
			carSeatCount,
		};
		reactLocalStorage.setObject("importantOpions", data);
		setStep("totalsummary");
		reactLocalStorage.setObject("quickBookingStep", "totalsummary");
	};

	return (
		<div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
			<OptionCounter
				label='Passengers'
				count={passengersCount}
				setCount={setPassengersCount}
				isChecked={passengers}
				setChecked={setPassengers}
			/>
			<OptionCounter
				label='Luggage'
				count={luggageCount}
				setCount={setLuggageCount}
				isChecked={luggage}
				setChecked={setLuggage}
			/>
			<OptionCounter
				label='Child Seat'
				count={babyCount}
				setCount={setBabyCount}
				isChecked={babySeat}
				setChecked={setBabySeat}
			/>
			<OptionCounter
				label='Wheelchair'
				count={wheelchairCount}
				setCount={setWheelchairCount}
				isChecked={wheelchair}
				setChecked={setWheelchair}
			/>
			<OptionCounter
				label='Car Seat'
				count={carSeatCount}
				setCount={setCarSeatCount}
				isChecked={carSeat}
				setChecked={setCarSeat}
			/>

			{/* Navigation Buttons */}
			<div
				style={{
					marginTop: "10px",
					position: "fixed",
					bottom: 0,
					left: "50%",
					transform: "translateX(-50%)",
					width: "100%",
					padding: "10px",
					backgroundColor: "#80808078",
					borderTop: "3px solid #80808078",
				}}>
				<div
					style={{
						maxWidth: "900px",
						margin: "0 auto",
						display: "flex",
						justifyContent: "space-between",
					}}>
					<button
						type='button'
						onClick={() => {
							setStep("booking");
							localStorage.setItem("quickBookingStep", "booking");
						}}
						style={{
							padding: "10px",
							backgroundColor: "black",
							color: "white",
							border: "none",
							borderRadius: "4px",
							cursor: "pointer",
						}}>
						&#8592; Back
					</button>
					<button
						type='button'
						style={{
							padding: "10px",
							backgroundColor: "black",
							color: "white",
							border: "none",
							borderRadius: "4px",
							cursor: "pointer",
						}}
						onClick={() => {
							reactLocalStorage.clear("vehicles");
							reactLocalStorage.clear("rideDetails");
							reactLocalStorage.clear("contactDetails");
							reactLocalStorage.clear("importantOpions");
							reactLocalStorage.clear("client");
							reactLocalStorage.clear("quickBookingStep");
							window.location.href = "/dashboard/QuickBooking";
						}}>
						Reset  &#x21bb;
					</button>
					<button
						onClick={saveAndGo}
						style={{
							padding: "10px",
							backgroundColor: "black",
							color: "white",
							border: "none",
							borderRadius: "4px",
							cursor: "pointer",
						}}>
						Summary &#8594;
					</button>
				</div>
			</div>
		</div>
	);
};

export default ExtraOptions;
