import React from "react";
import Vehicles from "./Vehicles";
import ExtraOptions from "./ExtraOptions";

const ChooseVehicle = ({ setStep, cars }) => {
	return (
		<div style={{ maxWidth: "700px", margin: "0 auto" }}>
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					marginBottom: "50px",
					padding: "10px",
				}}>
				<div style={{ flex: "1 1 75%", marginBottom: "16px" }}>
					<div
						style={{
							width: "100%",
							padding: "12px 0",
							backgroundColor: "#f1f1f1",
							textAlign: "center",
						}}>
						<span
							style={{ fontSize: "16px", fontWeight: "500", color: "#333" }}>
							PASSENGERS AND LUGGAGE
						</span>
					</div>

					<div style={{ width: "100%" }}>
						<Vehicles cars={cars} />
					</div>
					<div
						style={{
							width: "100%",
							padding: "12px 0",
							margin: "7px 0",
							backgroundColor: "#f1f1f1",
							textAlign: "center",
						}}>
						<span
							style={{ fontSize: "16px", fontWeight: "500", color: "#333" }}>
							Important options
						</span>
					</div>
					<ExtraOptions setStep={setStep} />
				</div>
			</div>
		</div>
	);
};

export default ChooseVehicle;
