import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import Cookies from "js-cookie";
import { useData } from "../../contex/contex";
import { useEffect } from "react";

const drawerWidth = 222;

function DriverDashboard(props) {
	const { token } = useData();
	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		if (!token) {
			navigate("/login", { state: { from: location } });
		}
	}, [token, location, navigate]);

	return (
		<Box sx={{ display: "flex" }}>
			<CssBaseline />
			<AppBar
				position='fixed'
				className='bgColor'
				sx={{
					width: "100%",
				}}>
				<Toolbar>
					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							gap: 2,
							width: "100%",
						}}>
						<Link
							style={{
								textDecoration: "none",
								fontWeight: "bold",
								fontSize: "15px",
								color: "white",
							}}
							to=''>
							<Button
								className={
									location?.pathname === "/driverDashboard" && "activeBorder"
								}
								sx={{
									my: 2,
									color: "#A2A2A2",
									":hover": { color: "white" },
									display: "block",
									fontWeight: "bold",
								}}>
								Profile
							</Button>
						</Link>

						<Link
							style={{
								textDecoration: "none",
								fontWeight: "bold",
								fontSize: "15px",
								color: "white",
							}}
							to='bookings'>
							<Button
								className={
									location?.pathname === "/driverDashboard/bookings" &&
									"activeBorder"
								}
								sx={{
									my: 2,
									color: "#A2A2A2",
									":hover": { color: "white" },
									display: "block",
									fontWeight: "bold",
								}}>
								Assigned
							</Button>
						</Link>
						<Button
							variant='contained'
							disableElevation
							sx={{
								backgroundColor: "#a93939",
								color: "white",
								fontWeight: "bold",
								"&:hover": {
									backgroundColor: "#a93939",
								},
								height: 40,
								borderRadius: 15,
								mx: 1,
							}}
							onClick={() => {
								Cookies.remove("user");
								navigate("/login", { state: { from: location } });
							}}>
							LogOut
						</Button>
					</Box>
				</Toolbar>
			</AppBar>

			<Box
				component='main'
				sx={{
					flexGrow: 1,
					width: { sm: `calc(100% - ${drawerWidth}px)` },
				}}>
				<Toolbar />
				<Outlet></Outlet>
			</Box>
		</Box>
	);
}

DriverDashboard.propTypes = {
	window: PropTypes.func,
};

export default DriverDashboard;
