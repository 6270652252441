import React, { useEffect, useMemo, useState } from "react";
import { reactLocalStorage } from "reactjs-localstorage";

const CustomerTypeQuickBooking = ({
	setStep,
	companyList,
	allUserList,
	user,
}) => {
	const generateTransactionNumber = () => {
		const part1 = Math.floor(10000 + Math.random() * 90000).toString();
		const part2 = Math.floor(1000 + Math.random() * 9000).toString();
		const part3 = "RT" + Math.floor(100 + Math.random() * 900).toString();
		return `HST # ${part1} ${part2} ${part3}`;
	};
	const id = useMemo(generateTransactionNumber, []);
	const [value, setValue] = useState(null);
	const [whom, setWhom] = useState(
		reactLocalStorage.getObject("forWhom").forWhom || "Booking for myself",
	);
	const [who, setWho] = useState(
		reactLocalStorage.getObject("forWho").forWho || "",
	);
	const [company, setCompany] = useState(
		reactLocalStorage.getObject("company").company || "",
	);
	const [txn, setTxn] = useState("");

	useEffect(() => {
		const data = { forWhom: whom };
		reactLocalStorage.setObject("forWhom", data);
	}, [whom]);

	useEffect(() => {
		const data = { forWho: who };
		reactLocalStorage.setObject("forWho", data);
	}, [who]);

	useEffect(() => {
		const data = { company: company };
		reactLocalStorage.setObject("company", data);
	}, [company]);

	useEffect(() => {
		const randomNumber = Math.floor(Math.random() * 90000) + 10000;
		setTxn(who === "A Company" ? "COM" + randomNumber : "PER" + randomNumber);
	}, [who]);

	const submit = async () => {
		const forWhom = reactLocalStorage.getObject("forWhom");
		const forWho = reactLocalStorage.getObject("forWho");
		const companyData = reactLocalStorage.getObject("company");
		const companyAdmin = companyList.find(
			(c) => c.name === companyData.company,
		)?.companyAdmin;

		const data = {
			...forWhom,
			...forWho,
			...companyData,
			txn,
			hst: id,
			bookedBy:
				whom === "Booking on behalf"
					? company
						? companyAdmin
						: value?.email
					: user?.email,
			bookedHelper: user?.email,
		};

		reactLocalStorage.setObject("client", data);
		setStep("contact");
		reactLocalStorage.setObject("quickBookingStep", "contact");
	};

	return (
		<div
			style={{
				padding: "10px",
				fontFamily: "Arial, sans-serif",
				maxWidth: "350px",
				margin: "auto",
				color: "black",
			}}>
			<div
				style={{
					width: "100%",
					height: "90vh",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					flexDirection: "column",
				}}>
				<h2 style={{ textAlign: "center", margin: "10px 0" }}>
					Click on the button below to continue booking
				</h2>
				<button
					disabled={
						(whom === "Booking on behalf" && (company || value)) ||
							whom === "Booking for myself"
							? false
							: true
					}
					onClick={submit}
					style={{
						padding: "15px 10px",
						cursor: "pointer",
						borderRadius: "3px",
						border: "none",
						backgroundColor:
							(whom === "Booking on behalf" && (company || value)) ||
								whom === "Booking for myself"
								? "black"
								: "#ccc",
						color: "white",
						fontSize: "21px",
						width: "100%",
					}}>
					Continue Booking &#8594;
				</button>
				<button
					type='button'
					style={{
						padding: "10px",
						backgroundColor: "black",
						color: "white",
						border: "none",
						borderRadius: "4px",
						cursor: "pointer",
						marginTop: "10px",
					}}
					onClick={() => {
						reactLocalStorage.clear("vehicles");
						reactLocalStorage.clear("rideDetails");
						reactLocalStorage.clear("contactDetails");
						reactLocalStorage.clear("importantOpions");
						reactLocalStorage.clear("client");
						reactLocalStorage.clear("quickBookingStep");
						window.location.href = "/dashboard/QuickBooking";
					}}>
					Reset &#x21bb;
				</button>
			</div>
			<div
				style={{
					display: "none",
				}}>
				<h3 style={{ textAlign: "center", margin: "10px 0" }}>Booking For?</h3>
				<div style={{ marginBottom: "10px" }}>
					<label
						style={{
							display: "flex",
							alignItems: "center",
							marginBottom: "10px",
						}}>
						<input
							type='checkbox'
							checked={whom === "Booking for myself"}
							onChange={() => {
								setWhom("Booking for myself");
								setWho("");
							}}
						/>
						<span style={{ marginLeft: "5px" }}>Booking for myself</span>
					</label>
					<label
						style={{
							display: "flex",
							alignItems: "center",
							marginBottom: "10px",
						}}>
						<input
							type='checkbox'
							checked={whom === "Booking on behalf"}
							onChange={() => {
								setWhom("Booking on behalf");
								setWho("");
							}}
						/>
						<span style={{ marginLeft: "5px" }}>Booking on behalf</span>
					</label>
				</div>
				{whom === "Booking on behalf" && (
					<div>
						<h3 style={{ textAlign: "center", margin: "10px 0" }}>You Are?</h3>
						<div style={{ marginBottom: "10px" }}>
							<label
								style={{
									display: "flex",
									alignItems: "center",
									marginBottom: "10px",
								}}>
								<input
									type='checkbox'
									checked={who === "A Person"}
									onChange={() => {
										setWho("A Person");
										setCompany("");
									}}
								/>
								<span style={{ marginLeft: "5px" }}>Are you a person?</span>
							</label>
							<label
								style={{
									display: "flex",
									alignItems: "center",
									marginBottom: "10px",
								}}>
								<input
									type='checkbox'
									checked={who === "A Company"}
									onChange={() => {
										setWho("A Company");
										setValue("");
									}}
								/>
								<span style={{ marginLeft: "5px" }}>Are You a company?</span>
							</label>
						</div>
					</div>
				)}
				{who === "A Company" ? (
					<div>
						<h3 style={{ textAlign: "center", margin: "10px 0" }}>
							Select Company
						</h3>
						<select
							style={{
								padding: "5px",
								width: "100%",
								borderRadius: "3px",
								border: "1px solid #ccc",
								marginBottom: "10px",
							}}
							onChange={(e) => {
								setCompany(e.target.value);
								reactLocalStorage.setObject("company", {
									company: e.target.value,
								});
							}}>
							<option value=''>Select Company</option>
							{companyList.map((company) => (
								<option key={company._id} value={company.name}>
									{company.name}
								</option>
							))}
						</select>
					</div>
				) : who === "A Person" ? (
					<div>
						<h3 style={{ textAlign: "center", margin: "10px 0" }}>
							Select Person or Enter Email
						</h3>
						<input
							type='text'
							list='userList'
							value={value?.email || ""}
							onChange={(e) => setValue({ email: e.target.value })}
							style={{
								padding: "5px",
								width: "100%",
								borderRadius: "3px",
								border: "1px solid #ccc",
								marginBottom: "10px",
							}}
						/>
						<datalist id='userList'>
							{allUserList.map((user) => (
								<option key={user._id} value={user.email}>
									{user.displayName}
								</option>
							))}
						</datalist>
					</div>
				) : null}
			</div>

			<div
				style={{
					display: "none",
					marginTop: "10px",
					position: "fixed",
					bottom: 0,
					left: "50%",
					transform: "translateX(-50%)",
					width: "100%",
					padding: "10px",
					backgroundColor: "#80808078",
					borderTop: "3px solid #80808078",
				}}>
				<div
					style={{
						maxWidth: "900px",
						margin: "0 auto",
						display: "flex",
						justifyContent: "center",
					}}>
					<button
						disabled={
							(whom === "Booking on behalf" && (company || value)) ||
								whom === "Booking for myself"
								? false
								: true
						}
						onClick={submit}
						style={{
							padding: "10px",
							cursor: "pointer",
							borderRadius: "3px",
							border: "none",
							backgroundColor:
								(whom === "Booking on behalf" && (company || value)) ||
									whom === "Booking for myself"
									? "black"
									: "#ccc",
							color: "white",
							fontSize: "14px",
						}}>
						Continue Booking &#8594;
					</button>
				</div>
			</div>
		</div>
	);
};

export default CustomerTypeQuickBooking;
