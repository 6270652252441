import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import axios from "axios";
import { Backdrop, CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";

export default function ResetPass() {
    const [submitting, setSubmitting] = React.useState(false);
    const { register, handleSubmit, watch, reset } = useForm();
    const { token } = useParams();

    const onSubmit = async (data) => {
        setSubmitting(true);
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_PATH}/resetPassword`,
                { ...data, token },
            );

            if (response.status === 200) {
                Swal.fire({
                    icon: "success",
                    title: "Success",
                    text: "Password has been reset successfully.",
                });
                reset();
                window.location.href = "/login";
            }
            setSubmitting(false);
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: error.response.data.message,
            });
            setSubmitting(false);
        }
    };

    return (
        <Container component='main' maxWidth='xs'>
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}>
                <Avatar sx={{ m: 1 }} className='buttonColor'>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component='h1' variant='h5'>
                    Reset Password
                </Typography>
                <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: 3 }}>
                    <TextField
                        margin='normal'
                        required
                        fullWidth
                        name='password'
                        label='New Password'
                        type='password'
                        id='password'
                        autoComplete='new-password'
                        {...register("password", { required: true })}
                    />
                    <TextField
                        margin='normal'
                        required
                        fullWidth
                        name='confirmPassword'
                        label='Confirm Password'
                        type='password'
                        id='confirmPassword'
                        autoComplete='new-password'
                        {...register("confirmPassword", {
                            validate: (value) =>
                                value === watch("password") || "Passwords do not match",
                        })}
                    />
                    <Button
                        type='submit'
                        fullWidth
                        variant='contained'
                        className='buttonColor'
                        sx={{
                            my: 2,
                        }}>
                        Reset Password
                    </Button>
                </form>
            </Box>
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={submitting}>
                <CircularProgress color='inherit' />
            </Backdrop>
        </Container>
    );
}
