import { CardMedia, Container, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import GetData from "../../GetData";

const OurServices = () => {
	const { service } = useParams();
	const { services } = GetData();
	const [selectService, setSelectService] = useState();
	useEffect(() => {
		setSelectService(services?.find((a) => a?.service === service));
	}, [services, service]);
	return (
		<Container>
			<Typography
				data-aos='fade-right'
				sx={{ fontWeight: "bold", mb: 2 }}
				className='textColor'
				variant='h4'
				component='div'
				gutterBottom>
				{selectService?.service}
			</Typography>
			<CardMedia
				data-aos='fade-left'
				component='img'
				image={selectService?.image}
				alt=''
				sx={{
					width: { md: "70%", sm: "80%", xs: "100%" },
					mx: "auto",
				}}
			/>
			<Typography
				data-aos='fade-right'
				sx={{ my: 3, textAlign: "justify" }}
				className='textColor'
				variant='subtitle1'
				component='div'
				gutterBottom>
				{selectService?.details}
			</Typography>
		</Container>
	);
};

export default OurServices;
