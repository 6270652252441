import { Box, CardMedia, Container, Grid, Typography } from "@mui/material";
import React from "react";
import images from "../ImportImage";

const Services = () => {
	const allPlaces = [
		{
			id: 1,
			name: "DETROIT AIRPORT LIMOUSINE",
			title: "Detroit Metro Airport Pickup & Drop Off Services",
			image: images?.detroit,
			animation: "flip-left",
		},
		{
			id: 2,
			name: "WEDDING LIMOUSINE",
			title: "Make YOur Day Memorable",
			image: images?.weeding,
			animation: "flip-up",
		},
		{
			id: 3,
			name: "PROM LIMOUSINE & HOMECOMING",
			title: "Want to ride in style?",
			image: images?.prom2,
			animation: "flip-right",
		},
		{
			id: 4,
			name: "METRO LIMOUSINE SERVICE DETROIT",
			title: "Affordable Transportation",
			image: images?.metro,
			animation: "flip-right",
		},
	];
	return (
		<Container sx={{ py: 7 }}>
			<Typography
				sx={{
					fontWeight: "bold",
					textAlign: "center",
				}}
				className='textColor'
				variant='h4'
				gutterBottom>
				LIMO SERVICES IN DETROIT
			</Typography>
			<Typography
				sx={{
					textAlign: "center",
					color: "gray",
					mb: 5,
				}}
				variant='body1'
				gutterBottom>
				Check out our luxury and affordable limousine rental services for
				several occasions and pick & drop from one place to another with a wide
				range of luxury vehicles. In case of any query or limo reservation or
				lomousine rates, feel free to contact us.
			</Typography>
			<Grid container spacing={2}>
				{allPlaces?.map((place, i) => (
					<Grid key={i} item xs={12} md={3} data-aos={place?.animation}>
						<Box
							sx={{
								overflow: "hidden",
							}}>
							<CardMedia
								component='img'
								image={place?.image}
								alt=''
								sx={{
									width: "100%",
									transition: "transform .5s",
									"&:hover": {
										transform: "scale(1.1)",
										transition: "transform .5s",
									},
								}}
							/>
						</Box>
						<Typography
							sx={{
								fontWeight: "bold",
								mt: 1,
								textAlign: "center",
							}}
							className='textColor'
							variant='h6'
							gutterBottom>
							{place?.name.toUpperCase()}
						</Typography>
						<Typography
							sx={{
								textAlign: "center",
								color: "gray",
							}}
							variant='body1'
							gutterBottom>
							{place?.title}
						</Typography>
					</Grid>
				))}
			</Grid>
		</Container>
	);
};

export default Services;
