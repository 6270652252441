import { Button, Container, Divider, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { Link } from "react-router-dom";
import video from "../Files/Elegance-limousine.mp4";

const AboutUs = () => {
	return (
		<Box
			className='VideoConatiner'
			sx={{
				position: "relative",
				overflow: "hidden",
			}}>
			<Box
				className='VideoBottomConatiner'
				sx={{
					py: 7,
				}}>
				<Box
					sx={{
						overflow: "hidden",
						width: "100%",
						height: 400,
					}}>
					<video
						width='100%'
						autoPlay
						muted
						loop
						style={{ height: "100%", objectFit: "cover" }}>
						<source src={video} type='video/mp4' />
					</video>
				</Box>
			</Box>
			<Container
				className='videoTopContainer'
				sx={{
					position: "absolute",
					left: "50%",
					top: "50%",
					transform: "translate(-50%, -50%)",
					textAlign: "left",
				}}>
				<Typography
					sx={{ fontWeight: "bold", color: "white" }}
					variant='h4'
					component='div'
					gutterBottom>
					ABOUT US
				</Typography>
				<Divider sx={{ border: "1px solid #E2C474", width: 200 }} />
				<Typography
					sx={{ color: "white", py: 1 }}
					variant='subtitle2'
					component='div'
					gutterBottom>
					Part of going to the even is getting there and back. You can view this
					as simple as getting from point A to B, or you can view this as it’s
					own experience that can be as fun or better as the event you’re going
					to. Elegance Limousine services is in the latter of the two examples.
					We do not provide you with a service, we provide you an experience.
				</Typography>
				<Link to='/startBooking' style={{ textDecoration: "none" }}>
					<Button
						variant='contained'
						disableElevation
						sx={{
							backgroundColor: "#E5E5E5",
							color: "#292929",
							fontWeight: "bold",
							"&:hover": {
								backgroundColor: "#E5E5E5",
							},
							height: 50,
							borderRadius: 1,
						}}>
						Book Now
					</Button>
				</Link>
			</Container>
		</Box>
	);
};

export default AboutUs;
