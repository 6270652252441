import * as React from "react";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import {
	Button,
	Container,
	FormControl,
	FormHelperText,
	Grid,
	MenuItem,
	Select,
} from "@mui/material";
import { useForm } from "react-hook-form";
import axios from "axios";
import Swal from "sweetalert2";

export default function Tab1({ data, token, id, handleClose }) {
	const [dateTime, setDateTime] = React.useState(new Date(data?.time1 || ""));

	const handleChange = (newValue) => {
		setDateTime(newValue);
	};

	const [extraHour, setExtraHour] = React.useState(data?.extraTime1 || "");

	const handleExtraHourChange = (event) => {
		setExtraHour(event.target.value);
	};
	const { register, handleSubmit, reset } = useForm({
		defaultValues: {
			picupLocation1: "",
			dropOffLocation1: "",
		},
	});
	React.useEffect(() => {
		reset(data);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const onSubmit = ({ picupLocation1, dropOffLocation1 }) => {
		const data = {
			time1: dateTime,
			picupLocation1,
			dropOffLocation1,
			extraTime1: extraHour,
			rideType: "Distance",
			tab: 0,
		};
		axios
			.patch(
				`${process.env.REACT_APP_API_PATH}/bookings/${id}`,
				{
					...data,
				},
				{
					headers: {
						Authorization: token,
					},
				},
			)
			.then((res) => {
				Swal.fire({
					icon: "success",
					title: "Success",
					text: res.data.message,
				});
				handleClose();
			})
			.catch((err) => {
				Swal.fire({
					icon: "error",
					title: "Error",
					text: err.response.data.message,
				});
			});
	};
	return (
		<Container>
			<form onSubmit={handleSubmit(onSubmit)}>
				<LocalizationProvider dateAdapter={AdapterDateFns}>
					<Grid container spacing={2}>
						<Grid item md={6} xs={12}>
							<FormHelperText>PICKUP DATE</FormHelperText>
							<DesktopDatePicker
								inputFormat='MM/dd/yyyy'
								value={dateTime}
								onChange={handleChange}
								renderInput={(params) => (
									<TextField required fullWidth {...params} />
								)}
							/>
						</Grid>

						<Grid item md={6} xs={12}>
							<FormHelperText>PICKUP TIME</FormHelperText>
							<TimePicker
								value={dateTime}
								onChange={handleChange}
								ampm={false}
								renderInput={(params) => <TextField fullWidth {...params} />}
							/>
						</Grid>
						<Grid item md={12} xs={12}>
							<FormHelperText>PICKUP LOCATION</FormHelperText>
							<TextField
								fullWidth
								id='filled-search'
								placeholder='ENTER A LOCATION'
								type='standard'
								{...register("picupLocation1", { required: true })}
							/>
						</Grid>
						<Grid item md={12} xs={12}>
							<FormHelperText>DROP-OFF LOCATION</FormHelperText>
							<TextField
								fullWidth
								id='filled-search'
								placeholder='ENTER A LOCATION'
								type='standard'
								{...register("dropOffLocation1", { required: true })}
							/>
						</Grid>
						<Grid item md={12} xs={12}>
							<FormControl fullWidth>
								<FormHelperText sx={{ ml: 0 }}>EXTRA TIME</FormHelperText>
								<Select
									value={extraHour}
									onChange={handleExtraHourChange}
									displayEmpty
									inputProps={{ "aria-label": "Without label" }}>
									<MenuItem value=''>
										<em>0 HOUR(S)</em>
									</MenuItem>
									<MenuItem value={"1 HOUR"}>1 HOUR(S)</MenuItem>
									<MenuItem value={"2 HOUR"}>2 HOUR(S)</MenuItem>
									<MenuItem value={"3 HOUR"}>3 HOUR(S)</MenuItem>
									<MenuItem value={"4 HOUR"}>4 HOUR(S)</MenuItem>
									<MenuItem value={"5 HOUR"}>5 HOUR(S)</MenuItem>
								</Select>
							</FormControl>
						</Grid>
						<Button
							type='submit'
							className='buttonColor'
							variant='contained'
							sx={{ py: 1.7, mt: 2 }}>
							Update Booking
						</Button>
					</Grid>
				</LocalizationProvider>
			</form>
		</Container>
	);
}
