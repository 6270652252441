import React, { useEffect, useState } from "react";
import { reactLocalStorage } from "reactjs-localstorage";
import axios from "axios";
import Swal from "sweetalert2";
import BeatLoader from "react-spinners/BeatLoader";
import { Box } from "@mui/material";
import html2pdf from "html2pdf.js";

const useStoredState = (key, initialValue) => {
	return useState(() => reactLocalStorage.getObject(key) || initialValue);
};

const useBookingData = (
	vehicles,
	rides,
	contactDetails,
	extraOptions,
	client,
	id,
) => {
	const [data, setData] = useState({});

	useEffect(() => {
		const newData = {
			bookingTime: new Date().toISOString(),
			...vehicles,
			rides,
			...contactDetails,
			...extraOptions,
			...client,
			quoted: contactDetails?.bookingType === "quatation" ? true : false,
			position:
				contactDetails?.bookingType === "quatation" ? "Pending" : "Completed",
			quotedPrice: 0,
			quoteAccepted: false,
			payments: [],
		};
		setData(newData);
	}, [client, contactDetails, extraOptions, rides, vehicles, id]);

	// Function to add new data
	const addData = (newInfo) => {
		setData((prevData) => ({
			...prevData,
			...newInfo,
		}));
	};

	return { data, addData };
};

const TotalSummary = ({ setStep, setData }) => {
	const [submitting, setSubmitting] = useState(false);

	const [vehicles] = useStoredState("vehicles", {});
	const [rides] = useStoredState("rideDetails", {});
	const [contactDetails] = useStoredState("contactDetails", {});
	const [extraOptions] = useStoredState("importantOpions", {});
	const [client] = useStoredState("client", {});

	const { data, addData } = useBookingData(
		vehicles,
		rides,
		contactDetails,
		extraOptions,
		client,
	);

	const confirm = (position) => {
		const bookingData = {
			...data,
		};
		Swal.fire({
			title: "Are you sure?",
			text: "Are you sure you want to proceed with this booking?",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "Yes, proceed!",
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
		}).then(async (result) => {
			if (result.isConfirmed) {
				setSubmitting(true);
				try {
					const response = await axios.post(
						`${process.env.REACT_APP_API_PATH}/bookings`,
						bookingData,
					);
					if (response.status === 201) {
						setSubmitting(false);
						Swal.fire({
							icon: "success",
							title: "Success",
							showConfirmButton: false,
							timer: 1500,
						}).then(() => {
							reactLocalStorage.clear("vehicles");
							reactLocalStorage.clear("rideDetails");
							reactLocalStorage.clear("contactDetails");
							reactLocalStorage.clear("importantOpions");
							reactLocalStorage.clear("client");
							reactLocalStorage.clear("quickBookingStep");
							window.location.href = "/dashboard/QuickBooking";
						});
					}
				} catch (error) {
					setSubmitting(false);
					Swal.fire({
						icon: "error",
						title: "Error",
						text: error.response?.data?.message,
					});
				}
			} else if (result.dismiss === Swal.DismissReason.cancel) {
				Swal.fire(
					"Cancelled",
					"Your booking request is not confirmed",
					"error",
				);
			}
		});
	};

	const generateStyledPDF = async (hidePrice = false) => {
		const htmlContent = `
    <div style="font-family: Arial, sans-serif; font-size: 12px; line-height: 1.5; color: #333; margin: 0; padding: 0; text-align: left;">
        <div style="max-width: 210mm; padding: 20px; box-sizing: border-box;">
		<div style="overflow: hidden; margin-bottom: 15px; background-color: #000; padding: 10px; border-radius: 5px;">
			<img src='/logo.png' alt="Elegance Limousine Services" style="width: 100px; height: auto; border-radius: 15px; float: left;">
			<div style="float: right;  color:white;">
			 <div style="float: right; text-align: right; ">
			 <p style="margin: 0; color: white; font-size: 17px; font-weight: bold;">
			  Elegance Limousine Services</p>
				<p style="margin: 0; color: white;">515 Riverside Dr W, Windsor, ON N9A 5K3</p>
				<p style="margin: 0; color: white;">Tel: 519-969-4444</p>
				<p style="margin: 0; color: white;">
					Email: <a href="mailto:info@elegancelimo.ca" style="color: white;">
						info@elegancelimo.ca
					</a>
				</p>
					</div>								
			</div>
		</div>
            <div style="overflow: hidden; margin-bottom: 11px;">
                <div style="text-align: center; font-size: 18px; font-weight: bold; text-decoration: underline;">
                    ${data?.quoteAccepted || data?.position === "Completed"
				? "Invoice"
				: "Quotation"
			} Details
                </div>
               
            </div>
            <div style="overflow: hidden; border: 1px solid #ddd; padding: 10px; margin-bottom: 20px;">
                <div style="width: 48%; float: left; margin-right: 4%;">
                    <div style="margin-bottom: 10px;">
                        <p style="margin: 0;"><strong>Client:</strong> ${data.firstName
			} ${data.lastName}</p>
                        <p style="margin: 0;"><strong>Email:</strong> ${data.bookedBy || data?.userEmail
			}</p>
                        <p style="margin: 0;"><strong>Company:</strong> ${data.reqCompany
			}</p>
                    </div>
                </div>
                <div style="width: 48%; float: left;">
                    <div style="margin-bottom: 10px;">
                        <p style="margin: 0;"><strong>Quotation ID:</strong> ${data.txn
			}</p>
                        <p style="margin: 0;"><strong>HST:</strong> ${data.hst?.split("HST")?.[1]
			}</p>
                        <p style="margin: 0;"><strong>Passengers:</strong> ${data.passengersCount
			}</p>
                    </div>
                </div>
            </div>
            <div style="overflow: hidden; border: 1px solid #ddd; padding: 10px; margin: 20px 0;">
                <p style="margin: 0;"><strong>Rides:</strong></p>
             ${data.rides
				.map(
					(ride, index) => `
    <div key=${index} style="border: 1px solid #ddd; padding: 10px; margin-bottom: 10px;">
        <div style="display: flex; justify-content: space-between;">
            <!-- Left Column -->
            <div style="width: 48%;">
                ${ride.rideType
							? `<p style="margin: 0;"><strong>Ride Type:</strong> ${ride.rideType}</p>`
							: ""
						}
                ${ride.picupLocation1 ||
							ride.picupLocation2 ||
							ride.picupLocation3 ||
							ride.picupLocation4 ||
							ride.picupLocation5
							? `<p style="margin: 0;"><strong>Pick-up Location:</strong> ${ride.picupLocation1 ||
							ride.picupLocation2 ||
							ride.picupLocation3 ||
							ride.picupLocation4 ||
							ride.picupLocation5
							}</p>`
							: ""
						}
                ${ride.time1
							? `
                    <p style="margin: 0;"><strong>Pick-up Date:</strong> ${new Date(
								ride.time1,
							).toLocaleDateString()}</p>
                    <p style="margin: 0;"><strong>Pick-up Time:</strong> ${new Date(
								ride.time1,
							).toLocaleTimeString()}</p>
                `
							: ""
						}
                <p style="margin: 0;"><strong>Vehicle to Stay:</strong> No</p>
                
                ${ride.rideType === "Flat Rate" && ride.transferType3
							? `
                    <p style="margin: 0;"><strong>Service Type:</strong> ${ride.transferType3
							}</p>
                    ${ride.duration3
								? `<p style="margin: 0;"><strong>Duration:</strong> ${ride.duration3}</p>`
								: ""
							}
                `
							: ""
						}
                
                ${ride.rideType === "Chauffeur Services" && ride.transferType4
							? `
                    <p style="margin: 0;"><strong>Service Type:</strong> ${ride.transferType4
							}</p>
                    ${ride.duration4
								? `<p style="margin: 0;"><strong>Duration:</strong> ${ride.duration4}</p>`
								: ""
							}
                `
							: ""
						}
            </div>

            <!-- Right Column -->
            <div style="width: 48%;">
                ${ride.dropOffLocation1 || ride.dropOffLocation2
							? `<p style="margin: 0;"><strong>Destination:</strong> ${ride.dropOffLocation1 || ride.dropOffLocation2
							}</p>`
							: ""
						}
                ${ride.time1
							? `
                    <p style="margin: 0;"><strong>Arrival Date:</strong> ${new Date(
								ride.time1,
							).toLocaleDateString()}</p>
                    <p style="margin: 0;"><strong>Leave Date:</strong> ${new Date(
								ride.time1,
							).toLocaleDateString()}</p>
                    <p style="margin: 0;"><strong>Back Date:</strong> ${new Date(
								ride.time1,
							).toLocaleDateString()}</p>
                `
							: ""
						}
                ${ride.airline || ride.flightNo
							? `<p style="margin: 0;"><strong>Flight Info:</strong> ${ride.airline || "N/A"
							}, ${ride.flightNo || "N/A"}</p>`
							: ""
						}

                ${ride.rideType === "Airport Service"
							? `
                    ${ride.airport
								? `<p style="margin: 0;"><strong>Airport:</strong> ${ride.airport}</p>`
								: ""
							}
                    ${ride.terminal
								? `<p style="margin: 0;"><strong>Terminal:</strong> ${ride.terminal}</p>`
								: ""
							}
                    ${ride.flightTime
								? `<p style="margin: 0;"><strong>Flight Time:</strong> ${ride.flightTime}</p>`
								: ""
							}
                    ${ride.flightStatus
								? `<p style="margin: 0;"><strong>Flight Status:</strong> ${ride.flightStatus}</p>`
								: ""
							}
                    ${ride.origin
								? `<p style="margin: 0;"><strong>Origin:</strong> ${ride.origin}</p>`
								: ""
							}
                `
							: ""
						}
            </div>
        </div>
    </div>
  `,
				)
				.join("")}


            </div>
            <div style="overflow: hidden; border: 1px solid #ddd; padding: 10px; margin: 20px 0; ${hidePrice ? "display: none;" : ""
			}">
                <table style="width: 100%; border-collapse: collapse;">
                    <thead>
                        <tr style="background-color: #ddd;">
                            <th style="padding: 4px; border: 1px solid #ddd; ">No</th>
                            <th style="padding: 4px; border: 1px solid #ddd; ">Seats</th>
                            <th style="padding: 4px; border: 1px solid #ddd; ">Vehicle</th>
                            <th style="padding: 4px; border: 1px solid #ddd; ">Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr style="border-bottom: 1px solid #ddd; text-align:center;">
                            <td style="padding: 4px; border: 1px solid #ddd;">1</td>
                            <td style="padding: 4px; border: 1px solid #ddd;">${data.carPassenger
			}</td>
                            <td style="padding: 4px; border: 1px solid #ddd;">${data.carName
			}</td>
                            <td style="padding: 4px; border: 1px solid #ddd;">${data.quotedPrice || data?.priceByAdmin || 0.0
			}</td>
                        </tr>
						 <tr style="border-top: 5px solid #ddd; text-align:center;">
                            <td style="padding: 4px;"></td>
                            <td style="padding: 4px;"></td>
                            <td style="padding: 4px;">Tax</td>
                            <td style="padding: 4px;">${data.tax || 0.0}%</td>
                        </tr>
						<tr style="text-align:center;">
                            <td style="padding: 4px;"></td>
                            <td style="padding: 4px;"></td>
                            <td style="padding: 4px;">Tips</td>
                            <td style="padding: 4px;">${data.tips || 0.0}$</td>
                        </tr>
						<tr style="border-top: 1px solid #ddd; text-align:center;">
                            <td style="padding: 4px;"></td>
                            <td style="padding: 4px;"></td>
                            <td style="padding: 4px;">Total</td>
                            <td style="padding: 4px;"> ${(
				(Number(data.priceByAdmin) ||
					Number(data.quotedPrice) ||
					0) *
				(1 + Number(data?.tax || 0) / 100) +
				Number(data?.tips || 0)
			).toFixed(2)}$</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div style="overflow: hidden; border: 1px solid #ddd; padding: 10px; margin-bottom: 20px;">
                <p style="margin: 0;"><strong>Vehicle Description:</strong></p>
                <p style="margin: 0;">${data.carInfo
				.map((info) => info.feature)
				.join(", ")}</p>
            </div>
			 <div style="overflow: hidden; border: 1px solid #ddd; padding: 10px; margin-bottom: 20px;">
                <p style="margin: 0;"><strong>Notes:</strong></p>
				<p style="margin: 0;">${data.notes || ""}</p>
				</div>
            <div style="overflow: hidden; border: 1px solid #ddd; padding: 10px; margin-bottom: 20px;">
                <p style="margin: 0;"><strong>Further Requirements:</strong> All vehicle pictures can be found at: <a href='https://elegancelimo.ca' target='_blank' rel='noreferrer'>https://elegancelimo.ca</a></p>
                <p style="margin: 0;">It is customary to tip your limo/bus driver between 15% to 20% based on your experience. Gratuities are not included in the price of your charter.</p>
            </div>

						<div style="overflow: hidden; margin-bottom: 20px; text-align: center;">
				<h3 style="margin: 0;">Thank you for choosing Elegance Limousine Services. <br>
				 We look forward to serving you.</h3>
					
			</div>
			
			

        </div>
    </div>
`;

		// Create a temporary container
		const container = document.createElement("div");
		container.innerHTML = htmlContent;
		document.body.appendChild(container);
		html2pdf()
			.set({
				margin: 5,
				filename:
					(data?.quoteAccepted || data?.position === "Completed"
						? "Invoice"
						: "Quotation") + ".pdf",
				image: { type: "jpeg", quality: 0.98 },
				html2canvas: { scale: 2, useCORS: true, scrollX: 0, scrollY: 0 },
				jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
				pagebreak: { mode: ["avoid-all"] },
			})
			.from(container)
			.save()
			.then(() => document.body.removeChild(container))
			.catch((error) => console.error("PDF generation error:", error));
	};

	if (submitting) {
		return (
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					height: "100vh",
				}}>
				<BeatLoader color='#000' />
			</div>
		);
	}

	return (
		<div style={{ maxWidth: "900px", margin: "0 auto" }}>
			<div
				style={{
					marginTop: "10px",
					position: "fixed",
					bottom: 0,
					left: "50%",
					transform: "translateX(-50%)",
					width: "100%",
					padding: "10px",
					backgroundColor: "#80808078",
					borderTop: "3px solid #80808078",
				}}>
				<div
					style={{
						maxWidth: "900px",
						margin: "0 auto",
						display: "flex",
						justifyContent: "space-between",
					}}>
					<button
						type='button'
						onClick={() => {
							setStep("choosevehicle");
							reactLocalStorage.setObject("quickBookingStep", "choosevehicle");
						}}
						style={{
							padding: "10px",
							backgroundColor: "black",
							color: "white",
							border: "none",
							borderRadius: "4px",
							cursor: "pointer",
						}}>
						&#8592; Back
					</button>
					<button
						onClick={() => generateStyledPDF()}
						style={{
							padding: "10px",
							backgroundColor: "black",
							color: "white",
							border: "none",
							borderRadius: "4px",
							cursor: "pointer",
						}}>
						Download PDF
					</button>
					<button
						onClick={() => confirm()}
						style={{
							padding: "10px",
							backgroundColor: "black",
							color: "white",
							border: "none",
							borderRadius: "4px",
							cursor: "pointer",
						}}>
						FINISH &#8594;
					</button>
				</div>
			</div>
			<Box
				sx={{
					maxWidth: "800px",
					mx: "auto",
					p: {
						xs: 0,
						sm: 1,
					},
					border: "1px solid #ddd",
					my: "20px",
				}}>
				<div
					style={{
						fontFamily: "Arial, sans-serif",
						fontSize: "12px",
						lineHeight: 1.5,
						color: "#333",
						margin: 0,
						padding: 0,
						textAlign: "left",
					}}>
					<div style={{ padding: "20px", margin: "auto", width: "100%" }}>
						<div
							style={{
								overflow: "hidden",
								marginBottom: "15px",
								backgroundColor: "#000",
								padding: "10px",
								borderRadius: "5px",
							}}>
							<img
								src='/logo.png'
								alt='Elegance Limousine Services'
								style={{
									width: "100px",
									height: "auto",
									borderRadius: "15px",
									float: "left",
								}}
							/>
							<div style={{ float: "right", color: "white" }}>
								<div style={{ float: "right", textAlign: "right" }}>
									<p
										style={{
											margin: 0,
											color: "white",
											fontSize: "17px",
											fontWeight: "bold",
										}}>
										Elegance Limousine Services
									</p>
									<p style={{ margin: 0, color: "white" }}>
										515 Riverside Dr W, Windsor, ON N9A 5K3
									</p>
									<p style={{ margin: 0, color: "white" }}>Tel: 519-969-4444</p>
									<p style={{ margin: 0, color: "white" }}>
										Email:{" "}
										<a
											href='mailto:info@elegancelimo.ca'
											style={{ color: "white" }}>
											info@elegancelimo.ca
										</a>
									</p>
								</div>
							</div>
						</div>
						<div style={{ overflow: "hidden", marginBottom: "11px" }}>
							<div
								style={{
									textAlign: "center",
									fontSize: "18px",
									fontWeight: "bold",
									textDecoration: "underline",
								}}>
								{data?.quoteAccepted || data?.position === "Completed"
									? "Invoice"
									: "Quotation"}{" "}
								Details
							</div>{" "}
							<div
								style={{
									textAlign: "center",
									fontSize: "18px",
								}}>
								<p style={{ margin: 0 }}>
									<strong>
										{data?.quoteAccepted || data?.position === "Completed"
											? "Invoice"
											: "Quotation"}{" "}
										ID:
									</strong>{" "}
									{data.txn}
								</p>
								<p style={{ margin: 0 }}>
									<strong>HST:</strong> {data.hst?.split("HST")?.[1]}
								</p>
							</div>
						</div>
						<div
							style={{
								overflow: "hidden",
								border: "1px solid #ddd",
								padding: "10px",
								marginBottom: "20px",
							}}>
							<div style={{ width: "48%", float: "left", marginRight: "4%" }}>
								<div style={{ marginBottom: "10px" }}>
									<p style={{ margin: 0 }}>
										<strong>Client ID:</strong> {data._id}
									</p>
									<p style={{ margin: 0 }}>
										<strong>Client:</strong> {data.firstName} {data.lastName}
									</p>
									<p style={{ margin: 0 }}>
										<strong>Email:</strong> {data.bookedBy || data?.userEmail}
									</p>
									<p style={{ margin: 0 }}>
										<strong>Company:</strong> {data.reqCompany}
									</p>
								</div>
							</div>
							<div style={{ width: "48%", float: "left" }}>
								<div style={{ marginBottom: "10px" }}>
									<p style={{ margin: 0 }}>
										<strong>
											{data?.quoteAccepted || data?.position === "Completed"
												? "Invoice"
												: "Quotation"}{" "}
											ID:
										</strong>{" "}
										{data.txn}
									</p>
									<p style={{ margin: 0 }}>
										<strong>HST:</strong> {data.hst?.split("HST")?.[1]}
									</p>
									<p style={{ margin: 0 }}>
										<strong>Passengers:</strong> {data.passengersCount}
									</p>
								</div>
							</div>
						</div>
						<div
							style={{
								overflow: "hidden",
								border: "1px solid #ddd",
								padding: "10px",
								margin: "20px 0",
							}}>
							<p style={{ margin: 0 }}>
								<strong>Rides:</strong>
							</p>
							{data?.rides?.map((ride, index) => (
								<div
									key={index}
									style={{
										border: "1px solid #ddd",
										padding: "10px",
										marginBottom: "10px",
									}}>
									<div
										style={{
											display: "flex",
											justifyContent: "space-between",
										}}>
										{/* Left Column */}
										<div style={{ width: "48%" }}>
											{ride?.rideType && (
												<p style={{ margin: 0 }}>
													<strong>Ride Type:</strong> {ride.rideType}
												</p>
											)}
											{(ride.picupLocation1 ||
												ride.picupLocation2 ||
												ride.picupLocation3 ||
												ride.picupLocation4 ||
												ride.picupLocation5) && (
													<p style={{ margin: 0 }}>
														<strong>Pick-up Location:</strong>{" "}
														{ride.picupLocation1 ||
															ride.picupLocation2 ||
															ride.picupLocation3 ||
															ride.picupLocation4 ||
															ride.picupLocation5}
													</p>
												)}
											{ride.time1 && (
												<>
													<p style={{ margin: 0 }}>
														<strong>Pick-up Date:</strong>{" "}
														{new Date(ride.time1).toLocaleDateString()}
													</p>
													<p style={{ margin: 0 }}>
														<strong>Pick-up Time:</strong>{" "}
														{new Date(ride.time1).toLocaleTimeString()}
													</p>
												</>
											)}
											<p style={{ margin: 0 }}>
												<strong>Vehicle to Stay:</strong> No
											</p>
											{ride.rideType === "Flat Rate" && (
												<>
													{ride.transferType3 && (
														<p style={{ margin: 0 }}>
															<strong>Service Type:</strong>{" "}
															{ride.transferType3}
														</p>
													)}
													{ride.duration3 && (
														<p style={{ margin: 0 }}>
															<strong>Duration:</strong> {ride.duration3}
														</p>
													)}
												</>
											)}
											{ride.rideType === "Chauffeur Services" && (
												<>
													{ride.transferType4 && (
														<p style={{ margin: 0 }}>
															<strong>Service Type:</strong>{" "}
															{ride.transferType4}
														</p>
													)}
													{ride.duration4 && (
														<p style={{ margin: 0 }}>
															<strong>Duration:</strong> {ride.duration4}
														</p>
													)}
												</>
											)}
										</div>

										{/* Right Column */}
										<div style={{ width: "48%" }}>
											{(ride.dropOffLocation1 || ride.dropOffLocation2) && (
												<p style={{ margin: 0 }}>
													<strong>Destination:</strong>{" "}
													{ride.dropOffLocation1 || ride.dropOffLocation2}
												</p>
											)}
											{ride.time1 && (
												<>
													<p style={{ margin: 0 }}>
														<strong>Arrival Date:</strong>{" "}
														{new Date(ride.time1).toLocaleDateString()}
													</p>
													<p style={{ margin: 0 }}>
														<strong>Leave Date:</strong>{" "}
														{new Date(ride.time1).toLocaleDateString()}
													</p>
													<p style={{ margin: 0 }}>
														<strong>Back Date:</strong>{" "}
														{new Date(ride.time1).toLocaleDateString()}
													</p>
												</>
											)}
											{(ride.airline || ride.flightNo) && (
												<p style={{ margin: 0 }}>
													<strong>Flight Info:</strong> {ride.airline || "N/A"},{" "}
													{ride.flightNo || "N/A"}
												</p>
											)}
											{ride.rideType === "Airport Service" && (
												<>
													{ride.airport && (
														<p style={{ margin: 0 }}>
															<strong>Airport:</strong> {ride.airport}
														</p>
													)}
													{ride.terminal && (
														<p style={{ margin: 0 }}>
															<strong>Terminal:</strong> {ride.terminal}
														</p>
													)}
													{ride.flightTime && (
														<p style={{ margin: 0 }}>
															<strong>Flight Time:</strong> {ride.flightTime}
														</p>
													)}
													{ride.flightStatus && (
														<p style={{ margin: 0 }}>
															<strong>Flight Status:</strong>{" "}
															{ride.flightStatus}
														</p>
													)}
													{ride.origin && (
														<p style={{ margin: 0 }}>
															<strong>Origin:</strong> {ride.origin}
														</p>
													)}
												</>
											)}
										</div>
									</div>
								</div>
							))}
						</div>
						<div
							style={{
								overflow: "hidden",
								border: "1px solid #ddd",
								padding: "10px",
								margin: "20px 0",
							}}>
							<table
								style={{
									width: "100%",
									borderCollapse: "collapse",
									textAlign: "center",
								}}>
								<thead>
									<tr style={{ backgroundColor: "#ddd" }}>
										<th style={{ padding: "4px", border: "1px solid #ddd" }}>
											No
										</th>
										<th style={{ padding: "4px", border: "1px solid #ddd" }}>
											Seats
										</th>
										<th style={{ padding: "4px", border: "1px solid #ddd" }}>
											Vehicle
										</th>

										<th style={{ padding: "4px", border: "1px solid #ddd" }}>
											Total
										</th>
									</tr>
								</thead>
								<tbody>
									<tr
										style={{
											borderBottom: "1px solid #ddd",
											textAlign: "center",
										}}>
										<td style={{ padding: "4px", border: "1px solid #ddd" }}>
											1
										</td>
										<td style={{ padding: "4px", border: "1px solid #ddd" }}>
											{data.carPassenger}
										</td>
										<td style={{ padding: "4px", border: "1px solid #ddd" }}>
											{data.carName}
										</td>

										<td style={{ padding: "4px", border: "1px solid #ddd" }}>
											<input
												type='number'
												defaultValue={data.priceByAdmin || 0}
												style={{
													backgroundColor: "transparent",
													textAlign: "center",
													width: "50px",
												}}
												onChange={(e) => {
													const value = e.target.value;
													addData({ priceByAdmin: Number(value) });
												}}
											/>{" "}
											$
										</td>
									</tr>
									<tr
										style={{
											textAlign: "center",
											borderTop: "5px solid #ddd",
										}}>
										<td style={{ padding: "4px" }}></td>
										<td style={{ padding: "4px" }}></td>
										<td style={{ padding: "4px" }}>Tax</td>

										<td style={{ padding: "4px" }}>
											<input
												type='number'
												defaultValue={data.tax || 0}
												style={{
													backgroundColor: "transparent",
													textAlign: "center",
													width: "50px",
												}}
												onChange={(e) => {
													const value = e.target.value;
													addData({ tax: Number(value) });
												}}
											/>{" "}
											%
										</td>
									</tr>
									<tr
										style={{
											textAlign: "center",
										}}>
										<td style={{ padding: "4px" }}></td>
										<td style={{ padding: "4px" }}></td>
										<td style={{ padding: "4px" }}>Tips</td>

										<td style={{ padding: "4px" }}>
											<input
												type='number'
												defaultValue={data.tips || 0}
												style={{
													backgroundColor: "transparent",
													textAlign: "center",
													width: "50px",
												}}
												onChange={(e) => {
													const value = e.target.value;
													addData({ tips: Number(value) });
												}}
											/>{" "}
											$
										</td>
									</tr>
									<tr
										style={{
											borderTop: "1px solid #ddd",
											textAlign: "center",
										}}>
										<td style={{ padding: "4px" }}></td>
										<td style={{ padding: "4px" }}></td>
										<td style={{ padding: "4px" }}>Total</td>

										<td style={{ padding: "4px" }}>
											{(
												(Number(data.priceByAdmin) || 0) *
												(1 + Number(data?.tax || 0) / 100) +
												Number(data?.tips || 0)
											).toFixed(2)}
											$
										</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div
							style={{
								overflow: "hidden",
								border: "1px solid #ddd",
								padding: "10px",
								marginBottom: "20px",
							}}>
							<p style={{ margin: 0 }}>
								<strong>Vehicle Description:</strong>
							</p>
							<p style={{ margin: 0 }}>
								{data?.carInfo?.map((info) => info.feature).join(", ")}
							</p>
						</div>
						<div
							style={{
								overflow: "hidden",
								border: "1px solid #ddd",
								padding: "10px",
								marginBottom: "20px",
							}}>
							<p style={{ margin: 0 }}>
								<strong>Notes:</strong>
							</p>
							<textarea
								/* disabled={!isAdmin} */
								defaultValue={data.notes}
								style={{
									width: "100%",
									height: "100px",
									border: "none",
									backgroundColor: "transparent",
									resize: "none",
									fontSize: "12px",
									fontFamily: "inherit",
								}}
								onBlur={(e) => {
									const value = e.target.value;
									addData({ notes: value });
								}}></textarea>
							{/*  <p style={{ margin: 0 }}>{data.comment}</p> */}
						</div>
						<div
							style={{
								overflow: "hidden",
								border: "1px solid #ddd",
								padding: "10px",
								marginBottom: "20px",
							}}>
							<p style={{ margin: 0 }}>
								<strong>Further Requirements:</strong> All vehicle pictures can
								be found at:{" "}
								<a
									href='https://elegancelimo.ca'
									target='_blank'
									rel='noreferrer'>
									https://elegancelimo.ca
								</a>
							</p>
							<p style={{ margin: 0 }}>
								It is customary to tip your limo/bus driver between 15% to 20%
								based on your experience. Gratuities are not included in the
								price of your charter.
							</p>
						</div>
						<div
							style={{
								overflow: "hidden",
								marginBottom: "20px",
								textAlign: "center",
							}}>
							<h3 style={{ margin: 0 }}>
								Thank you for choosing Elegance Limousine Services. <br />
								We look forward to serving you.
							</h3>
						</div>
					</div>
				</div>
			</Box>
		</div>
	);
};

export default TotalSummary;
