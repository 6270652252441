import { Box } from "@mui/material";
import React from "react";
import AboutUs from "../../Components/AboutUs";
import OurFleet from "../../Components/OurFleet";
import Places from "../../Components/Places";
import WhatAboutPricing from "../../Components/WhatAboutPricing";
import WhyUs from "../../Components/WhyUs";
import video from "../../Files/elegance-video.mp4";
import Services from "../../Components/Services";

const HomePage = () => {
	return (
		<>
			<Box className='video-wrapper'>
				<video
					width='100%'
					autoPlay
					muted
					loop
					style={{ height: "100%", objectFit: "cover" }}>
					<source src={video} type='video/mp4' />
				</video>
			</Box>
			<OurFleet />
			<Services />
			<Places />
			<AboutUs />
			<WhyUs />
			<WhatAboutPricing />
		</>
	);
};

export default HomePage;
