import {
	Box,
	Button,
	CardMedia,
	Divider,
	Grid,
	Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { useData } from "../../contex/contex";

const BookingQuote = () => {
	const { id } = useParams();
	const [data, setData] = useState();
	const { token } = useData();
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(
					`${process.env.REACT_APP_API_PATH}/bookings/${id}`,
					{
						headers: {
							Authorization: token,
						},
					},
				);

				setData(response.data);
			} catch (error) {
				Swal.fire({
					icon: "error",
					title: "Error",
					text: error.response.data.message,
				});
			}
		};
		fetchData();
	}, [id, token]);

	return (
		<Box
			sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				flexDirection: "column",
				minHeight: "70vh",
			}}>
			{data?.quoted && data?.position === "Quote" && !data?.quoteAccepted && (
				<Box
					sx={{
						p: 2,
					}}>
					<Typography gutterBottom variant='h5' component='div' sx={{ mb: 2 }}>
						Booking Quote Verification
					</Typography>
					<Grid container spacing={3}>
						<Grid item md={4} xs={12}>
							<Box
								sx={{ textAlign: "left", bgcolor: "#F6F6F6", p: 1.5, mb: 1 }}>
								<Typography
									gutterBottom
									variant='h5'
									component='div'
									sx={{ mb: 2 }}>
									About
								</Typography>
								<Box>
									{/* <Typography gutterBottom variant='body' component='div'>
									Booking For ?
								</Typography>
								<Typography gutterBottom variant='body2' component='div'>
									{data?.forWhom || "N/A"}
								</Typography> */}
									<Divider sx={{ my: 1.5 }} />

									{(!data?.forWhom === "Booking for myself" ||
										data?.forWho) && (
											<>
												<Typography gutterBottom variant='body' component='div'>
													You Are ?
												</Typography>
												<Typography gutterBottom variant='body2' component='div'>
													{data?.forWho || "N/A"}
												</Typography>
												<Divider sx={{ my: 1.5 }} />
											</>
										)}
									{(!data?.forWhom === "Booking for myself" ||
										!data?.forWho === "A Person" ||
										data?.company ||
										data?.reqCompany) && (
											<>
												<Typography gutterBottom variant='body' component='div'>
													Company
												</Typography>
												<Typography gutterBottom variant='body2' component='div'>
													{data?.company || data?.reqCompany || "N/A"}
												</Typography>
												<Divider sx={{ my: 1.5 }} />
											</>
										)}
								</Box>
							</Box>
							<Box sx={{ textAlign: "left", bgcolor: "#F6F6F6", p: 1.5 }}>
								<Typography
									gutterBottom
									variant='h5'
									component='div'
									sx={{ mb: 2 }}>
									Conatct And Billing Info
								</Typography>
								<Box sx={{ display: "flex" }}>
									<Box>
										<Typography gutterBottom variant='body' component='div'>
											First Name
										</Typography>
										<Typography gutterBottom variant='body2' component='div'>
											{data?.firstName}
										</Typography>
									</Box>
									<Box sx={{ ml: 2 }}>
										<Typography gutterBottom variant='body' component='div'>
											Last Name
										</Typography>
										<Typography gutterBottom variant='body2' component='div'>
											{data?.lastName}
										</Typography>
									</Box>
								</Box>
								<Divider sx={{ my: 1.5 }} />
								<Typography gutterBottom variant='body' component='div'>
									Email Address
								</Typography>
								<Typography gutterBottom variant='body2' component='div'>
									{data?.userEmail}
								</Typography>
								<Divider sx={{ my: 1.5 }} />
								<Typography gutterBottom variant='body' component='div'>
									Phone Number
								</Typography>
								<Typography gutterBottom variant='body2' component='div'>
									{data?.phone}
								</Typography>
								<Divider sx={{ my: 1.5 }} />
								<Typography gutterBottom variant='body' component='div'>
									Notes
								</Typography>
								<Typography gutterBottom variant='body2' component='div'>
									{data?.comment || "N/A"}
								</Typography>
								<Divider sx={{ my: 1.5 }} />
							</Box>
						</Grid>
						<Grid item md={4} xs={12}>
							<Box
								sx={{ textAlign: "left", bgcolor: "#F6F6F6", p: 1.5, mb: 2 }}>
								<Typography
									gutterBottom
									variant='h5'
									component='div'
									sx={{ mb: 2 }}>
									Booking Info
								</Typography>

								<Typography gutterBottom variant='body' component='div'>
									Booking Time
								</Typography>
								<Typography gutterBottom variant='body2' component='div'>
									{data?.bookingTime}
								</Typography>
								<Divider sx={{ my: 1.5 }} />
								<Typography gutterBottom variant='body' component='div'>
									TXN
								</Typography>
								<Typography gutterBottom variant='body2' component='div'>
									{data?.txn}
								</Typography>
								<Divider sx={{ my: 1.5 }} />
							</Box>
							<Box sx={{ textAlign: "left", bgcolor: "#F6F6F6", p: 1.5 }}>
								<Typography
									gutterBottom
									variant='h5'
									component='div'
									sx={{ mb: 2 }}>
									Vehicle Info
								</Typography>

								<Divider sx={{ my: 1.5 }} />
								<Grid container spacing={2} sx={{ alignItems: "center" }}>
									<Grid item md={12} xs={12}>
										<CardMedia
											sx={{ width: "100%" }}
											component='img'
											alt=''
											image={data?.carPhoto1}
										/>
									</Grid>
									<Grid item md={6} xs={6}>
										<CardMedia
											sx={{ width: "100%" }}
											component='img'
											alt=''
											image={data?.carPhoto2}
										/>
									</Grid>
									<Grid item md={6} xs={6}>
										<CardMedia
											sx={{ width: "100%" }}
											component='img'
											alt=''
											image={data?.carPhoto3}
										/>
									</Grid>
								</Grid>
								<Divider sx={{ my: 1.5 }} />
								<Typography gutterBottom variant='body' component='div'>
									Vehicle
								</Typography>
								<Typography gutterBottom variant='body2' component='div'>
									{data?.carName}
								</Typography>
								<Divider sx={{ my: 1.5 }} />
							</Box>
						</Grid>
						<Grid item md={4} xs={12}>
							<Box sx={{ textAlign: "left", bgcolor: "#F6F6F6", p: 1.5 }}>
								<Typography
									gutterBottom
									variant='h5'
									component='div'
									sx={{ mb: 2 }}>
									Ride details
								</Typography>
								{/* <button onClick={() => handleOpen("RideDetails")}>Edit</button> */}
								<Typography gutterBottom variant='body' component='div'>
									SERVICE TYPE
								</Typography>

								<Box
									sx={{
										maxHeight: "50vh",
										overflowY: "scroll",
									}}>
									{data?.rides?.map((rideDetails) => (
										<Box
											sx={{
												border: "1px solid #A72C79",
												p: 2,
												my: 2,
											}}>
											{rideDetails?.rideType === "Hourly" && (
												<>
													<Typography
														gutterBottom
														variant='body2'
														component='div'>
														{rideDetails?.rideType}
													</Typography>
													<Divider sx={{ my: 1.5 }} />
													<Typography
														gutterBottom
														variant='body'
														component='div'>
														PICKUP LOCATION
													</Typography>
													<Typography
														gutterBottom
														variant='body2'
														component='div'>
														{rideDetails?.picupLocation2}
													</Typography>
													<Divider sx={{ my: 1.5 }} />
													<Typography
														gutterBottom
														variant='body'
														component='div'>
														DROP-OFF LOCATION
													</Typography>
													<Typography
														gutterBottom
														variant='body2'
														component='div'>
														{rideDetails?.dropOffLocation2}
													</Typography>
													<Divider sx={{ my: 1.5 }} />
													<Typography
														gutterBottom
														variant='body'
														component='div'>
														DURATION
													</Typography>
													<Typography
														gutterBottom
														variant='body2'
														component='div'>
														{rideDetails?.duration2}
													</Typography>
													<Divider sx={{ my: 1.5 }} />
													<Typography
														gutterBottom
														variant='body'
														component='div'>
														PICKUP DATE, TIME
													</Typography>
													<Typography
														gutterBottom
														variant='body2'
														component='div'>
														{new Date(rideDetails?.time2).toLocaleString(
															"en-GB",
														)}
													</Typography>
													<Divider sx={{ my: 1.5 }} />
												</>
											)}
											{rideDetails?.rideType === "Distance" && (
												<>
													<Typography
														gutterBottom
														variant='body2'
														component='div'>
														{rideDetails?.rideType}
													</Typography>
													<Divider sx={{ my: 1.5 }} />
													<Typography
														gutterBottom
														variant='body'
														component='div'>
														PICKUP LOCATION
													</Typography>
													<Typography
														gutterBottom
														variant='body2'
														component='div'>
														{rideDetails?.picupLocation1}
													</Typography>
													<Divider sx={{ my: 1.5 }} />
													<Typography
														gutterBottom
														variant='body'
														component='div'>
														DROP-OFF LOCATION
													</Typography>
													<Typography
														gutterBottom
														variant='body2'
														component='div'>
														{rideDetails?.dropOffLocation1}
													</Typography>
													<Divider sx={{ my: 1.5 }} />
													<Typography
														gutterBottom
														variant='body'
														component='div'>
														EXTRA TIME
													</Typography>
													<Typography
														gutterBottom
														variant='body2'
														component='div'>
														{rideDetails?.extraTime1}
													</Typography>
													<Divider sx={{ my: 1.5 }} />
													<Typography
														gutterBottom
														variant='body'
														component='div'>
														PICKUP DATE, TIME
													</Typography>
													<Typography
														gutterBottom
														variant='body2'
														component='div'>
														{new Date(rideDetails?.time1).toLocaleString(
															"en-GB",
														)}
													</Typography>
													<Divider sx={{ my: 1.5 }} />
												</>
											)}
											{rideDetails?.rideType === "Flat Rate" && (
												<>
													<Typography
														gutterBottom
														variant='body2'
														component='div'>
														{rideDetails?.rideType}
													</Typography>
													<Divider sx={{ my: 1.5 }} />

													<Typography
														gutterBottom
														variant='body'
														component='div'>
														SERVICE TYPE
													</Typography>
													<Typography
														gutterBottom
														variant='body2'
														component='div'>
														{rideDetails?.transferType3}
													</Typography>
													<Typography
														gutterBottom
														variant='body'
														component='div'>
														PICKUP LOCATION
													</Typography>
													<Typography
														gutterBottom
														variant='body2'
														component='div'>
														{rideDetails?.picupLocation3}
													</Typography>
													<Divider sx={{ my: 1.5 }} />
													<Typography
														gutterBottom
														variant='body'
														component='div'>
														DURATION
													</Typography>
													<Typography
														gutterBottom
														variant='body2'
														component='div'>
														{rideDetails?.duration3}
													</Typography>
													<Divider sx={{ my: 1.5 }} />
													<Typography
														gutterBottom
														variant='body'
														component='div'>
														PICKUP DATE, TIME
													</Typography>
													<Typography
														gutterBottom
														variant='body2'
														component='div'>
														{new Date(rideDetails?.time3).toLocaleString(
															"en-GB",
														)}
													</Typography>
													<Divider sx={{ my: 1.5 }} />
												</>
											)}
											{rideDetails?.rideType === "Chauffeur Services" && (
												<>
													<Typography
														gutterBottom
														variant='body2'
														component='div'>
														{rideDetails?.rideType}
													</Typography>
													<Divider sx={{ my: 1.5 }} />

													<Typography
														gutterBottom
														variant='body'
														component='div'>
														SERVICE TYPE
													</Typography>
													<Typography
														gutterBottom
														variant='body2'
														component='div'>
														{rideDetails?.transferType4}
													</Typography>
													<Typography
														gutterBottom
														variant='body'
														component='div'>
														PICKUP LOCATION
													</Typography>
													<Typography
														gutterBottom
														variant='body2'
														component='div'>
														{rideDetails?.picupLocation4}
													</Typography>
													<Divider sx={{ my: 1.5 }} />
													<Typography
														gutterBottom
														variant='body'
														component='div'>
														DURATION
													</Typography>
													<Typography
														gutterBottom
														variant='body2'
														component='div'>
														{rideDetails?.duration4}
													</Typography>
													<Divider sx={{ my: 1.5 }} />
													<Typography
														gutterBottom
														variant='body'
														component='div'>
														PICKUP DATE, TIME
													</Typography>
													<Typography
														gutterBottom
														variant='body2'
														component='div'>
														{new Date(rideDetails?.time4).toLocaleString(
															"en-GB",
														)}
													</Typography>
													<Divider sx={{ my: 1.5 }} />
												</>
											)}
											{rideDetails?.rideType === "Airport Service" && (
												<>
													<Typography
														gutterBottom
														variant='body2'
														component='div'>
														{rideDetails?.rideType}
													</Typography>
													<Divider sx={{ my: 1.5 }} />
													<Typography
														gutterBottom
														variant='body'
														component='div'>
														PICKUP DATE, TIME
													</Typography>
													<Typography
														gutterBottom
														variant='body2'
														component='div'>
														{new Date(rideDetails?.time5).toLocaleString(
															"en-GB",
														)}
													</Typography>
													<Divider sx={{ my: 1.5 }} />
													<Typography
														gutterBottom
														variant='body'
														component='div'>
														PICKUP LOCATION
													</Typography>
													<Typography
														gutterBottom
														variant='body2'
														component='div'>
														{rideDetails?.picupLocation5}
													</Typography>
													<Divider sx={{ my: 1.5 }} />
													<Typography
														gutterBottom
														variant='body'
														component='div'>
														AIRPORT
													</Typography>
													<Typography
														gutterBottom
														variant='body2'
														component='div'>
														{rideDetails?.airport}
													</Typography>
													<Divider sx={{ my: 1.5 }} />
													<Typography
														gutterBottom
														variant='body'
														component='div'>
														AIRLINE
													</Typography>
													<Typography
														gutterBottom
														variant='body2'
														component='div'>
														{rideDetails?.airline || "N/A"}
													</Typography>
													<Divider sx={{ my: 1.5 }} />
													<Typography
														gutterBottom
														variant='body'
														component='div'>
														FLIGHT NO
													</Typography>
													<Typography
														gutterBottom
														variant='body2'
														component='div'>
														{rideDetails?.flightNo || "N/A"}
													</Typography>
													<Divider sx={{ my: 1.5 }} />
													<Typography
														gutterBottom
														variant='body'
														component='div'>
														TERMINAL
													</Typography>
													<Typography
														gutterBottom
														variant='body2'
														component='div'>
														{rideDetails?.terminal || "N/A"}
													</Typography>
													<Divider sx={{ my: 1.5 }} />
													<Typography
														gutterBottom
														variant='body'
														component='div'>
														FLIGHT TIME
													</Typography>
													<Typography
														gutterBottom
														variant='body2'
														component='div'>
														{rideDetails?.flightTime || "N/A"}
													</Typography>
													<Divider sx={{ my: 1.5 }} />
													<Typography
														gutterBottom
														variant='body'
														component='div'>
														FLIGHT STATUS
													</Typography>
													<Typography
														gutterBottom
														variant='body2'
														component='div'>
														{rideDetails?.flightStatus || "N/A"}
													</Typography>
													<Divider sx={{ my: 1.5 }} />
													<Typography
														gutterBottom
														variant='body'
														component='div'>
														ORIGIN
													</Typography>
													<Typography
														gutterBottom
														variant='body2'
														component='div'>
														{rideDetails?.origin || "N/A"}
													</Typography>
													<Divider sx={{ my: 1.5 }} />
												</>
											)}

											{/* <SinglePriceEdit
											booking={data}
											setSubmitting={setSubmitting}
											token={token}
											ride={rideDetails} 
										/>
										<button
											onClick={(e) => {
												handleOpenRec(e);
												setPdfType("receiptSingle");
												setSingleRide(rideDetails);
											}}>
											PDF Receipt
										</button> */}
										</Box>
									))}
								</Box>
								{data?.rideType === "Hourly" && (
									<>
										<Typography gutterBottom variant='body2' component='div'>
											{data?.rideType}
										</Typography>
										<Divider sx={{ my: 1.5 }} />
										<Typography gutterBottom variant='body' component='div'>
											PICKUP LOCATION
										</Typography>
										<Typography gutterBottom variant='body2' component='div'>
											{data?.picupLocation2}
										</Typography>
										<Divider sx={{ my: 1.5 }} />
										<Typography gutterBottom variant='body' component='div'>
											DROP-OFF LOCATION
										</Typography>
										<Typography gutterBottom variant='body2' component='div'>
											{data?.dropOffLocation2}
										</Typography>
										<Divider sx={{ my: 1.5 }} />
										<Typography gutterBottom variant='body' component='div'>
											DURATION
										</Typography>
										<Typography gutterBottom variant='body2' component='div'>
											{data?.duration2}
										</Typography>
										<Divider sx={{ my: 1.5 }} />
										<Typography gutterBottom variant='body' component='div'>
											PICKUP DATE, TIME
										</Typography>
										<Typography gutterBottom variant='body2' component='div'>
											{new Date(data?.time2).toLocaleString("en-GB")}
										</Typography>
										<Divider sx={{ my: 1.5 }} />
									</>
								)}
								{data?.rideType === "Distance" && (
									<>
										<Typography gutterBottom variant='body2' component='div'>
											{data?.rideType}
										</Typography>
										<Divider sx={{ my: 1.5 }} />
										<Typography gutterBottom variant='body' component='div'>
											PICKUP LOCATION
										</Typography>
										<Typography gutterBottom variant='body2' component='div'>
											{data?.picupLocation1}
										</Typography>
										<Divider sx={{ my: 1.5 }} />
										<Typography gutterBottom variant='body' component='div'>
											DROP-OFF LOCATION
										</Typography>
										<Typography gutterBottom variant='body2' component='div'>
											{data?.dropOffLocation1}
										</Typography>
										<Divider sx={{ my: 1.5 }} />
										<Typography gutterBottom variant='body' component='div'>
											EXTRA TIME
										</Typography>
										<Typography gutterBottom variant='body2' component='div'>
											{data?.extraTime1}
										</Typography>
										<Divider sx={{ my: 1.5 }} />
										<Typography gutterBottom variant='body' component='div'>
											PICKUP DATE, TIME
										</Typography>
										<Typography gutterBottom variant='body2' component='div'>
											{new Date(data?.time1).toLocaleString("en-GB")}
										</Typography>
										<Divider sx={{ my: 1.5 }} />
									</>
								)}
								{data?.rideType === "Flat Rate" && (
									<>
										<Typography gutterBottom variant='body2' component='div'>
											{data?.rideType}
										</Typography>

										<Divider sx={{ my: 1.5 }} />
										<Typography gutterBottom variant='body' component='div'>
											SERVICE TYPE
										</Typography>
										<Typography gutterBottom variant='body2' component='div'>
											{data?.transferType3}
										</Typography>
										<Divider sx={{ my: 1.5 }} />
										<Typography gutterBottom variant='body' component='div'>
											DURATION
										</Typography>
										<Typography gutterBottom variant='body2' component='div'>
											{data?.duration3}
										</Typography>
										<Divider sx={{ my: 1.5 }} />
										<Typography gutterBottom variant='body' component='div'>
											PICKUP DATE, TIME
										</Typography>
										<Typography gutterBottom variant='body2' component='div'>
											{new Date(data?.time3).toLocaleString("en-GB")}
										</Typography>
										<Divider sx={{ my: 1.5 }} />
									</>
								)}
								{data?.rideType === "Chauffeur Services" && (
									<>
										<Typography gutterBottom variant='body2' component='div'>
											{data?.rideType}
										</Typography>

										<Divider sx={{ my: 1.5 }} />
										<Typography gutterBottom variant='body' component='div'>
											SERVICE TYPE
										</Typography>
										<Typography gutterBottom variant='body2' component='div'>
											{data?.transferType4}
										</Typography>
										<Divider sx={{ my: 1.5 }} />
										<Typography gutterBottom variant='body' component='div'>
											DURATION
										</Typography>
										<Typography gutterBottom variant='body2' component='div'>
											{data?.duration4}
										</Typography>
										<Divider sx={{ my: 1.5 }} />
										<Typography gutterBottom variant='body' component='div'>
											PICKUP DATE, TIME
										</Typography>
										<Typography gutterBottom variant='body2' component='div'>
											{new Date(data?.time4).toLocaleString("en-GB")}
										</Typography>
										<Divider sx={{ my: 1.5 }} />
									</>
								)}
								{data?.rideType === "Airport Service" && (
									<>
										<Typography gutterBottom variant='body2' component='div'>
											{data?.rideType}
										</Typography>
										<Divider sx={{ my: 1.5 }} />
										<Typography gutterBottom variant='body' component='div'>
											PICKUP DATE, TIME
										</Typography>
										<Typography gutterBottom variant='body2' component='div'>
											{new Date(data?.time5).toLocaleString("en-GB")}
										</Typography>
										<Divider sx={{ my: 1.5 }} />
										<Typography gutterBottom variant='body' component='div'>
											PICKUP LOCATION
										</Typography>
										<Typography gutterBottom variant='body2' component='div'>
											{data?.picupLocation5}
										</Typography>
										<Divider sx={{ my: 1.5 }} />
										<Typography gutterBottom variant='body' component='div'>
											AIRPORT
										</Typography>
										<Typography gutterBottom variant='body2' component='div'>
											{data?.airport}
										</Typography>
										<Divider sx={{ my: 1.5 }} />
										<Typography gutterBottom variant='body' component='div'>
											AIRLINE
										</Typography>
										<Typography gutterBottom variant='body2' component='div'>
											{data?.airline || "N/A"}
										</Typography>
										<Divider sx={{ my: 1.5 }} />
										<Typography gutterBottom variant='body' component='div'>
											FLIGHT NO
										</Typography>
										<Typography gutterBottom variant='body2' component='div'>
											{data?.flightNo || "N/A"}
										</Typography>
										<Divider sx={{ my: 1.5 }} />
										<Typography gutterBottom variant='body' component='div'>
											TERMINAL
										</Typography>
										<Typography gutterBottom variant='body2' component='div'>
											{data?.terminal || "N/A"}
										</Typography>
										<Divider sx={{ my: 1.5 }} />
										<Typography gutterBottom variant='body' component='div'>
											FLIGHT TIME
										</Typography>
										<Typography gutterBottom variant='body2' component='div'>
											{data?.flightTime || "N/A"}
										</Typography>
										<Divider sx={{ my: 1.5 }} />
										<Typography gutterBottom variant='body' component='div'>
											FLIGHT STATUS
										</Typography>
										<Typography gutterBottom variant='body2' component='div'>
											{data?.flightStatus || "N/A"}
										</Typography>
										<Divider sx={{ my: 1.5 }} />
										<Typography gutterBottom variant='body' component='div'>
											ORIGIN
										</Typography>
										<Typography gutterBottom variant='body2' component='div'>
											{data?.origin || "N/A"}
										</Typography>
										<Divider sx={{ my: 1.5 }} />
									</>
								)}
								{data?.passengers === true && (
									<Typography gutterBottom variant='body2' component='div'>
										<b>Passengers</b> :{" "}
										{data?.passengers === true ? data?.passengersCount : "No"}
									</Typography>
								)}
								{data?.luggage === true && (
									<>
										<Divider sx={{ my: 1.5 }} />
										<Typography gutterBottom variant='body2' component='div'>
											<b>Luggage</b> :{" "}
											{data?.luggage === true ? data?.luggageCount : "No"}
										</Typography>
									</>
								)}
								{data?.babySeat === true && (
									<>
										<Divider sx={{ my: 1.5 }} />
										<Typography gutterBottom variant='body2' component='div'>
											<b>Child Seat</b> :{" "}
											{data?.babySeat === true ? data?.babyCount : "No"}
										</Typography>
									</>
								)}
								{data?.carSeat === true && (
									<>
										<Divider sx={{ my: 1.5 }} />
										<Typography gutterBottom variant='body2' component='div'>
											<b>Car Seat</b> :{" "}
											{data?.carSeat === true ? data?.carSeatCount : "No"}
										</Typography>
									</>
								)}
								{data?.wheelchair === true && (
									<>
										<Divider sx={{ my: 1.5 }} />
										<Typography gutterBottom variant='body2' component='div'>
											<b>Wheel Chair</b> :{" "}
											{data?.wheelchair === true ? data?.wheelchairCount : "No"}
										</Typography>
									</>
								)}

								<Divider sx={{ my: 1.5 }} />
							</Box>
						</Grid>
					</Grid>
					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							mt: 4,
						}}>
						<Button
							sx={{
								marginRight: "10px",
								backgroundColor: "green",
								color: "white",
								padding: "10px 20px",
								border: "1px solid green",
								"&:hover": {
									backgroundColor: "white",
									color: "green",
									border: "1px solid green",
								},
								fontWeight: "bold",
							}}
							onClick={() => {
								const id = data?._id;

								Swal.fire({
									title: "Are you sure?",
									text: "You want to accept this quote!",
									icon: "warning",
									showCancelButton: true,
									confirmButtonColor: "#3085d6",
									cancelButtonColor: "#d33",
									confirmButtonText: "Yes, Accept it!",
								}).then(async (result) => {
									if (result.isConfirmed) {
										try {
											const response = await axios.patch(
												`${process.env.REACT_APP_API_PATH}/quotedPriceFeedback/${id}`,
												{
													...data,
													quoteAccepted: true,
													position: "Pending",
												},
											);
											if (response.status) {
												Swal.fire({
													icon: "success",
													title: "Success",
													text: "Quote Accepted Successfully",
												});
												window.location.href = `/`;
											}
										} catch (error) {
											Swal.fire({
												icon: "error",
												title: "Error",
												text: error.response.data.message,
											});
										}
									}
								});
							}}>
							Accept Quote
						</Button>
						<Button
							sx={{
								marginRight: "10px",
								backgroundColor: "red",
								color: "white",
								padding: "10px 20px",
								border: "1px solid red",
								"&:hover": {
									backgroundColor: "white",
									color: "red",
									border: "1px solid red",
								},
								fontWeight: "bold",
							}}
							onClick={() => {
								const id = data?._id;

								Swal.fire({
									title: "Are you sure?",
									text: "You want to reject this quote!",
									icon: "warning",
									showCancelButton: true,
									confirmButtonColor: "#3085d6",
									cancelButtonColor: "#d33",
									confirmButtonText: "Yes, Reject it!",
								}).then(async (result) => {
									if (result.isConfirmed) {
										try {
											const response = await axios.patch(
												`${process.env.REACT_APP_API_PATH}/quotedPriceFeedback/${id}`,
												{
													...data,
													quoteAccepted: false,
													position: "Quote",
												},
												{
													headers: {
														Authorization: token,
													},
												},
											);
											if (response.status) {
												Swal.fire({
													icon: "success",
													title: "Success",
													text: "Quote Rejected Successfully",
												});
											}
										} catch (error) {
											Swal.fire({
												icon: "error",
												title: "Error",
												text: error.response.data.message,
											});
										}
									}
								});
							}}>
							Reject Quote
						</Button>
					</Box>
				</Box>
			)}
		</Box>
	);
};

export default BookingQuote;
