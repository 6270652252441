import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import ExtraOptions from "./ExtraOptions";
import Vehicles from "./Vehicles";

const ChooseVehicle = ({ data, id, token, handleClose }) => {
	return (
		<Container>
			<Grid container spacing={2} sx={{ mb: 2 }}>
				<Grid item md={9} xs={12}>
					<Box
						className='headline'
						sx={{
							width: "100%",
							py: 1.5,
							my: 2,
						}}>
						<Typography variant='body' component='div'>
							PASSENGERS AND LUGGAGE
						</Typography>
					</Box>
					<Grid container spacing={2}>
						<Grid item md={12}>
							<Vehicles data={data} id={id} token={token}
								handleClose={handleClose} />
						</Grid>
					</Grid>
					<Box
						className='headline'
						sx={{
							width: "100%",
							py: 1.5,
							my: 2,
						}}>
						<Typography variant='body' component='div'>
							Important options
						</Typography>
					</Box>
					<ExtraOptions data={data} id={id} token={token}
						handleClose={handleClose} />
				</Grid>
			</Grid>
		</Container>
	);
};

export default ChooseVehicle;
