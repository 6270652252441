import * as React from "react";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import {
	Button,
	Container,
	FormControl,
	FormHelperText,
	Grid,
	MenuItem,
	Select,
} from "@mui/material";
import { useForm } from "react-hook-form";

export default function Tab1({ rides, setRides, ride }) {
	const [dateTime, setDateTime] = React.useState(new Date(ride?.time1 || ""));

	const [newChange, setNewChange] = React.useState(false);
	const handleChange = (newValue) => {
		setDateTime(newValue);
		setNewChange(true);
	};

	const [extraHour, setExtraHour] = React.useState(ride?.extraTime1 || "");

	const handleExtraHourChange = (event) => {
		setExtraHour(event.target.value);
		setNewChange(true);
	};
	const { register, handleSubmit, reset } = useForm({
		defaultValues: {
			picupLocation1: "",
			dropOffLocation1: "",
		},
	});
	React.useEffect(() => {
		reset(ride);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		setDateTime(new Date(ride?.time1 || ""));
		setExtraHour(ride?.extraTime1 || "");
	}, [ride]);

	const onSubmit = ({ picupLocation1, dropOffLocation1 }) => {
		const data = {
			time1: dateTime,
			picupLocation1,
			dropOffLocation1,
			extraTime1: extraHour,
			rideType: "Distance",
			tab: 0,
			rideId: Math.round(Math.random() * 10000),
			paidPrice: 0,
			paid: false,
		};
		setRides(
			rides.map((r) => {
				if (r.rideId === ride.rideId) {
					return data;
				}
				return r;
			}),
		);

		setNewChange(false);

		/* reactLocalStorage.setObject("rideDetails", data);
		navigate(destination); */
	};

	return (
		<Container>
			<form onSubmit={handleSubmit(onSubmit)}>
				<LocalizationProvider dateAdapter={AdapterDateFns}>
					<Grid container spacing={2}>
						<Grid item md={6} xs={12}>
							<FormHelperText>PICKUP DATE</FormHelperText>
							<DesktopDatePicker
								inputFormat='MM/dd/yyyy'
								value={dateTime}
								onChange={handleChange}
								renderInput={(params) => (
									<TextField required fullWidth {...params} />
								)}
							/>
						</Grid>

						<Grid item md={6} xs={12}>
							<FormHelperText>PICKUP TIME</FormHelperText>
							<TimePicker
								value={dateTime}
								onChange={handleChange}
								ampm={false}
								renderInput={(params) => <TextField fullWidth {...params} />}
							/>
						</Grid>
						<Grid item md={12} xs={12}>
							<FormHelperText>PICKUP LOCATION</FormHelperText>
							<TextField
								fullWidth
								id='filled-search'
								placeholder='ENTER A LOCATION'
								type='standard'
								{...register("picupLocation1", { required: true })}
								onChange={() => setNewChange(true)}
							/>
						</Grid>
						<Grid item md={12} xs={12}>
							<FormHelperText>DROP-OFF LOCATION</FormHelperText>
							<TextField
								fullWidth
								id='filled-search'
								placeholder='ENTER A LOCATION'
								type='standard'
								{...register("dropOffLocation1", { required: true })}
								onChange={() => setNewChange(true)}
							/>
						</Grid>
						<Grid item md={12} xs={12}>
							<FormControl fullWidth>
								<FormHelperText sx={{ ml: 0 }}>EXTRA TIME</FormHelperText>
								<Select
									value={extraHour}
									onChange={handleExtraHourChange}
									displayEmpty
									inputProps={{ "aria-label": "Without label" }}>
									<MenuItem value=''>
										<em>0 HOUR(S)</em>
									</MenuItem>
									<MenuItem value={"1 HOUR"}>1 HOUR(S)</MenuItem>
									<MenuItem value={"2 HOUR"}>2 HOUR(S)</MenuItem>
									<MenuItem value={"3 HOUR"}>3 HOUR(S)</MenuItem>
									<MenuItem value={"4 HOUR"}>4 HOUR(S)</MenuItem>
									<MenuItem value={"5 HOUR"}>5 HOUR(S)</MenuItem>
								</Select>
							</FormControl>
						</Grid>

						<Grid item md={6} xs={12}>
							<Button
								type='submit'
								className='buttonColor'
								sx={{
									width: "100%",
									py: 1.7,
									display: !newChange && "none !important",
									backgroundColor: "red !important",
								}}
								variant='contained'>
								Next
							</Button>
						</Grid>
						<Grid item md={6} xs={12}>
							<Button
								onClick={() => {
									setRides([
										...rides,
										{
											rideId: Math.round(Math.random() * 10000),
										},
									]);
								}}
								className='buttonColor'
								variant='contained'
								sx={{
									width: "100%",
									py: 1.5,
									mb: 2,
									display:
										rides?.[rides?.length - 1]?.rideId !== ride?.rideId &&
										"none",
								}}>
								ADD NEW RIDE
							</Button>
						</Grid>
					</Grid>
				</LocalizationProvider>
			</form>
		</Container>
	);
}
