import {
	Backdrop,
	Box,
	Button,
	CardMedia,
	CircularProgress,
	Container,
	Divider,
	Grid,
	Typography,
} from "@mui/material";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { reactLocalStorage } from "reactjs-localstorage";
import Step3 from "../Steps/Step3";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SendIcon from "@mui/icons-material/Send";
import axios from "axios";
import Swal from "sweetalert2";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";

const TotalSummary = () => {
	const [submitting, setSubmitting] = React.useState(false);
	const vehicles = reactLocalStorage.getObject("vehicles");
	const rides = reactLocalStorage.getObject("rideDetails");
	const contactDetails = reactLocalStorage.getObject("contactDetails");
	const extraOpions = reactLocalStorage.getObject("importantOpions");
	const client = reactLocalStorage.getObject("client");
	const navigate = useNavigate();
	const destination = "/dashboard";

	function generateTransactionNumber() {
		// Generate random 5-digit number
		let part1 = Math.floor(10000 + Math.random() * 90000).toString();

		// Generate random 4-digit number
		let part2 = Math.floor(1000 + Math.random() * 9000).toString();

		// Generate random 3-digit number for the last part
		let part3 = "RT" + Math.floor(100 + Math.random() * 900).toString();

		// Combine all parts into the desired format
		let transactionNumber = `HST # ${part1} ${part2} ${part3}`;

		return transactionNumber;
	}

	const confirm = async (position) => {
		const data = {
			bookingTime: new Date().toISOString(),
			...vehicles,
			rides: rides,
			...contactDetails,
			...extraOpions,
			...client,
			quoted: position === "Pending" ? false : true,
			position: position,
			quotedPrice: 0,
			quoteAccepted: false,
			payments: [],
			hst: generateTransactionNumber(),
		};
		Swal.fire({
			title: "",
			text: `You want to ${position === "Pending"
				? "Confirm this booking request?"
				: "get a quote?"
				}`,
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: `Yes, ${position === "Pending" ? "Confirm" : "Get Quote"
				}!`,
			cancelButtonText: "No, cancel!",
			reverseButtons: true,
		}).then(async (result) => {
			if (result.isConfirmed) {
				setSubmitting(true);
				try {
					const response = await axios.post(
						`${process.env.REACT_APP_API_PATH}/bookings`,
						data,
					);

					if (response.status === 201) {
						setSubmitting(false);
						Swal.fire({
							icon: "success",
							title: `${position === "Pending" ? "Booking" : "Quote"
								} Requested Successfully!`,
							showConfirmButton: false,
							timer: 1500,
						}).then(function () {
							navigate(destination);
							reactLocalStorage.clear();
						});
					}
				} catch (error) {
					Swal.fire({
						icon: "error",
						title: "Error",
						text: error.response.data.message,
					});
				}
			} else if (result.dismiss === Swal.DismissReason.cancel) {
				Swal.fire(
					"Cancelled",
					"Your booking request is not confirmed",
					"error",
				);
			}
		});
	};

	return (
		<Container sx={{ mb: 2 }}>
			<Box sx={{ my: 2.5 }}>
				<Step3 />
			</Box>
			<Grid container spacing={3}>
				<Grid item md={4} xs={12}>
					<Box sx={{ textAlign: "left", bgcolor: "#F6F6F6", p: 1.5, mb: 1 }}>
						<Typography
							gutterBottom
							variant='h5'
							component='div'
							sx={{ mb: 2 }}>
							About
						</Typography>
						<Box>
							{/* <Typography
								gutterBottom
								variant='body'
								component='div'
								sx={{ fontWeight: "600" }}>
								Booking For ?
							</Typography>
							<Typography gutterBottom variant='body2' component='div'>
								{client?.forWhom || "N/A"}
							</Typography> */}
							<Divider sx={{ my: 1.5 }} />
							{(!client?.forWhom === "Booking for myself" ||
								client?.forWho) && (
									<>
										<Typography gutterBottom variant='body' component='div'>
											You Are ?
										</Typography>
										<Typography gutterBottom variant='body2' component='div'>
											{client?.forWho || "N/A"}
										</Typography>
										<Divider sx={{ my: 1.5 }} />
									</>
								)}
							{(!client?.forWhom === "Booking for myself" ||
								!client?.forWho === "A Person" ||
								client?.company ||
								client?.reqCompany) && (
									<>
										<Typography gutterBottom variant='body' component='div'>
											Company
										</Typography>
										<Typography gutterBottom variant='body2' component='div'>
											{client?.company || client?.reqCompany || "N/A"}
										</Typography>
										<Divider sx={{ my: 1.5 }} />
									</>
								)}
						</Box>
					</Box>
					<Box sx={{ textAlign: "left", bgcolor: "#F6F6F6", p: 1.5 }}>
						<Typography
							gutterBottom
							variant='h5'
							component='div'
							sx={{ mb: 2 }}>
							Conatct And Billing Info
						</Typography>
						<Box sx={{ display: "flex" }}>
							<Box>
								<Typography gutterBottom variant='body' component='div'>
									First Name
								</Typography>
								<Typography gutterBottom variant='body2' component='div'>
									{contactDetails?.firstName}
								</Typography>
							</Box>
							<Box sx={{ ml: 2 }}>
								<Typography gutterBottom variant='body' component='div'>
									Last Name
								</Typography>
								<Typography gutterBottom variant='body2' component='div'>
									{contactDetails?.lastName}
								</Typography>
							</Box>
						</Box>
						<Divider sx={{ my: 1.5 }} />
						<Typography gutterBottom variant='body' component='div'>
							Email Address
						</Typography>
						<Typography gutterBottom variant='body2' component='div'>
							{contactDetails?.userEmail}
						</Typography>
						<Divider sx={{ my: 1.5 }} />
						<Typography gutterBottom variant='body' component='div'>
							Phone Number
						</Typography>
						<Typography gutterBottom variant='body2' component='div'>
							{contactDetails?.phone}
						</Typography>
						<Divider sx={{ my: 1.5 }} />
						<Typography gutterBottom variant='body' component='div'>
							Notes
						</Typography>
						<Typography gutterBottom variant='body2' component='div'>
							{contactDetails?.comment || "N/A"}
						</Typography>
						<Divider sx={{ my: 1.5 }} />
					</Box>
				</Grid>
				<Grid item md={4} xs={12}>
					<Box sx={{ textAlign: "left", bgcolor: "#F6F6F6", p: 1.5 }}>
						<Typography
							gutterBottom
							variant='h5'
							component='div'
							sx={{ mb: 2 }}>
							Vehicle Info
						</Typography>
						<Divider sx={{ my: 1.5 }} />
						<Grid container spacing={2} sx={{ alignItems: "center" }}>
							<Grid item md={12} xs={12}>
								<CardMedia
									sx={{ width: "100%" }}
									component='img'
									alt=''
									image={vehicles?.carPhoto1}
								/>
							</Grid>
							<Grid item md={6} xs={6}>
								<CardMedia
									sx={{ width: "100%" }}
									component='img'
									alt=''
									image={vehicles?.carPhoto2}
								/>
							</Grid>
							<Grid item md={6} xs={6}>
								<CardMedia
									sx={{ width: "100%" }}
									component='img'
									alt=''
									image={vehicles?.carPhoto3}
								/>
							</Grid>
						</Grid>
						<Divider sx={{ my: 1.5 }} />
						<Typography gutterBottom variant='body' component='div'>
							Vehicle
						</Typography>
						<Typography gutterBottom variant='body2' component='div'>
							{vehicles?.carName}
						</Typography>
						<Divider sx={{ my: 1.5 }} />
					</Box>
				</Grid>
				<Grid item md={4} xs={12}>
					<Box
						sx={{
							textAlign: "left",
							bgcolor: "#F6F6F6",
							p: 1.5,
						}}>
						<Box
							sx={{
								maxHeight: "50vh",
								overflowY: "scroll",
							}}>
							<Typography
								gutterBottom
								variant='h5'
								component='div'
								sx={{
									mb: 2,
								}}>
								Ride details
							</Typography>
							<Typography gutterBottom variant='body' component='div'>
								SERVICE TYPE
							</Typography>
							{rides?.map((rideDetails) => (
								<Box
									sx={{
										border: "1px solid #A72C79",
										p: 2,
										my: 2,
									}}>
									{rideDetails?.rideType === "Hourly" && (
										<>
											<Typography gutterBottom variant='body2' component='div'>
												{rideDetails?.rideType}
											</Typography>
											<Divider sx={{ my: 1.5 }} />
											<Typography gutterBottom variant='body' component='div'>
												PICKUP LOCATION
											</Typography>
											<Typography gutterBottom variant='body2' component='div'>
												{rideDetails?.picupLocation2}
											</Typography>
											<Divider sx={{ my: 1.5 }} />
											<Typography gutterBottom variant='body' component='div'>
												DROP-OFF LOCATION
											</Typography>
											<Typography gutterBottom variant='body2' component='div'>
												{rideDetails?.dropOffLocation2}
											</Typography>
											<Divider sx={{ my: 1.5 }} />
											<Typography gutterBottom variant='body' component='div'>
												DURATION
											</Typography>
											<Typography gutterBottom variant='body2' component='div'>
												{rideDetails?.duration2}
											</Typography>
											<Divider sx={{ my: 1.5 }} />
											<Typography gutterBottom variant='body' component='div'>
												PICKUP DATE, TIME
											</Typography>
											<Typography gutterBottom variant='body2' component='div'>
												{new Date(rideDetails?.time2).toLocaleString("en-GB")}
											</Typography>
											<Divider sx={{ my: 1.5 }} />
										</>
									)}
									{rideDetails?.rideType === "Distance" && (
										<>
											<Typography gutterBottom variant='body2' component='div'>
												{rideDetails?.rideType}
											</Typography>
											<Divider sx={{ my: 1.5 }} />
											<Typography gutterBottom variant='body' component='div'>
												PICKUP LOCATION
											</Typography>
											<Typography gutterBottom variant='body2' component='div'>
												{rideDetails?.picupLocation1}
											</Typography>
											<Divider sx={{ my: 1.5 }} />
											<Typography gutterBottom variant='body' component='div'>
												DROP-OFF LOCATION
											</Typography>
											<Typography gutterBottom variant='body2' component='div'>
												{rideDetails?.dropOffLocation1}
											</Typography>
											<Divider sx={{ my: 1.5 }} />
											<Typography gutterBottom variant='body' component='div'>
												EXTRA TIME
											</Typography>
											<Typography gutterBottom variant='body2' component='div'>
												{rideDetails?.extraTime1}
											</Typography>
											<Divider sx={{ my: 1.5 }} />
											<Typography gutterBottom variant='body' component='div'>
												PICKUP DATE, TIME
											</Typography>
											<Typography gutterBottom variant='body2' component='div'>
												{new Date(rideDetails?.time1).toLocaleString("en-GB")}
											</Typography>
											<Divider sx={{ my: 1.5 }} />
										</>
									)}
									{rideDetails?.rideType === "Flat Rate" && (
										<>
											<Typography gutterBottom variant='body2' component='div'>
												{rideDetails?.rideType}
											</Typography>
											<Divider sx={{ my: 1.5 }} />

											<Typography gutterBottom variant='body' component='div'>
												SERVICE TYPE
											</Typography>
											<Typography gutterBottom variant='body2' component='div'>
												{rideDetails?.transferType3}
											</Typography>
											<Divider sx={{ my: 1.5 }} />
											<Typography gutterBottom variant='body' component='div'>
												DURATION
											</Typography>
											<Typography gutterBottom variant='body2' component='div'>
												{rideDetails?.duration3}
											</Typography>
											<Divider sx={{ my: 1.5 }} />
											<Typography gutterBottom variant='body' component='div'>
												PICKUP DATE, TIME
											</Typography>
											<Typography gutterBottom variant='body2' component='div'>
												{new Date(rideDetails?.time3).toLocaleString("en-GB")}
											</Typography>
											<Divider sx={{ my: 1.5 }} />
										</>
									)}
									{rideDetails?.rideType === "Chauffeur Services" && (
										<>
											<Typography gutterBottom variant='body2' component='div'>
												{rideDetails?.rideType}
											</Typography>
											<Divider sx={{ my: 1.5 }} />

											<Typography gutterBottom variant='body' component='div'>
												SERVICE TYPE
											</Typography>
											<Typography gutterBottom variant='body2' component='div'>
												{rideDetails?.transferType4}
											</Typography>
											<Divider sx={{ my: 1.5 }} />
											<Typography gutterBottom variant='body' component='div'>
												DURATION
											</Typography>
											<Typography gutterBottom variant='body2' component='div'>
												{rideDetails?.duration4}
											</Typography>
											<Divider sx={{ my: 1.5 }} />
											<Typography gutterBottom variant='body' component='div'>
												PICKUP DATE, TIME
											</Typography>
											<Typography gutterBottom variant='body2' component='div'>
												{new Date(rideDetails?.time4).toLocaleString("en-GB")}
											</Typography>
											<Divider sx={{ my: 1.5 }} />
										</>
									)}
									{rideDetails?.rideType === "Airport Service" && (
										<>
											<Typography gutterBottom variant='body2' component='div'>
												{rideDetails?.rideType}
											</Typography>
											<Divider sx={{ my: 1.5 }} />
											<Typography gutterBottom variant='body' component='div'>
												PICKUP DATE, TIME
											</Typography>
											<Typography gutterBottom variant='body2' component='div'>
												{new Date(rideDetails?.time5).toLocaleString("en-GB")}
											</Typography>
											<Divider sx={{ my: 1.5 }} />
											<Typography gutterBottom variant='body' component='div'>
												PICKUP LOCATION
											</Typography>
											<Typography gutterBottom variant='body2' component='div'>
												{rideDetails?.picupLocation5}
											</Typography>
											<Divider sx={{ my: 1.5 }} />
											<Typography gutterBottom variant='body' component='div'>
												AIRPORT
											</Typography>
											<Typography gutterBottom variant='body2' component='div'>
												{rideDetails?.airport}
											</Typography>
											<Divider sx={{ my: 1.5 }} />
											<Typography gutterBottom variant='body' component='div'>
												AIRLINE
											</Typography>
											<Typography gutterBottom variant='body2' component='div'>
												{rideDetails?.airline || "N/A"}
											</Typography>
											<Divider sx={{ my: 1.5 }} />
											<Typography gutterBottom variant='body' component='div'>
												FLIGHT NO
											</Typography>
											<Typography gutterBottom variant='body2' component='div'>
												{rideDetails?.flightNo || "N/A"}
											</Typography>
											<Divider sx={{ my: 1.5 }} />
											<Typography gutterBottom variant='body' component='div'>
												TERMINAL
											</Typography>
											<Typography gutterBottom variant='body2' component='div'>
												{rideDetails?.terminal || "N/A"}
											</Typography>
											<Divider sx={{ my: 1.5 }} />
											<Typography gutterBottom variant='body' component='div'>
												FLIGHT TIME
											</Typography>
											<Typography gutterBottom variant='body2' component='div'>
												{rideDetails?.flightTime || "N/A"}
											</Typography>
											<Divider sx={{ my: 1.5 }} />
											<Typography gutterBottom variant='body' component='div'>
												FLIGHT STATUS
											</Typography>
											<Typography gutterBottom variant='body2' component='div'>
												{rideDetails?.flightStatus || "N/A"}
											</Typography>
											<Divider sx={{ my: 1.5 }} />
											<Typography gutterBottom variant='body' component='div'>
												ORIGIN
											</Typography>
											<Typography gutterBottom variant='body2' component='div'>
												{rideDetails?.origin || "N/A"}
											</Typography>
											<Divider sx={{ my: 1.5 }} />
										</>
									)}
								</Box>
							))}
						</Box>
						<Divider sx={{ my: 1.5 }} />
						<Typography gutterBottom variant='body2' component='div'>
							<b>Passengers</b> :{" "}
							{extraOpions?.passengers === true
								? extraOpions?.passengersCount
								: "No"}
						</Typography>
						<Divider sx={{ my: 1.5 }} />
						<Typography gutterBottom variant='body2' component='div'>
							<b>Luggage</b> :{" "}
							{extraOpions?.luggage === true ? extraOpions?.luggageCount : "No"}
						</Typography>
						<Divider sx={{ my: 1.5 }} />
						<Typography gutterBottom variant='body2' component='div'>
							<b>Child Seat</b> :{" "}
							{extraOpions?.babySeat === true ? extraOpions?.babyCount : "No"}
						</Typography>
						<Divider sx={{ my: 1.5 }} />
						<Typography gutterBottom variant='body2' component='div'>
							<b>Car Seat</b> :{" "}
							{extraOpions?.carSeat === true ? extraOpions?.carSeatCount : "No"}
						</Typography>
						<Divider sx={{ my: 1.5 }} />
						<Typography gutterBottom variant='body2' component='div'>
							<b>Wheel Chair</b> :{" "}
							{extraOpions?.wheelchair === true
								? extraOpions?.wheelchairCount
								: "No"}
						</Typography>
						<Divider sx={{ my: 1.5 }} />
					</Box>
				</Grid>
			</Grid>
			<Grid container spacing={{ md: 2, xs: 0 }}>
				<Grid item md={6} xs={12}>
					<Link
						to='/dashboard/choosevehicle'
						style={{ textDecoration: "none" }}>
						<Button
							className='buttonColor'
							variant='contained'
							sx={{ width: "100%", py: 1.7, mt: 2 }}>
							<ArrowBackIcon sx={{ mr: 1 }} />
							Back
						</Button>
					</Link>
				</Grid>
				<Grid item md={6} xs={12}>
					<Button
						onClick={() => confirm("Quote")}
						sx={{
							width: "100%",
							py: 1.7,
							mt: 2,
							backgroundColor: "green",
							color: "#fff",
						}}
						variant='contained'
						endIcon={<RequestQuoteIcon />}>
						Get a Quote Now!
					</Button>
				</Grid>
				<Grid item md={12} xs={12}>
					<Button
						onClick={() => confirm("Pending")}
						sx={{
							width: "100%",
							py: 1.7,
							mt: 2,
							backgroundColor: "red",
							color: "#fff",
						}}
						variant='contained'
						endIcon={<SendIcon />}>
						Book Now!
					</Button>
				</Grid>
			</Grid>
			<Backdrop
				sx={{
					color: "#fff",
					zIndex: (theme) => theme.zIndex.drawer + 1,
				}}
				open={submitting}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</Container>
	);
};

export default TotalSummary;
