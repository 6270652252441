import { CardMedia, Container, Grid, Typography } from "@mui/material";
import React from "react";
import images from "../ImportImage";

const WhyUs = () => {
	const data = [
		{
			id: 1,
			image: images?.calender,
			title: "EASY ONLINE BOOKING",
			deccription:
				"Get peace-of-mind that your transport is taken care of by pre-booking online. Or book last minute and take advantage of our 24-hour, 7-day-a-week operation.",
			animation: "zoom-in-right",
		},
		{
			id: 2,
			image: images?.starIcon,
			title: "PROFESSIONALLY TRAINED DRIVERS",
			deccription:
				"Our fleet of insured, regulated limousines is operated by the best professionally-trained chauffeurs in Edmonton.",
			animation: "zoom-in-left",
		},
		{
			id: 3,
			image: images?.carIcon,
			title: "LUXURY FLEET OF VEHICLES",
			deccription:
				"The newest luxury vehicles from Lincoln, Mercedes-Benz, Cadillac, Ford, and Chevrolet, all spotlessly clean for your enjoyment.",
			animation: "zoom-in-right",
		},
		{
			id: 4,
			image: images?.visaIcon,
			title: "FLEXIBLE PAYMENT OPTIONS",
			deccription:
				"We accept pre-payment with major credit cards or pay in person with cash, credit or debit cards.",
			animation: "zoom-in-left",
		},
	];
	return (
		<Container sx={{ py: 7 }}>
			<Grid container spacing={2}>
				{data?.map((d,i) => (
					<Grid
						key={i}
						item
						xs={12}
						md={6}
						sx={{ textAlign: { md: "left", xs: "center" } }}
						data-aos={d?.animation}>
						<Grid container spacing={2}>
							<Grid item xs={12} md={2}>
								<CardMedia
									component='img'
									image={d?.image}
									alt=''
									sx={{
										width: { md: "80%", sm: "60px", xs: "60px" },
										mx: "auto",
									}}
								/>
							</Grid>
							<Grid item xs={12} md={10}>
								<Typography
									sx={{ fontWeight: "bold" }}
									className='textColor'
									variant='h6'
									component='div'
									gutterBottom>
									{d?.title?.toUpperCase()}
								</Typography>
								<Typography
									className='textColor'
									variant='subtitle2'
									component='div'
									gutterBottom>
									{d?.deccription}
								</Typography>
							</Grid>
						</Grid>
					</Grid>
				))}
			</Grid>
		</Container>
	);
};

export default WhyUs;
