import React from "react";
import { reactLocalStorage } from "reactjs-localstorage";
import BeatLoader from "react-spinners/BeatLoader";
import GroupIcon from "@mui/icons-material/Group";
import LuggageIcon from "@mui/icons-material/Luggage";

const Vehicles = ({ cars }) => {
	const data = reactLocalStorage.getObject("vehicles");
	const [selectedId, setSelectedId] = React.useState(data?.carId || "");

	const setCar = (car) => {
		setSelectedId(car?._id);
		const vehicleData = {
			carId: car?._id,
			carName: car?.name,
			carInfo: car?.features,
			carPhoto1: car?.images?.[0]?.url,
			carPhoto2: car?.images?.[1]?.url,
			carPhoto3: car?.images?.[2]?.url,
			carPassenger: car?.carPassenger,
			carLuggage: car?.carLuggage,
		};
		reactLocalStorage.setObject("vehicles", vehicleData);
	};

	return (
		<div style={{ padding: "10px" }}>
			{cars?.length > 0 ? (
				cars.map((car) => {
					const isSelected = selectedId === car?._id;

					return (
						<div
							key={car?._id}
							style={{
								border: "1px solid #ddd",
								borderRadius: "5px",
								margin: "5px 0",
								padding: "10px",
								display: "flex",
								alignItems: "center",
							}}>
							<img
								src={car?.images?.[0]?.url}
								alt={car?.name}
								style={{
									width: "60px",
									height: "auto",
									marginRight: "10px",
									borderRadius: "3px",
								}}
							/>
							<div style={{ flexGrow: 1 }}>
								<h3 style={{ margin: "0", fontSize: "16px" }}>{car?.name}</h3>
								<div
									style={{
										display: "flex",
										alignItems: "center",
										fontSize: "14px",
									}}>
									<span
										style={{
											display: "flex",
											alignItems: "center",
											marginRight: "10px",
										}}>
										<GroupIcon style={{ fontSize: 20 }} />
										<span>{car?.carPassenger}</span>
									</span>
									<span style={{ display: "flex", alignItems: "center" }}>
										<LuggageIcon style={{ fontSize: 20 }} />
										<span>{car?.carLuggage}</span>
									</span>
								</div>
							</div>
							<button
								onClick={() => setCar(car)}
								style={{
									background: isSelected ? "#4CAF50" : "#008CBA",
									color: "white",
									border: "none",
									borderRadius: "3px",
									padding: "5px 10px",
									fontSize: "12px",
									marginLeft: "10px",
								}}>
								{isSelected ? <>Selected</> : "Select"}
							</button>
						</div>
					);
				})
			) : (
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						marginTop: "20px",
					}}>
					<BeatLoader size={10} />
				</div>
			)}
		</div>
	);
};

export default Vehicles;
