import React from "react";

const Test = () => {
    const data = {
        _id: "666c5cc945105c11a7632eab",
        bookingTime: "2024-06-14T15:07:51.992Z",
        carId: 1,
        carName: "Ford SUV",
        carInfo: [
            {
                feature:
                    "Leather upholstery with multi-level heating passenger seats dual climate control.",
            },
            {
                feature:
                    "Most popular vehicles for Airport service, Business meeting, Dinners and Sporting event.",
            },
            {
                feature: "Daily newspaper & complimentary water bottles.",
            },
        ],
        carPhoto1:
            "https://res.cloudinary.com/realtor-crm/image/upload/v1697294064/car-booking-website-photos/ford-suv/ford-suv-2_l2exkk.jpg",
        carPhoto2:
            "https://res.cloudinary.com/realtor-crm/image/upload/v1697294063/car-booking-website-photos/ford-suv/ford-suv-1_rdekmf.png",
        carPhoto3:
            "https://res.cloudinary.com/realtor-crm/image/upload/v1697294064/car-booking-website-photos/ford-suv/ford-suv-3_emqaom.jpg",
        carPassenger: 6,
        carLuggage: 4,
        rides: [
            {
                time1: "2024-06-13T18:00:00.000Z",
                picupLocation1: "Chattogram",
                dropOffLocation1: "Dhaka",
                extraTime1: "3 HOUR",
                rideType: "Distance",
                tab: 0,
                rideId: 5100,
                paidPrice: 0,
                paid: false,
            },
            {
                time2: "2024-06-13T18:00:00.000Z",
                picupLocation2: "Test",
                dropOffLocation2: "Test2",
                duration2: "2 HOUR",
                rideType: "Hourly",
                tab: 1,
                rideId: 3184,
                paidPrice: 0,
                paid: false,
            },
            {
                time3: "2024-06-13T18:00:00.000Z",
                transferType3: "Wheel accessible",
                duration3: "5 HOUR",
                picupLocation3: "Test",
                rideType: "Flat Rate",
                tab: 2,
                rideId: 9739,
                paidPrice: 0,
                paid: false,
            },
            {
                time4: "2024-06-27T18:00:00.000Z",
                transferType4: "Funeral",
                duration4: "4 HOUR",
                picupLocation4: "Test",
                rideType: "Chauffeur Services",
                tab: 3,
                rideId: 542,
                paidPrice: 0,
                paid: false,
            },
            {
                time5: "2024-06-28T18:00:00.000Z",
                picupLocation5: "Test",
                airport: "Test",
                airline: "Test",
                flightNo: "Test",
                terminal: "Test",
                flightTime: "Test",
                flightStatus: "Test",
                origin: "Test",
                rideType: "Airport Service",
                tab: 4,
                rideId: 7839,
                paidPrice: 0,
                paid: false,
            },
        ],
        firstName: "Mr",
        lastName: "tester",
        userEmail: "windsor.nour@gmail.com",
        phone: "1234567",
        comment: "",
        passengers: true,
        passengersCount: 7,
        luggage: false,
        luggageCount: 0,
        wheelchair: false,
        wheelchairCount: 0,
        babySeat: false,
        babyCount: 0,
        carSeat: false,
        carSeatCount: 0,
        forWhom: "Booking on behalf",
        forWho: "A Person",
        reqCompany: "N/A",
        txn: "PER74660",
        quoted: true,
        position: "Quote",
        quotedPrice: 0,
        quoteAccepted: false,
        bookedBy: "suny.webdev@gmail.com",
        payments: [],
        hst: "HST # 92591 6892 RT850",
    };
    const formatDateTime = (dateTime) => {
        const date = new Date(dateTime);
        return {
            date: date.toLocaleDateString(),
            time: date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }),
        };
    };

    return (
        <div
            style={{
                fontFamily: "Arial, sans-serif",
                fontSize: "12px",
                lineHeight: "1.5",
                color: "#333",
                margin: 0,
                padding: 0,
                textAlign: "left",
            }}>
            <div style={{ maxWidth: "8.27in", padding: "20px", margin: "auto" }}>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}>
                    <div
                        style={{
                            textAlign: "center",
                            fontSize: "18px",
                            fontWeight: "bold",
                            marginBottom: "20px",
                            backgroundColor: "#000",
                            color: "#fff",
                            padding: "10px",
                            width: "max-content",
                            borderRadius: "15px",
                        }}>
                        Quotation Details
                    </div>
                    <div
                        style={{
                            textAlign: "right",
                            fontSize: "19px",
                            fontWeight: "bold",
                        }}>
                        Elegance Limousin Services
                    </div>
                </div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        border: "1px solid #ddd",
                        padding: "10px",
                        marginBottom: "20px",
                    }}>
                    <div style={{ width: "48%" }}>
                        <div style={{ marginBottom: "10px" }}>
                            <p style={{ margin: 0 }}>
                                <strong>Client ID:</strong> {data._id}
                            </p>
                            <p style={{ margin: 0 }}>
                                <strong>Client:</strong> {data.firstName} {data.lastName}
                            </p>
                            <p style={{ margin: 0 }}>
                                <strong>Email:</strong> {data.bookedBy}
                            </p>
                            <p style={{ margin: 0 }}>
                                <strong>Company:</strong> {data.reqCompany}
                            </p>
                        </div>
                    </div>
                    <div style={{ width: "48%" }}>
                        <div style={{ marginBottom: "10px" }}>
                            <p style={{ margin: 0 }}>
                                <strong>Quotation ID:</strong> {data.txn}
                            </p>
                            <p style={{ margin: 0 }}>
                                <strong>HST:</strong> {data.hst}
                            </p>
                            <p style={{ margin: 0 }}>
                                <strong>Passengers:</strong> {data.passengersCount}
                            </p>
                            <p style={{ margin: 0 }}>
                                <strong>Distance:</strong> N/A
                            </p>
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        border: "1px solid #ddd",
                        padding: "10px",
                        margin: "20px 0",
                    }}>
                    <p style={{ margin: 0 }}>
                        <strong>Rides:</strong>
                    </p>
                    {data.rides.map((ride, index) => (
                        <div
                            key={index}
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                border: "1px solid #ddd",
                                padding: "10px",
                            }}>
                            <div style={{ width: "48%" }}>
                                <div>
                                    <p style={{ margin: 0 }}>
                                        <strong>Pick-up Location:</strong>{" "}
                                        {ride.picupLocation1 ||
                                            ride.picupLocation2 ||
                                            ride.picupLocation3 ||
                                            ride.picupLocation4 ||
                                            ride.picupLocation5}
                                    </p>
                                    <p style={{ margin: 0 }}>
                                        <strong>Pick-up Date:</strong>{" "}
                                        {
                                            formatDateTime(
                                                ride.time1 ||
                                                ride.time2 ||
                                                ride.time3 ||
                                                ride.time4 ||
                                                ride.time5,
                                            ).date
                                        }
                                    </p>
                                    <p style={{ margin: 0 }}>
                                        <strong>Pick-up Time:</strong>{" "}
                                        {
                                            formatDateTime(
                                                ride.time1 ||
                                                ride.time2 ||
                                                ride.time3 ||
                                                ride.time4 ||
                                                ride.time5,
                                            ).time
                                        }
                                    </p>
                                    <p style={{ margin: 0 }}>
                                        <strong>Vehicle to Stay:</strong> No
                                    </p>
                                </div>
                            </div>
                            <div style={{ width: "48%" }}>
                                <div>
                                    <p style={{ margin: 0 }}>
                                        <strong>Destination:</strong>{" "}
                                        {ride.dropOffLocation1 || ride.dropOffLocation2}
                                    </p>
                                    <p style={{ margin: 0 }}>
                                        <strong>Arrival Date:</strong>{" "}
                                        {
                                            formatDateTime(
                                                ride.time1 ||
                                                ride.time2 ||
                                                ride.time3 ||
                                                ride.time4 ||
                                                ride.time5,
                                            ).date
                                        }
                                    </p>
                                    <p style={{ margin: 0 }}>
                                        <strong>Leave Date:</strong>{" "}
                                        {
                                            formatDateTime(
                                                ride.time1 ||
                                                ride.time2 ||
                                                ride.time3 ||
                                                ride.time4 ||
                                                ride.time5,
                                            ).date
                                        }
                                    </p>
                                    <p style={{ margin: 0 }}>
                                        <strong>Back Date:</strong>{" "}
                                        {
                                            formatDateTime(
                                                ride.time1 ||
                                                ride.time2 ||
                                                ride.time3 ||
                                                ride.time4 ||
                                                ride.time5,
                                            ).date
                                        }
                                    </p>
                                    <p style={{ margin: 0 }}>
                                        <strong>Time:</strong> 18:00
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                <div
                    style={{
                        border: "1px solid #ddd",
                        padding: "10px",
                        margin: "20px 0",
                    }}>
                    <table style={{ width: "100%", borderCollapse: "collapse" }}>
                        <thead>
                            <tr style={{ backgroundColor: "#ddd" }}>
                                <th
                                    style={{
                                        padding: "4px",
                                        border: "1px solid #ddd",
                                        fontWeight: "bold",
                                    }}>
                                    Quantity
                                </th>
                                <th
                                    style={{
                                        padding: "4px",
                                        border: "1px solid #ddd",
                                        fontWeight: "bold",
                                    }}>
                                    Seats
                                </th>
                                <th
                                    style={{
                                        padding: "4px",
                                        border: "1px solid #ddd",
                                        fontWeight: "bold",
                                    }}>
                                    Vehicle
                                </th>
                                <th
                                    style={{
                                        padding: "4px",
                                        border: "1px solid #ddd",
                                        fontWeight: "bold",
                                    }}>
                                    Unit Price
                                </th>
                                <th
                                    style={{
                                        padding: "4px",
                                        border: "1px solid #ddd",
                                        fontWeight: "bold",
                                    }}>
                                    Price
                                </th>
                                <th
                                    style={{
                                        padding: "4px",
                                        border: "1px solid #ddd",
                                        fontWeight: "bold",
                                    }}>
                                    Total
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr style={{ borderBottom: "1px solid #ddd" }}>
                                <td style={{ padding: "4px", border: "1px solid #ddd" }}>1</td>
                                <td style={{ padding: "4px", border: "1px solid #ddd" }}>
                                    {data.carPassenger}
                                </td>
                                <td style={{ padding: "4px", border: "1px solid #ddd" }}>
                                    {data.carName}
                                </td>
                                <td style={{ padding: "4px", border: "1px solid #ddd" }}>$0</td>
                                <td style={{ padding: "4px", border: "1px solid #ddd" }}>$0</td>
                                <td style={{ padding: "4px", border: "1px solid #ddd" }}>$0</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div
                    style={{
                        border: "1px solid #ddd",
                        padding: "10px",
                        marginBottom: "20px",
                    }}>
                    <p style={{ margin: 0 }}>
                        <strong>Vehicle Description:</strong>
                    </p>
                    <p style={{ margin: 0 }}>
                        {data.carInfo.map((info, index) => (
                            <span key={index}>{info.feature}</span>
                        ))}
                    </p>
                </div>
                <div
                    style={{
                        border: "1px solid #ddd",
                        padding: "10px",
                        marginBottom: "20px",
                    }}>
                    <p style={{ margin: 0 }}>
                        <strong>Further Requirements:</strong> All vehicle pictures can be
                        found at:{" "}
                        <a href='https://elegancelimo.ca' target='_blank' rel='noreferrer'>
                            https://elegancelimo.ca
                        </a>
                    </p>
                    <p style={{ margin: 0 }}>
                        It is customary to tip your limo/bus driver between 15% to 20% based
                        on your experience. Gratuities are not included in the price of your
                        charter.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Test;
