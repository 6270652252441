import { Box, CardMedia, Container, Grid, Typography } from "@mui/material";
import React from "react";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import SwiperCore, { Autoplay, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import images from "../ImportImage";
import { useLocation } from "react-router-dom";
SwiperCore.use([Autoplay, Pagination]);

const Footer = () => {
	const location = useLocation();
	if (
		location.pathname.includes("/dashboard") ||
		location.pathname.includes("/userDashboard") ||
		location.pathname.includes("/driverDashboard")
	)
		return null;

	const testimonials = [
		{
			id: 1,
			name: "John Doe",
			title: "CEO",
			image:
				"http://www.elegancelimo.ca/wp-content/uploads/2020/05/download.jpg",
			testimonial: "loved the service, and always on time , thanks guys.",
		},
		{
			id: 2,
			name: "John Doe",
			title: "CEO",
			image:
				"http://www.elegancelimo.ca/wp-content/uploads/2020/05/download.jpg",
			testimonial: "loved the service, and always on time , thanks guys.",
		},
		{
			id: 3,
			name: "John Doe",
			title: "CEO",
			image:
				"http://www.elegancelimo.ca/wp-content/uploads/2020/05/download.jpg",
			testimonial: "loved the service, and always on time , thanks guys.",
		},
	];
	return (
		<Box
			className='bgColor'
			sx={{
				textAlign: "left",
				color: "#DDDDDD",
				py: 5,
				overflowX: "hidden",
			}}>
			<Container>
				<Grid container spacing={2}>
					<Grid item xs={12} md={3} data-aos='fade-right'>
						<Typography
							sx={{ fontWeight: "bold" }}
							variant='button'
							component='div'
							gutterBottom>
							ABOUT US
						</Typography>
						<Typography
							sx={{ color: "#9B9B9B" }}
							variant='subtitle2'
							component='div'
							gutterBottom>
							Elegance Limousin Services is Windsor and Essex County’s premier
							livery experience company. Once you experience Elegance Limousin
							Services you won’t book anyone else for your events.
						</Typography>
						<CardMedia
							component='img'
							image='/logo.png'
							alt='logo'
							sx={{ width: "70px" }}
						/>
					</Grid>
					<Grid item xs={12} md={3} data-aos='fade-right'>
						<Typography
							sx={{ fontWeight: "bold" }}
							variant='button'
							component='div'
							gutterBottom>
							CONTACT INFO
						</Typography>
						<Typography
							sx={{ color: "#9B9B9B" }}
							variant='subtitle2'
							component='div'
							gutterBottom>
							Address: 515 Riverside Dr W, Windsor, ON N9A 5K3
						</Typography>
						<Box
							sx={{
								display: "flex",
								justifyContent: "flex-start",
								cursor: "pointer",
								mt: 2,
							}}
							onClick={() => window.open(`tel:519-969-4444`)}>
							<CallIcon sx={{ mr: 0.7 }} />
							<Typography
								variant='body2'
								gutterBottom
								sx={{ color: "#9B9B9B" }}>
								519-969-4444
							</Typography>
						</Box>{" "}
						<Box
							sx={{
								display: "flex",
								justifyContent: "flex-start",
								cursor: "pointer",
								my: 1,
							}}
							onClick={() => window.open(`mailto:info@elegancelimo.ca`)}>
							<EmailIcon sx={{ mr: 0.7 }} />
							<Typography
								variant='body2'
								gutterBottom
								sx={{ color: "#9B9B9B" }}>
								info@elegancelimo.ca
							</Typography>
						</Box>
						<iframe
							style={{
								width: "100%",
							}}
							src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2950.19318841587!2d-83.0474450529175!3d42.3170780821844!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x883b2d182c2878a7%3A0x481d018f43c8de3e!2sWaterpark%20Place%20Condominiums%2C%20515%20Riverside%20Dr%20W%2C%20Windsor%2C%20ON%20N9A%205K3%2C%20Canada!5e0!3m2!1sen!2sbd!4v1717218739164!5m2!1sen!2sbd'
							allowfullscreen=''
							loading='lazy'
							title='google-map'
							referrerpolicy='no-referrer-when-downgrade'></iframe>
						{/* <Box
							sx={{
								display: "flex",
								justifyContent: "flex-start",
								cursor: "pointer",
								mt: 1,
							}}
							onClick={() => window.open(`tel:1-888-969-0045`)}>
							<CallIcon sx={{ mr: 0.7 }} />
							<Typography
								variant='body2'
								gutterBottom
								sx={{ color: "#9B9B9B" }}>
								Toll free : 1-888-969-0045
							</Typography>
						</Box> */}
					</Grid>
					<Grid item xs={12} md={3} data-aos='fade-left'>
						<Typography
							sx={{ fontWeight: "bold" }}
							variant='button'
							component='div'
							gutterBottom>
							TESTIMONIAL
						</Typography>
						<Swiper
							autoHeight={true}
							grabCursor={true}
							loop={true}
							autoplay={{ delay: 2500 }}
							centeredSlides={true}
							slidesPerView={1}
							spaceBetween={30}
							className='mySwiper'>
							{testimonials?.map((a, key) => (
								<SwiperSlide key={key}>
									<Typography
										sx={{ border: "1px solid white", p: 1.5 }}
										variant='subtitle2'
										component='div'
										gutterBottom>
										{a?.testimonial}
									</Typography>
									<Box
										sx={{
											display: "flex",
											justifyContent: "flex-start",
											mt: 3,
										}}>
										<CardMedia
											component='img'
											image={a?.image}
											alt=''
											sx={{
												width: "70px",
												height: "70px",
												borderRadius: "50%",
											}}
										/>
										<Box sx={{ ml: 2 }}>
											<Typography
												sx={{ fontWeight: "bold" }}
												variant='button'
												component='div'
												gutterBottom>
												{a?.name}
											</Typography>
											<Typography
												sx={{ color: "#9B9B9B" }}
												variant='subtitle2'
												component='div'
												gutterBottom>
												{a?.title}
											</Typography>
										</Box>
									</Box>
								</SwiperSlide>
							))}
						</Swiper>
					</Grid>
					<Grid item xs={12} md={3} data-aos='fade-left'>
						<Typography
							sx={{ fontWeight: "bold" }}
							variant='button'
							component='div'
							gutterBottom>
							MOST CREDIT AND DEBIT CARD PAYMENTS ARE ACCEPTED
						</Typography>
						<CardMedia
							sx={{
								mt: 2,
								width: {
									sm: "50%",
									md: "100%",
								},
							}}
							component='img'
							image={images?.visaMastercard}
							alt='logo'
						/>
					</Grid>
				</Grid>
			</Container>
		</Box>
	);
};

export default Footer;
