import {
	Backdrop,
	Button,
	CircularProgress,
	Container,
	Grid,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import DeleteIcon from "@mui/icons-material/Delete";
import { useData } from "../../../contex/contex";

const AllUsers = () => {
	const [submitting, setSubmitting] = useState(false);
	const [allUserList, setAllUserList] = useState([]);

	const { token } = useData();
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(
					`${process.env.REACT_APP_API_PATH}/users`,
					{
						headers: {
							Authorization: token,
						},
					},
				);

				setAllUserList(response.data.reverse());
			} catch (error) {
				Swal.fire({
					icon: "error",
					title: "Error",
					text: error.response.data.message,
				});
			}
		};
		token && fetchData();
	}, [token, submitting]);

	const handleDelete = async (id) => {
		Swal.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, delete it!",
		}).then(async (result) => {
			if (result.isConfirmed) {
				setSubmitting(true);
				try {
					const response = await axios.delete(
						`${process.env.REACT_APP_API_PATH}/users/${id}`,
						{
							headers: {
								Authorization: token,
							},
						},
					);

					if (response.status) {
						Swal.fire({
							icon: "success",
							title: "Deleted Successfully",
							showConfirmButton: false,
							timer: 1500,
						});
						setSubmitting(false);
					}
				} catch (error) {
					Swal.fire({
						icon: "error",
						title: "Error",
						text: error.response.data.message,
					});
				}
			}
		});
	};

	let count = 1;

	return (
		<Container sx={{ mt: 2, minHeight: "100vh" }}>
			<Grid>
				<Typography
					sx={{ mb: 3, fontWeight: "bold" }}
					variant='h4'
					component='div'
					gutterBottom>
					All Users
				</Typography>

				<Paper
					className='container'
					sx={{ overflow: "auto", maxHeight: "80vh", maxWidth: "90vw" }}>
					<Table size='small' aria-label='a dense table'>
						<TableHead sx={{ th: { fontWeight: "bold" } }}>
							<TableRow>
								<TableCell align='left'>No</TableCell>
								<TableCell align='left'>Name</TableCell>
								<TableCell align='left'>Email</TableCell>
								<TableCell align='left'>Phone</TableCell>
								<TableCell align='left'>Role</TableCell>
								<TableCell align='left'>Joined On</TableCell>
								<TableCell align='center'>Action</TableCell>
							</TableRow>
						</TableHead>
						{allUserList?.length > 0 ? (
							<TableBody sx={{ td: { py: 1 } }}>
								{allUserList.map((user) => (
									<TableRow
										key={user?._id}
										sx={{
											"&:last-child td, &:last-child th": { border: 0 },
										}}>
										<TableCell align='left'>{count++}</TableCell>
										<TableCell align='left'>{user?.displayName}</TableCell>
										<TableCell align='left'>{user?.email}</TableCell>
										<TableCell align='left'>{user?.phone}</TableCell>
										<TableCell align='left'>
											<select
												style={{
													border: "none",
													backgroundColor: "transparent",
													fontWeight: "bold",
													color:
														user?.userRole === "Admin"
															? "red"
															: user?.userRole === "Developer"
																? "blue"
																: "black",
												}}
												defaultValue={user?.userRole}
												onChange={(e) => {
													Swal.fire({
														title: "Are you sure?",
														text: "You want to update this user role!",
														icon: "warning",
														showCancelButton: true,
														confirmButtonColor: "#3085d6",
														cancelButtonColor: "#d33",
														confirmButtonText: "Yes, update it!",
													}).then(async (result) => {
														if (result.isConfirmed) {
															setSubmitting(true);
															try {
																const response = await axios.patch(
																	`${process.env.REACT_APP_API_PATH}/users/pageRole`,
																	{
																		userRole: e.target.value,
																		email: user?.email,
																	},
																	{
																		headers: {
																			Authorization: token,
																		},
																	},
																);

																if (response.status) {
																	Swal.fire({
																		icon: "success",
																		title: "Updated Successfully",
																		showConfirmButton: false,
																		timer: 1500,
																	});
																	setSubmitting(false);
																}
															} catch (error) {
																Swal.fire({
																	icon: "error",
																	title: "Error",
																	text: error.response.data.message,
																});
															}
														}
													});
												}}>
												<option value='Driver'>Driver</option>
												<option value='User'>User</option>
												<option value='Admin'>Admin</option>
												<option value='Developer'>Developer</option>
											</select>
										</TableCell>
										<TableCell align='left'>{user?.joinedDate}</TableCell>
										<TableCell align='center'>
											<Button
												className='buttonColor'
												onClick={() => handleDelete(user?._id)}
												sx={{
													fontWeight: "bold",
													border: "2px solid",
													backgroundColor: "transparent",
													borderRadius: "25px",
													m: 0.5,
												}}
												variant='contained'>
												<DeleteIcon />
											</Button>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						) : (
							<TableHead sx={{ th: { fontWeight: "bold" } }}>
								<TableRow>
									<TableCell align='left'>N/A</TableCell>
									<TableCell align='left'>N/A</TableCell>
									<TableCell align='left'>N/A</TableCell>
									<TableCell align='left'>N/A</TableCell>
									<TableCell align='left'>N/A</TableCell>
									<TableCell align='left'>N/A</TableCell>
									<TableCell align='left'>N/A</TableCell>
								</TableRow>
							</TableHead>
						)}
					</Table>
				</Paper>
			</Grid>
			<Backdrop
				sx={{
					color: "#fff",
					zIndex: (theme) => theme.zIndex.drawer + 1,
				}}
				open={submitting || !allUserList}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</Container>
	);
};

export default AllUsers;
