import { CircularProgress } from "@mui/material";
import React from "react";

const Loading = () => {
	return (
		<div>
			<CircularProgress
				style={{
					position: "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-50%, -50%)",
				}}
			/>
		</div>
	);
};

export default Loading;
