import {
	Button,
	Container,
	Grid,
	TextField,
	Typography,
	CircularProgress,
	Backdrop,
	CardMedia,
	Box,
} from "@mui/material";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import Swal from "sweetalert2";

import { useData } from "../../../contex/contex";
import ImageUpload from "../../../ImageUpload/ImageUpload";

const AddCar = () => {
	const [submitting, setSubmitting] = useState(false);
	const [images, setImages] = useState([]);
	const [features, setFeatures] = useState([]);

	const { register, handleSubmit, reset } = useForm();
	const { token } = useData();

	const onSubmit = async ({
		name,
		carLuggage,
		carPassenger,
		passengerCapacity,
		trunkCapacity,
	}) => {
		const data = {
			carId: Math.floor(Math.random() * 90000) + 10000,
			name,
			carLuggage,
			passengerCapacity,
			carPassenger,
			trunkCapacity,
			images,
			features,
		};
		setSubmitting(true);
		try {
			const response = await axios.post(`${process.env.REACT_APP_API_PATH}/cars`, data, {
				headers: {
					Authorization: token,
				},
			});

			if (response.status === 201) {
				setSubmitting(false);
				Swal.fire({
					icon: "success",
					title: "New Car Added Successfully",
					showConfirmButton: false,
					timer: 1500,
				}).then(function () {
					reset();
					setImages([]);
				});
			}
		} catch (error) {
			Swal.fire({
				icon: "error",
				title: "Error",
				text: error.response.data.message,
			});
		}
	};

	return (
		<Container sx={{ mt: { md: 0, xs: 7 } }}>
			<Grid
				container
				direction='column'
				alignItems='center'
				justifyContent='center'
				sx={{ minHeight: { md: "100vh", xs: "90vh" } }}>
				<Typography
					sx={{ mb: 4, fontWeight: 900 }}
					variant='h3'
					component='div'
					gutterBottom
					data-aos='fade-right'>
					ADD NEW CAR
				</Typography>
				<ImageUpload
					onSuccess={(data) => setImages((prev) => [...prev, data])}
					folder='car-booking-website-photos'
				/>
				{images?.length && (
					<Grid container spacing={2} sx={{ mb: 3 }}>
						{images?.map((image, key) => (
							<Grid item md={4} xs={12} key={key}>
								<CardMedia
									sx={{ width: "100%" }}
									component='img'
									alt=''
									image={image?.url}
								/>
							</Grid>
						))}
					</Grid>
				)}

				<Grid container spacing={2}>
					<Grid item md={7} xs={12} sx={{ mx: "auto" }}>
						<form onSubmit={handleSubmit(onSubmit)}>
							{images?.length && (
								<>
									<TextField
										size='small'
										required
										sx={{ width: "100%", mb: 2 }}
										id='outlined-multiline-flexible'
										label='Car Name'
										{...register("name", { required: true })}
									/>
									<TextField
										size='small'
										required
										sx={{ width: "100%", mb: 2 }}
										id='"outlined-multiline-flexible'
										label='Max Passenger'
										type='number'
										{...register("carPassenger", { required: true })}
									/>
									<TextField
										size='small'
										required
										sx={{ width: "100%", mb: 2 }}
										id='"outlined-multiline-flexible'
										label='Max Passenger Details'
										{...register("passengerCapacity", { required: true })}
									/>
									<TextField
										size='small'
										type='number'
										required
										sx={{ width: "100%", mb: 2 }}
										id='"outlined-multiline-flexible'
										label='Max Luggage'
										{...register("carLuggage", { required: true })}
									/>
									<TextField
										size='small'
										required
										sx={{ width: "100%", mb: 2 }}
										id='"outlined-multiline-flexible'
										label='Max Passenger Details'
										{...register("trunkCapacity", { required: true })}
									/>
									<Button
										variant='contained'
										className='buttonColor'
										onClick={() =>
											setFeatures((prev) => [
												...prev,
												{
													feature: "",
												},
											])
										}>
										Add Features +
									</Button>
									{features?.map((feature, key) => (
										<Box
											key={key}
											sx={{
												display: "flex",
												alignItems: "center",
												justifyContent: "center",
												mb: 2,
											}}>
											<TextField
												size='small'
												key={key}
												required
												sx={{ width: "100%" }}
												id='"outlined-multiline-flexible'
												label={`Feature ${key + 1}`}
												value={feature?.feature}
												onChange={(e) => {
													setFeatures((prev) =>
														prev.map((item, index) =>
															index === key
																? { ...item, feature: e.target.value }
																: item,
														),
													);
												}}
											/>
											<Button
												variant='contained'
												className='buttonColor'
												onClick={() =>
													setFeatures((prev) =>
														prev.filter((item, index) => index !== key),
													)
												}>
												Remove
											</Button>
										</Box>
									))}
									<Button
										type='submit'
										variant='contained'
										className='buttonColor'
										sx={{
											width: "100%",
											my: 2,
											px: 3,
											fontWeight: "bold",
											border: "2px solid",
											borderRadius: "25px",
										}}>
										ADD CAR
									</Button>
								</>
							)}
						</form>
					</Grid>
				</Grid>
			</Grid>
			<Backdrop
				sx={{
					color: "#fff",
					zIndex: (theme) => theme.zIndex.drawer + 1,
				}}
				open={submitting}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</Container>
	);
};

export default AddCar;
