import { Container, Grid } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import Step0 from "../Steps/Step0";
import ContactForm from "./ContactForm";

const Contact = () => {
	return (
		<Container>
			<Box sx={{ my: 2.5 }}>
				<Step0 />
			</Box>
			<Grid container spacing={2} sx={{ mb: 2 }}>
				{/* <Grid item md={4} xs={12}>
					<Summary />
				</Grid> */}
				<Grid
					item
					md={8}
					xs={12}
					sx={{
						mx: "auto",
					}}>
					<ContactForm />
				</Grid>
			</Grid>
		</Container>
	);
};

export default Contact;
