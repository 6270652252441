import * as React from "react";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { Button, Container, FormHelperText, Grid } from "@mui/material";
import { useForm } from "react-hook-form";
import axios from "axios";
import Swal from "sweetalert2";

export default function Tab5({ data, token, id, handleClose }) {
	const [dateTime, setValue] = React.useState(new Date(data?.time5) || "");

	const handleChange = (newValue) => {
		setValue(newValue);
	};
	const { register, handleSubmit, reset } = useForm({
		defaultValues: {
			picupLocation5: "",
			airport: "",
			airline: "",
			flightNo: "",
			terminal: "",
			flightTime: "",
			flightStatus: "",
			origin: "",
		},
	});
	React.useEffect(() => {
		reset(data);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const onSubmit = ({
		picupLocation5,
		airport,
		airline,
		flightNo,
		terminal,
		flightTime,
		flightStatus,
		origin,
	}) => {
		const data = {
			time5: dateTime,
			picupLocation5,
			airport,
			airline,
			flightNo,
			terminal,
			flightTime,
			flightStatus,
			origin,
			rideType: "Airport Service",
			tab: 4,
		};
		axios
			.patch(
				`${process.env.REACT_APP_API_PATH}/bookings/${id}`,
				{
					...data,
				},
				{
					headers: {
						Authorization: token,
					},
				},
			)
			.then((res) => {
				Swal.fire({
					icon: "success",
					title: "Success",
					text: res.data.message,
				});
				handleClose();
			})
			.catch((err) => {
				Swal.fire({
					icon: "error",
					title: "Error",
					text: err.response.data.message,
				});
			});
	};
	return (
		<Container>
			<form onSubmit={handleSubmit(onSubmit)}>
				<LocalizationProvider dateAdapter={AdapterDateFns}>
					<Grid container spacing={2}>
						<Grid item md={6} xs={12}>
							<FormHelperText>PICKUP DATE</FormHelperText>
							<DesktopDatePicker
								inputFormat='MM/dd/yyyy'
								value={dateTime}
								onChange={handleChange}
								renderInput={(params) => (
									<TextField required fullWidth {...params} />
								)}
							/>
						</Grid>

						<Grid item md={6} xs={12}>
							<FormHelperText>PICKUP TIME</FormHelperText>
							<TimePicker
								ampm={false}
								value={dateTime}
								onChange={handleChange}
								renderInput={(params) => <TextField fullWidth {...params} />}
							/>
						</Grid>
						<Grid item md={6} xs={12}>
							<FormHelperText>PICKUP LOCATION</FormHelperText>
							<TextField
								fullWidth
								id='filled-search'
								placeholder='ENTER A LOCATION'
								type='standard'
								{...register("picupLocation5", { required: true })}
							/>
						</Grid>
						<Grid item md={6} xs={12}>
							<FormHelperText>AIRPORT</FormHelperText>
							<TextField
								fullWidth
								id='filled-search'
								placeholder='AIRPORT'
								type='standard'
								{...register("airport", { required: true })}
							/>
						</Grid>
						<Grid item md={6} xs={12}>
							<FormHelperText>AIRLINE</FormHelperText>
							<TextField
								fullWidth
								id='filled-search'
								placeholder='AIRLINE'
								type='standard'
								{...register("airline")}
							/>
						</Grid>
						<Grid item md={6} xs={12}>
							<FormHelperText>FLIGHT NO</FormHelperText>
							<TextField
								fullWidth
								id='filled-search'
								placeholder='FLIGHT NO'
								type='standard'
								{...register("flightNo")}
							/>
						</Grid>
						<Grid item md={6} xs={12}>
							<FormHelperText>TERMINAL</FormHelperText>
							<TextField
								fullWidth
								id='filled-search'
								placeholder='TERMINAL'
								type='standard'
								{...register("terminal")}
							/>
						</Grid>
						<Grid item md={6} xs={12}>
							<FormHelperText>FLIGHT TIME</FormHelperText>
							<TextField
								fullWidth
								id='filled-search'
								placeholder='FLIGHT TIME. Ex: 05:20 pm'
								type='standard'
								{...register("flightTime")}
							/>
						</Grid>
						<Grid item md={6} xs={12}>
							<FormHelperText>FLIGHT STATUS</FormHelperText>
							<TextField
								fullWidth
								id='filled-search'
								placeholder='FLIGHT STATUS'
								type='standard'
								{...register("flightStatus")}
							/>
						</Grid>
						<Grid item md={6} xs={12}>
							<FormHelperText>ORIGIN</FormHelperText>
							<TextField
								fullWidth
								id='filled-search'
								placeholder='ORIGIN'
								type='standard'
								{...register("origin")}
							/>
						</Grid>
						<Button
							type='submit'
							className='buttonColor'
							variant='contained'
							sx={{ py: 1.7, mt: 2 }}>
							Update Booking
						</Button>
					</Grid>
				</LocalizationProvider>
			</form>
		</Container>
	);
}
