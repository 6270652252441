import {
	Box,
	Button,
	CardMedia,
	Grid,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	Typography,
} from "@mui/material";
import { Container } from "@mui/system";
import React, { useEffect, useState } from "react";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import GetData from "../../GetData";
import { Link, useParams } from "react-router-dom";

const Cars = () => {
	const { car } = useParams();
	const { allCars } = GetData();
	const [selectImage, setSelectImage] = useState(0);
	const [selectCar, setSelectCar] = useState();
	useEffect(() => {
		setSelectCar(allCars?.find((a) => a?.name === car));
	}, [allCars, car]);

	return (
		<Container>
			<Grid container spacing={2}>
				<Grid item xs={12} md={8}>
					<CardMedia
						data-aos='fade-top'
						sx={{
							transition: "transform 1s ease",
							transform: "translateX(10px)",
						}}
						component='img'
						image={selectCar?.images?.[selectImage]?.url}
						alt=''
					/>
					<Box sx={{ display: "flex", overflowX: "scroll" }}>
						{selectCar?.images?.map((image, index) => (
							<CardMedia
								key={index}
								onClick={() => setSelectImage(index)}
								component='img'
								image={image?.url}
								alt=''
								sx={{
									width: "110px",
								}}
							/>
						))}
					</Box>
					<Box sx={{ my: 5 }} data-aos='fade-left'>
						<Typography
							sx={{ fontWeight: "bold", textAlign: "left", mb: 2 }}
							className='textColor'
							variant='h5'
							component='div'
							gutterBottom>
							Features
						</Typography>
						<Typography
							sx={{ fontWeight: "bold", textAlign: "left", mb: 2 }}
							className='textColor'
							variant='h5'
							component='div'
							gutterBottom>
							SERVICE OFFERED IN THIS VEHICLE
						</Typography>
						<List>
							{selectCar?.features?.map?.((feature) => (
								<ListItem disablePadding>
									<ListItemButton>
										<ArrowRightAltIcon fontSize='small' sx={{ mr: 1.5 }} />
										<ListItemText secondary={feature?.feature} />
									</ListItemButton>
								</ListItem>
							))}
						</List>
					</Box>
				</Grid>
				<Grid item xs={12} md={4} data-aos='fade-right'>
					<CardMedia
						sx={{
							transition: "transform 1s ease",
							transform: "translateX(10px)",
						}}
						component='img'
						image={selectCar?.images?.[0]?.image}
						alt=''
					/>
					<Typography
						sx={{ fontWeight: "bold", textAlign: "left", mb: 2 }}
						className='textColor'
						variant='h5'
						component='div'
						gutterBottom>
						{selectCar?.name}
					</Typography>
					<List>
						<ListItem
							disablePadding
							sx={{ backgroundColor: "#F5F5F5 !important", py: 0.7, mb: 0.5 }}>
							<ListItemButton>
								<DirectionsCarIcon fontSize='small' sx={{ mr: 1.5 }} />
								<ListItemText secondary={selectCar?.passengerCapacity} />
							</ListItemButton>
						</ListItem>
						<ListItem
							disablePadding
							sx={{ backgroundColor: "#F5F5F5 !important", py: 0.7, mb: 0.5 }}>
							<ListItemButton>
								<DirectionsCarIcon fontSize='small' sx={{ mr: 1.5 }} />
								<ListItemText secondary={selectCar?.trunkCapacity} />
							</ListItemButton>
						</ListItem>
						<Link to='/startBooking' style={{ textDecoration: "none" }}>
							<Button
								variant='contained'
								disableElevation
								sx={{
									backgroundColor: "#E5E5E5",
									color: "#292929",
									fontWeight: "bold",
									"&:hover": {
										backgroundColor: "#292929",
										color: "#F5F5F5",
									},
									py: 1.5,
									width: "100%",
								}}>
								Book Now
							</Button>
						</Link>
					</List>
				</Grid>
			</Grid>
		</Container>
	);
};

export default Cars;
