import React, { useEffect, useState } from "react";
import CustomerTypeQuickBooking from "./CustomerTypeQuickBooking";
import ContactForm from "./ContactForm";
import RideDetails from "./RideDetails";
import ChooseVehicle from "./ChooseVehicle";
import TotalSummary from "./TotalSummary";
import { useData } from "../../contex/contex";
import axios from "axios";
import Swal from "sweetalert2";
import BeatLoader from "react-spinners/BeatLoader";

const QuickBooking = () => {
    const { user, token } = useData();

    const [companyList, setCompanyList] = useState([]);
    const [allUserList, setAllUserList] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_PATH}/companylist`,
                    { headers: { Authorization: token } },
                );
                setCompanyList(response.data.reverse());
            } catch (error) {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text:
                        error.response?.data?.message || "Failed to fetch company list.",
                });
            } finally {
                setLoading(false);
            }
        };
        token && fetchData();
    }, [token]);

    useEffect(() => {
        const fetchUsers = async () => {
            setLoading(true);
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_PATH}/users`,
                    { headers: { Authorization: token } },
                );
                setAllUserList(response.data.reverse());
            } catch (error) {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: error.response?.data?.message || "Failed to fetch user list.",
                });
            } finally {
                setLoading(false);
            }
        };
        token && fetchUsers();
    }, [token]);

    const [cars, setCars] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_PATH}/cars`,
                );

                setCars(response.data.reverse());
            } catch (error) {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: error.response.data.message,
                });
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    const [step, setStep] = useState(
        localStorage.getItem("quickBookingStep")?.replace(/"/g, "") || "person",
    );

    if (loading) return <BeatLoader size={10} />;

    return (
        <div>
            {step === "person" ? (
                <CustomerTypeQuickBooking
                    setStep={setStep}
                    companyList={companyList}
                    allUserList={allUserList}
                    user={user}
                />
            ) : step === "contact" ? (
                <ContactForm setStep={setStep} />
            ) : step === "booking" ? (
                <RideDetails setStep={setStep} />
            ) : step === "choosevehicle" ? (
                <ChooseVehicle setStep={setStep} cars={cars} />
            ) : step === "totalsummary" ? (
                <TotalSummary setStep={setStep} />
            ) : null}
        </div>
    );
};

export default QuickBooking;
