import { Box, Container, Typography } from "@mui/material";
import React from "react";
import OurFleet from "../../Components/OurFleet";
import WhatAboutPricing from "../../Components/WhatAboutPricing";

const AboutUs = () => {
	return (
		<Container>
			<OurFleet />
			<Box sx={{ py: 7 }} data-aos='zoom-in-up'>
				<Typography
					sx={{ fontWeight: "bold" }}
					className='textColor'
					variant='h5'
					component='div'
					gutterBottom>
					HELLO
				</Typography>
				<Typography
					className='textColor'
					variant='subtitle2'
					component='div'
					gutterBottom
					sx={{ textAlign: "justify", lineHeight: 2 }}>
					Elegance Limo Services is an owned company that specializes in
					personal and business transportation. We are licensed to operate our
					private limo services in southern Ontario, Essex counties and cities,
					metro Detroit and downtown Detroit Michigan surround. We strive to
					provide quality services to every client. Our mission is to make every
					ride comfortable and memorable with our superior services, friendly
					staff and professional courteous chauffeurs. Elegance Limo Is not just
					a ride, it’s a five-star experience when you step into one of our
					vehicles.
					<br />
					<br />
					Please call us to make your reservations 24/7 at 519-969-4444
					888-969-0045
				</Typography>
			</Box>
			<WhatAboutPricing />
		</Container>
	);
};

export default AboutUs;
