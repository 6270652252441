import { useState } from "react";
import Swal from "sweetalert2";
import {
	Box,
	Button,
	IconButton,
	LinearProgress,
	Typography,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import axios from "axios";
import styled from "@emotion/styled";

const ImageUpload = ({ onSuccess, folder }) => {
	const Input = styled("input")({
		display: "none",
	});
	const [selectedFile, setSelectedFile] = useState(null);
	const [previewUrl, setPreviewUrl] = useState("");
	const [loading, setLoading] = useState(false);
	const [uploadProgress, setUploadProgress] = useState(0);

	const uploadImage = async () => {
		if (!selectedFile) {
			Swal.fire("Oops!", "Please select an image.", "error");
			return;
		}

		setLoading(true);

		try {
			const formData = new FormData();
			formData.append("file", selectedFile);
			formData.append("upload_preset", folder);

			const response = await axios.post(
				`https://api.cloudinary.com/v1_1/realtor-crm/auto/upload`,
				formData,
				{
					onUploadProgress: (progressEvent) => {
						const progress = Math.round(
							(progressEvent.loaded / progressEvent.total) * 100,
						);
						setUploadProgress(progress);
					},
				},
			);

			if (response.data.secure_url) {
				Swal.fire("Success!", "Image uploaded successfully!", "success");
				onSuccess({
					url: response.data.secure_url,
					mediaId: response.data.public_id,
					bytes: response.data.bytes,
					fileType: response.data.format,
					name: response.data.original_filename,
				});
				setPreviewUrl("");
				setSelectedFile(null);
			} else {
				Swal.fire(
					"Oops!",
					"An error occurred while uploading the image.",
					"error",
				);
			}
		} catch (error) {
			console.error("Error uploading image:", error);
			Swal.fire("Oops!", error?.response?.data?.message, "error");
		} finally {
			setLoading(false);
			setUploadProgress(0);
		}
	};

	const handleFileChange = (e) => {
		const file = e.target.files[0];
		setSelectedFile(file);
		const reader = new FileReader();
		reader.onload = () => {
			setPreviewUrl(reader.result);
		};
		reader.readAsDataURL(file);
	};

	return (
		<Box>
			<Box
				display='flex'
				flexDirection='column'
				alignItems='center'
				sx={{ mx: "auto", px: 2 }}>
				<label
					htmlFor='image-upload-input'
					className='bgColor'
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						margin: "0 9px",
						borderRadius: 5,
					}}>
					<Input
						accept='image/*'
						type='file'
						onChange={handleFileChange}
						id='image-upload-input'
					/>
					<Typography
						sx={{ mx: 2, color: "white" }}
						variant='h6'
						component='div'
						gutterBottom>
						Upload Photo
					</Typography>
					<IconButton
						sx={{ color: "white" }}
						aria-label='upload picture'
						component='span'>
						<CloudUploadIcon fontSize='large' sx={{ fontWeight: "bold" }} />
					</IconButton>
				</label>
			</Box>
			{previewUrl && selectedFile.type.startsWith("image/") ? (
				<Box sx={{ mt: 2 }}>
					<img
						src={previewUrl}
						alt='Preview'
						style={{
							maxHeight: "400px",
							maxWidth: "400px",
							width: "100%",
						}}
					/>
				</Box>
			) : (
				""
			)}

			{!loading && selectedFile && (
				<Button
					className='bgColor'
					variant='contained'
					color='primary'
					sx={{ mt: 2 }}
					onClick={uploadImage}>
					Upload
				</Button>
			)}
			{loading && (
				<LinearProgress
					variant='determinate'
					value={uploadProgress}
					sx={{ mt: 2 }}
				/>
			)}
		</Box>
	);
};

export default ImageUpload;
