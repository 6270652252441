import * as React from "react";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import {
	Button,
	Container,
	FormControl,
	FormHelperText,
	Grid,
	MenuItem,
	Select,
} from "@mui/material";
import { useForm } from "react-hook-form";

export default function Tab4({ rides, setRides, ride }) {
	const [dateTime, setDateTime] = React.useState(new Date(ride?.time4) || "");

	const [newChange, setNewChange] = React.useState(false);
	const handleChange = (newValue) => {
		setDateTime(newValue);
		setNewChange(true);
	};

	const [duration, setDuration] = React.useState(ride?.duration4 || "");
	const handleExtraHourChange = (event) => {
		setDuration(event.target.value);
		setNewChange(true);
	};
	const [type, setType] = React.useState(ride?.transferType4 || "");
	const handleTypeChange = (event) => {
		setType(event.target.value);
		setNewChange(true);
	};

	const [picupLocation4, setPicupLocation4] = React.useState(
		ride?.picupLocation4 || "",
	);
	const { handleSubmit } = useForm();
	React.useEffect(() => {
		setDateTime(new Date(ride?.time4 || ""));
		setDuration(ride?.duration4 || "");
		setType(ride?.transferType4 || "");
		setPicupLocation4(ride?.picupLocation4 || "");
	}, [ride]);

	const onSubmit = () => {
		const data = {
			time4: dateTime,
			transferType4: type,
			duration4: duration,
			picupLocation4: picupLocation4,
			rideType: "Chauffeur Services",
			tab: 3,
			rideId: Math.round(Math.random() * 10000),
			paidPrice: 0,
			paid: false,
		};
		setRides(
			rides.map((r) => {
				if (r.rideId === ride.rideId) {
					return data;
				}
				return r;
			}),
		);

		setNewChange(false);
		/* reactLocalStorage.setObject("rideDetails", data);
		navigate(destination); */
	};
	return (
		<Container>
			<form onSubmit={handleSubmit(onSubmit)}>
				<LocalizationProvider dateAdapter={AdapterDateFns}>
					<Grid container spacing={2}>
						<Grid item md={6} xs={12}>
							<FormHelperText>PICKUP DATE</FormHelperText>
							<DesktopDatePicker
								inputFormat='MM/dd/yyyy'
								value={dateTime}
								onChange={handleChange}
								renderInput={(params) => <TextField fullWidth {...params} />}
							/>
						</Grid>

						<Grid item md={6} xs={12}>
							<FormHelperText>PICKUP TIME</FormHelperText>
							<TimePicker
								ampm={false}
								value={dateTime}
								onChange={handleChange}
								renderInput={(params) => (
									<TextField required fullWidth {...params} />
								)}
							/>
						</Grid>
						<Grid item md={12} xs={12}>
							<FormHelperText>PICKUP LOCATION</FormHelperText>
							<TextField
								fullWidth
								id='filled-search'
								placeholder='ENTER A LOCATION'
								type='standard'
								value={picupLocation4}
								onChange={(e) => {
									setPicupLocation4(e.target.value);
									setNewChange(true);
								}}
							/>
						</Grid>

						<Grid item md={12} xs={12}>
							<FormControl fullWidth>
								<FormHelperText sx={{ ml: 0 }}>SERVICE TYPE</FormHelperText>
								<Select
									required
									value={type}
									onChange={handleTypeChange}
									displayEmpty
									inputProps={{ "aria-label": "Without label" }}>
									<MenuItem value=''>
										<em>Select Type</em>
									</MenuItem>
									<MenuItem value={"Corporate"}>Corporate</MenuItem>
									<MenuItem value={"Driver by Day"}>Driver by Day</MenuItem>
									<MenuItem value={"Wedding"}>Wedding</MenuItem>
									<MenuItem value={"Funeral"}>Funeral</MenuItem>
									<MenuItem value={"Cruise"}>Cruise</MenuItem>
									<MenuItem value={"Prom"}>Prom</MenuItem>
								</Select>
							</FormControl>
						</Grid>
						<Grid item md={12} xs={12}>
							<FormControl fullWidth>
								<FormHelperText sx={{ ml: 0 }}>
									DURATION (IN HOURS)
								</FormHelperText>
								<Select
									required
									value={duration}
									onChange={handleExtraHourChange}
									displayEmpty
									inputProps={{ "aria-label": "Without label" }}>
									<MenuItem value=''>
										<em>0 HOUR(S)</em>
									</MenuItem>
									<MenuItem value={"1 HOUR"}>1 HOUR(S)</MenuItem>
									<MenuItem value={"2 HOUR"}>2 HOUR(S)</MenuItem>
									<MenuItem value={"3 HOUR"}>3 HOUR(S)</MenuItem>
									<MenuItem value={"4 HOUR"}>4 HOUR(S)</MenuItem>
									<MenuItem value={"5 HOUR"}>5 HOUR(S)</MenuItem>
								</Select>
							</FormControl>
						</Grid>
						<Grid item md={6} xs={12}>
							<Button
								type='submit'
								className='buttonColor'
								sx={{
									width: "100%",
									py: 1.7,
									display: !newChange && "none !important",
									backgroundColor: "red !important",
								}}
								variant='contained'>
								Next
							</Button>
						</Grid>
						<Grid item md={6} xs={12}>
							<Button
								onClick={() => {
									setRides([
										...rides,
										{
											rideId: Math.round(Math.random() * 10000),
										},
									]);
								}}
								className='buttonColor'
								variant='contained'
								sx={{
									width: "100%",
									py: 1.5,
									mb: 2,
									display:
										rides?.[rides?.length - 1]?.rideId !== ride?.rideId &&
										"none",
								}}>
								ADD NEW RIDE
							</Button>
						</Grid>
					</Grid>
				</LocalizationProvider>
			</form>
		</Container>
	);
}
