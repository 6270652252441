import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import {
	Grid,
	Container,
	FormHelperText,
	ButtonGroup,
	TextField,
} from "@mui/material";
import { reactLocalStorage } from "reactjs-localstorage";
import { useNavigate } from "react-router-dom";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import Swal from "sweetalert2";
import axios from "axios";
import { useData } from "../../../contex/contex";
import BusinessIcon from "@mui/icons-material/Business";
import PersonIcon from "@mui/icons-material/Person";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import Autocomplete from "@mui/material/Autocomplete";

const CustomerType = () => {
	const { user, token } = useData();
	const [companyList, setCompanyList] = useState([]);
	const [value, setValue] = useState(null);
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(
					`${process.env.REACT_APP_API_PATH}/companylist`,
					{
						headers: {
							Authorization: token,
						},
					},
				);

				setCompanyList(response.data.reverse());
			} catch (error) {
				Swal.fire({
					icon: "error",
					title: "Error",
					text: error.response.data.message,
				});
			}
		};
		token && fetchData();
	}, [token]);
	const [whom, setWhom] = useState("Booking on behalf");
	useEffect(() => {
		setWhom("Booking on behalf");
		const data = { forWhom: "Booking on behalf" };
		reactLocalStorage.setObject("forWhom", data);
	}, []);

	const [who, setWho] = useState("");
	useEffect(() => {
		const data = { forWho: who };
		reactLocalStorage.setObject("forWho", data);
	}, [who]);

	const [company, setCompany] = React.useState("");

	useEffect(() => {
		const data = { company: company };
		reactLocalStorage.setObject("company", data);
	}, [company]);

	const navigate = useNavigate();
	const destination = "/dashboard/contactdetails";

	const [txn, setTxn] = React.useState("");
	useEffect(() => {
		const randomNumber = Math.floor(Math.random() * 90000) + 10000;
		if (who === "A Company") {
			setTxn("COM" + randomNumber);
		} else {
			setTxn("PER" + randomNumber);
		}
	}, [who]);

	const [allUserList, setAllUserList] = useState([]);
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(
					`${process.env.REACT_APP_API_PATH}/users`,
					{
						headers: {
							Authorization: token,
						},
					},
				);

				setAllUserList(response.data.reverse());
			} catch (error) {
				Swal.fire({
					icon: "error",
					title: "Error",
					text: error.response.data.message,
				});
			}
		};
		token && fetchData();
	}, [token]);

	const submit = async () => {
		const forWhom = reactLocalStorage.getObject("forWhom");
		const forWho = reactLocalStorage.getObject("forWho");
		const company = reactLocalStorage.getObject("company");
		const companyAdmin = companyList.find(
			(c) => c.name === company.company,
		)?.companyAdmin;
		const data = {
			...forWhom,
			...forWho,
			...company,
			txn: txn,
			bookedBy:
				whom === "Booking on behalf"
					? company
						? companyAdmin
						: value?.email
					: user?.email,
			bookedHelper: user?.email,
		};

		reactLocalStorage.setObject("client", data);

		navigate(destination);
	};

	return (
		<div>
			<Container sx={{ py: 5, minHeight: "50vh" }}>
				<Grid container spacing={2}>
					<Grid item md={5} sm={7} xs={12} sx={{ mx: "auto" }}>
						<Grid container spacing={2}>
							<Grid item md={12} xs={12}>
								<FormHelperText sx={{ textAlign: "center", fontSize: 23 }}>
									Booking For?
								</FormHelperText>
								<ButtonGroup variant='contained' sx={{ width: "100%" }}>
									{whom === "Booking for myself" ? (
										<Button
											startIcon={<PersonIcon />}
											className='buttonActiveColor'
											sx={{ width: "100%", py: 1.3 }}
											variant='contained'
											onClick={() => {
												setWhom("Booking for myself");
												setWho("");
											}}>
											Booking for myself
										</Button>
									) : (
										<Button
											startIcon={<PersonIcon />}
											className='buttonColor'
											sx={{ width: "100%", py: 1.3 }}
											variant='contained'
											onClick={() => {
												setWhom("Booking for myself");
												setWho("");
											}}>
											Booking for myself
										</Button>
									)}
									{whom === "Booking on behalf" ? (
										<Button
											startIcon={<PersonAddAltIcon />}
											className='buttonActiveColor'
											sx={{ width: "100%", py: 1.3 }}
											variant='contained'
											onClick={() => {
												setWhom("Booking on behalf");
												setWho("");
											}}>
											Booking on behalf
										</Button>
									) : (
										<Button
											startIcon={<PersonAddAltIcon />}
											className='buttonColor'
											sx={{ width: "100%", py: 1.3 }}
											variant='contained'
											onClick={() => {
												setWhom("Booking on behalf");
												setWho("");
											}}>
											Booking on behalf
										</Button>
									)}
								</ButtonGroup>
							</Grid>
							{whom === "Booking on behalf" && (
								<Grid item md={12} xs={12}>
									<FormHelperText sx={{ textAlign: "center", fontSize: 23 }}>
										You Are?
									</FormHelperText>
									<ButtonGroup variant='contained' sx={{ width: "100%" }}>
										{who === "A Person" ? (
											<Button
												startIcon={<PersonIcon />}
												className='buttonActiveColor'
												variant='contained'
												sx={{ width: "100%", py: 1.3 }}
												onClick={() => {
													setWho("A Person");
													setCompany("");
												}}>
												Are you a person?
											</Button>
										) : (
											<Button
												startIcon={<PersonIcon />}
												className='buttonColor'
												variant='contained'
												sx={{ width: "100%", py: 1.3 }}
												onClick={() => {
													setWho("A Person");
													setCompany("");
												}}>
												Are you a person?
											</Button>
										)}
										{who === "A Company" ? (
											<Button
												startIcon={<BusinessIcon />}
												className='buttonActiveColor'
												sx={{ width: "100%", py: 1.3 }}
												variant='contained'
												onClick={() => {
													setWho("A Company");
													setValue("");
												}}>
												Are You a company?
											</Button>
										) : (
											<Button
												startIcon={<BusinessIcon />}
												className='buttonColor'
												sx={{ width: "100%", py: 1.3 }}
												variant='contained'
												onClick={() => {
													setWho("A Company");
													setValue("");
												}}>
												Are You a company?
											</Button>
										)}
									</ButtonGroup>
								</Grid>
							)}
							{who === "A Company" ? (
								<Grid item md={12} xs={12}>
									<FormHelperText sx={{ textAlign: "center", fontSize: 23 }}>
										Select Company
									</FormHelperText>
									<select
										style={{
											width: "100%",
											padding: "10px",
											borderRadius: "5px",
											border: "1px solid #ccc",
										}}
										onChange={(e) => {
											setCompany(e.target.value);
											reactLocalStorage.setObject("company", e.target.value);
										}}>
										<option value=''>Select Company</option>
										{companyList.map((company) => (
											<option key={company._id} value={company.name}>
												{company.name}
											</option>
										))}
									</select>
								</Grid>
							) : who === "A Person" ? (
								<Grid
									item
									md={12}
									xs={12}
									sx={{
										"& .MuiAutocomplete-root": {
											width: "100% !important",
										},
									}}>
									<FormHelperText sx={{ textAlign: "center", fontSize: 23 }}>
										Select Person or Enter Email
									</FormHelperText>
									<Autocomplete
										value={value}
										onChange={(event, newValue) => {
											if (typeof newValue === "string") {
												setValue({
													email: newValue,
												});
											} else if (newValue && newValue.inputValue) {
												// Create a new value from the user input
												setValue({
													email: newValue.inputValue,
												});
											} else {
												setValue(newValue);
											}
										}}
										filterOptions={(options, params) => {
											const filtered = options.filter((option) => {
												const nameMatch = option?.displayName
													?.toLowerCase()
													.includes(params.inputValue.toLowerCase());
												const emailMatch = option?.email
													?.toLowerCase()
													.includes(params.inputValue.toLowerCase());
												return nameMatch || emailMatch;
											});

											const { inputValue } = params;
											// Suggest the creation of a new value
											const isExisting = options.some(
												(option) => inputValue === option.title,
											);
											if (inputValue !== "" && !isExisting) {
												filtered.push({
													displayName: "Add New User Email",
													email: inputValue,
												});
											}

											return filtered;
										}}
										selectOnFocus
										clearOnBlur
										handleHomeEndKeys
										id='free-solo-with-text-demo'
										options={allUserList}
										getOptionLabel={(option) => {
											// Value selected with enter, right from the input
											if (typeof option === "string") {
												return option;
											}
											// Add "xxx" option created dynamically
											if (option.inputValue) {
												return option.inputValue;
											}
											// Regular option
											return `${option.displayName} (${option.email})`;
										}}
										renderOption={(props, option) => (
											<li {...props}>
												{option.displayName || option.label} ({option.email})
											</li>
										)}
										sx={{ width: 300 }}
										freeSolo
										renderInput={(params) => (
											<TextField {...params} label='Select User' />
										)}
									/>
								</Grid>
							) : null}

							<Grid item md={12} xs={12}>
								{whom === "Booking on behalf" ? (
									(company || value) && (
										<Button
											endIcon={<ArrowRightIcon />}
											className='buttonColor'
											variant='contained'
											sx={{
												width: "100%",
												mb: 2,
											}}
											onClick={submit}>
											Continue Booking
										</Button>
									)
								) : whom === "Booking for myself" ? (
									<Button
										endIcon={<ArrowRightIcon />}
										className='buttonColor'
										variant='contained'
										sx={{
											width: "100%",
											mb: 2,
										}}
										onClick={submit}>
										Continue Booking
									</Button>
								) : null}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Container>
		</div>
	);
};

export default CustomerType;
