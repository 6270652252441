import * as React from "react";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { Button, Container, FormHelperText, Grid } from "@mui/material";
import { useForm } from "react-hook-form";

export default function Tab5({ rides, setRides, ride }) {
	const [dateTime, setValue] = React.useState(new Date(ride?.time5) || "");

	const [newChange, setNewChange] = React.useState(false);
	const handleChange = (newValue) => {
		setValue(newValue);
		setNewChange(true);
	};
	const { register, handleSubmit, reset } = useForm({
		defaultValues: {
			picupLocation5: "",
			airport: "",
			airline: "",
			flightNo: "",
			terminal: "",
			flightTime: "",
			flightStatus: "",
			origin: "",
		},
	});
	React.useEffect(() => {
		reset(ride);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		setValue(new Date(ride?.time5 || ""));
	}, [ride]);

	const onSubmit = ({
		picupLocation5,
		airport,
		airline,
		flightNo,
		terminal,
		flightTime,
		flightStatus,
		origin,
	}) => {
		const data = {
			time5: dateTime,
			picupLocation5,
			airport,
			airline,
			flightNo,
			terminal,
			flightTime,
			flightStatus,
			origin,
			rideType: "Airport Service",
			tab: 4,
			rideId: Math.round(Math.random() * 10000),
			paidPrice: 0,
			paid: false,
		};
		setRides(
			rides.map((r) => {
				if (r.rideId === ride.rideId) {
					return data;
				}
				return r;
			}),
		);

		setNewChange(false);
		/* reactLocalStorage.setObject("rideDetails", data);
		navigate(destination); */
	};
	return (
		<Container>
			<form onSubmit={handleSubmit(onSubmit)}>
				<LocalizationProvider dateAdapter={AdapterDateFns}>
					<Grid container spacing={2}>
						<Grid item md={6} xs={12}>
							<FormHelperText>PICKUP DATE</FormHelperText>
							<DesktopDatePicker
								inputFormat='MM/dd/yyyy'
								value={dateTime}
								onChange={handleChange}
								renderInput={(params) => (
									<TextField required fullWidth {...params} />
								)}
							/>
						</Grid>

						<Grid item md={6} xs={12}>
							<FormHelperText>PICKUP TIME</FormHelperText>
							<TimePicker
								ampm={false}
								value={dateTime}
								onChange={handleChange}
								renderInput={(params) => <TextField fullWidth {...params} />}
							/>
						</Grid>
						<Grid item md={6} xs={12}>
							<FormHelperText>PICKUP LOCATION</FormHelperText>
							<TextField
								fullWidth
								id='filled-search'
								placeholder='ENTER A LOCATION'
								type='standard'
								{...register("picupLocation5", { required: true })}
								onChange={() => setNewChange(true)}
							/>
						</Grid>
						<Grid item md={6} xs={12}>
							<FormHelperText>AIRPORT</FormHelperText>
							<TextField
								fullWidth
								id='filled-search'
								placeholder='AIRPORT'
								type='standard'
								{...register("airport", { required: true })}
								onChange={() => setNewChange(true)}
							/>
						</Grid>
						<Grid item md={6} xs={12}>
							<FormHelperText>AIRLINE</FormHelperText>
							<TextField
								fullWidth
								id='filled-search'
								placeholder='AIRLINE'
								type='standard'
								{...register("airline")}
								onChange={() => setNewChange(true)}
							/>
						</Grid>
						<Grid item md={6} xs={12}>
							<FormHelperText>FLIGHT NO</FormHelperText>
							<TextField
								fullWidth
								id='filled-search'
								placeholder='FLIGHT NO'
								type='standard'
								{...register("flightNo")}
								onChange={() => setNewChange(true)}
							/>
						</Grid>
						<Grid item md={6} xs={12}>
							<FormHelperText>TERMINAL</FormHelperText>
							<TextField
								fullWidth
								id='filled-search'
								placeholder='TERMINAL'
								type='standard'
								{...register("terminal")}
								onChange={() => setNewChange(true)}
							/>
						</Grid>
						<Grid item md={6} xs={12}>
							<FormHelperText>FLIGHT TIME</FormHelperText>
							<TextField
								fullWidth
								id='filled-search'
								placeholder='FLIGHT TIME. Ex: 05:20 pm'
								type='standard'
								{...register("flightTime")}
								onChange={() => setNewChange(true)}
							/>
						</Grid>
						<Grid item md={6} xs={12}>
							<FormHelperText>FLIGHT STATUS</FormHelperText>
							<TextField
								fullWidth
								id='filled-search'
								placeholder='FLIGHT STATUS'
								type='standard'
								{...register("flightStatus")}
								onChange={() => setNewChange(true)}
							/>
						</Grid>
						<Grid item md={6} xs={12}>
							<FormHelperText>ORIGIN</FormHelperText>
							<TextField
								fullWidth
								id='filled-search'
								placeholder='ORIGIN'
								type='standard'
								{...register("origin")}
								onChange={() => setNewChange(true)}
							/>
						</Grid>
						<Grid item md={6} xs={12}>
							<Button
								type='submit'
								className='buttonColor'
								sx={{
									width: "100%",
									py: 1.7,
									display: !newChange && "none !important",
									backgroundColor: "red !important",
								}}
								variant='contained'>
								Next
							</Button>
						</Grid>
						<Grid item md={6} xs={12}>
							<Button
								onClick={() => {
									setRides([
										...rides,
										{
											rideId: Math.round(Math.random() * 10000),
										},
									]);
								}}
								className='buttonColor'
								variant='contained'
								sx={{
									width: "100%",
									py: 1.5,
									mb: 2,
									display:
										rides?.[rides?.length - 1]?.rideId !== ride?.rideId &&
										"none",
								}}>
								ADD NEW RIDE
							</Button>
						</Grid>
					</Grid>
				</LocalizationProvider>
			</form>
		</Container>
	);
}
