import React, { useRef } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { Backdrop, Box, CircularProgress } from "@mui/material";
import Swal from "sweetalert2";
import axios from "axios";

const QuotationPreview = ({ show, onClose, data, token, isAdmin }) => {
    const divRef = useRef(null);
    const handleDownloadPdf = () => {
        const divElement = divRef.current;

        const options = {
            scale: 2,
            logging: true,
        };

        html2canvas(divElement, options).then((canvas) => {
            const imgData = canvas.toDataURL("image/png");
            const pdf = new jsPDF("p", "mm", "a4");
            pdf.addImage(
                imgData,
                "PNG",
                0,
                0,
                pdf.internal.pageSize.width,
                pdf.internal.pageSize.height,
            );
            pdf.save(
                `${data?.quoteAccepted || data?.position === "Completed" ? "Invoice" : "Quotation"} - ${data.firstName} ${data.lastName}.pdf`,
            );
        });
    };

    const [submitting, setSubmitting] = React.useState(false);

    if (!show) return null;

    return (
        <Box
            sx={{
                maxWidth: "800px",
                mx: "auto",
            }}>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    p: "10px",
                    borderBottom: "1px solid #ddd",
                }}>
                <button
                    style={{ backgroundColor: "red", color: "white" }}
                    onClick={onClose}>
                    Close Preview
                </button>
                {isAdmin && (
                    <button
                        style={{ backgroundColor: "blue", color: "white" }}
                        onClick={() => {
                            Swal.fire({
                                title: "Are you sure?",
                                text: "Do you want to send email to client?",
                                icon: "warning",
                                showCancelButton: true,
                                confirmButtonText: "Yes",
                                cancelButtonText: "No",
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    setSubmitting(true);
                                    axios
                                        .post(
                                            `${process.env.REACT_APP_API_PATH}/sendPDF/${data?._id}`,
                                        )
                                        .then((res) => {
                                            Swal.fire("Email Sent!", "", "success");
                                            setSubmitting(false);
                                        })
                                        .catch((err) => {
                                            Swal.fire("Failed to send email", "", "error");
                                        });
                                }
                            });
                        }}>
                        Re-Send Email to Client
                    </button>
                )}
                <button
                    style={{ backgroundColor: "green", color: "white" }}
                    onClick={handleDownloadPdf}>
                    Download PDF
                </button>
            </Box>
            <div ref={divRef}>
                <div
                    style={{
                        fontFamily: "Arial, sans-serif",
                        fontSize: "12px",
                        lineHeight: 1.5,
                        color: "#333",
                        margin: 0,
                        padding: 0,
                        textAlign: "left",
                    }}>
                    <div style={{ padding: "20px", margin: "auto", width: "100%" }}>
                        <div
                            style={{
                                overflow: "hidden",
                                marginBottom: "15px",
                                backgroundColor: "#000",
                                padding: "10px",
                                borderRadius: "5px",
                            }}>
                            <img
                                src='/logo.png'
                                alt='Elegance Limousine Services'
                                style={{
                                    width: "100px",
                                    height: "auto",
                                    borderRadius: "15px",
                                    float: "left",
                                }}
                            />
                            <div style={{ float: "right", color: "white" }}>
                                <div style={{ float: "right", textAlign: "right" }}>
                                    <p
                                        style={{
                                            margin: 0,
                                            color: "white",
                                            fontSize: "17px",
                                            fontWeight: "bold",
                                        }}>
                                        Elegance Limousine Services
                                    </p>
                                    <p style={{ margin: 0, color: "white" }}>
                                        515 Riverside Dr W, Windsor, ON N9A 5K3
                                    </p>
                                    <p style={{ margin: 0, color: "white" }}>Tel: 519-969-4444</p>
                                    <p style={{ margin: 0, color: "white" }}>
                                        Email:{" "}
                                        <a
                                            href='mailto:info@elegancelimo.ca'
                                            style={{ color: "white" }}>
                                            info@elegancelimo.ca
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div style={{ overflow: "hidden", marginBottom: "11px" }}>
                            <div
                                style={{
                                    textAlign: "center",
                                    fontSize: "18px",
                                    fontWeight: "bold",
                                    textDecoration: "underline",
                                }}>
                                {data?.quoteAccepted || data?.position === "Completed"
                                    ? "Invoice"
                                    : "Quotation"}{" "}
                                Details
                            </div>
                        </div>
                        <div
                            style={{
                                overflow: "hidden",
                                border: "1px solid #ddd",
                                padding: "10px",
                                marginBottom: "20px",
                            }}>
                            <div style={{ width: "48%", float: "left", marginRight: "4%" }}>
                                <div style={{ marginBottom: "10px" }}>
                                    <p style={{ margin: 0 }}>
                                        <strong>Client ID:</strong> {data._id}
                                    </p>
                                    <p style={{ margin: 0 }}>
                                        <strong>Client:</strong> {data.firstName} {data.lastName}
                                    </p>
                                    <p style={{ margin: 0 }}>
                                        <strong>Email:</strong> {data.bookedBy || data?.userEmail}
                                    </p>
                                    <p style={{ margin: 0 }}>
                                        <strong>Company:</strong> {data.reqCompany}
                                    </p>
                                </div>
                            </div>
                            <div style={{ width: "48%", float: "left" }}>
                                <div style={{ marginBottom: "10px" }}>
                                    <p style={{ margin: 0 }}>
                                        <strong>Quotation ID:</strong> {data.txn}
                                    </p>
                                    <p style={{ margin: 0 }}>
                                        <strong>HST:</strong> {data.hst?.split("HST")?.[1]}
                                    </p>
                                    <p style={{ margin: 0 }}>
                                        <strong>Passengers:</strong> {data.passengersCount}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div
                            style={{
                                overflow: "hidden",
                                border: "1px solid #ddd",
                                padding: "10px",
                                margin: "20px 0",
                            }}>
                            <p style={{ margin: 0 }}>
                                <strong>Rides:</strong>
                            </p>
                            {data.rides.map((ride, index) => (
                                <div
                                    key={index}
                                    style={{
                                        overflow: "hidden",
                                        border: "1px solid #ddd",
                                        padding: "10px",
                                        marginBottom: "10px",
                                    }}>
                                    <div
                                        style={{ width: "48%", float: "left", marginRight: "4%" }}>
                                        <p style={{ margin: 0 }}>
                                            <strong>Ride Type:</strong> {ride?.rideType}
                                        </p>
                                        <p style={{ margin: 0 }}>
                                            <strong>Pick-up Location:</strong>{" "}
                                            {ride.picupLocation1 ||
                                                ride.picupLocation2 ||
                                                ride.picupLocation3 ||
                                                ride.picupLocation4 ||
                                                ride.picupLocation5 ||
                                                "N/A"}
                                        </p>
                                        <p style={{ margin: 0 }}>
                                            <strong>Pick-up Date:</strong>{" "}
                                            {ride.time1
                                                ? new Date(ride.time1).toLocaleDateString()
                                                : "N/A"}
                                        </p>
                                        <p style={{ margin: 0 }}>
                                            <strong>Pick-up Time:</strong>{" "}
                                            {ride.time1
                                                ? new Date(ride.time1).toLocaleTimeString()
                                                : "N/A"}
                                        </p>
                                        <p style={{ margin: 0 }}>
                                            <strong>Vehicle to Stay:</strong> No
                                        </p>
                                    </div>
                                    <div style={{ width: "48%", float: "left" }}>
                                        <p style={{ margin: 0 }}>
                                            <strong>Destination:</strong>{" "}
                                            {ride.dropOffLocation1 || ride.dropOffLocation2 || "N/A"}
                                        </p>
                                        <p style={{ margin: 0 }}>
                                            <strong>Arrival Date:</strong>{" "}
                                            {ride.time1
                                                ? new Date(ride.time1).toLocaleDateString()
                                                : "N/A"}
                                        </p>
                                        <p style={{ margin: 0 }}>
                                            <strong>Leave Date:</strong>{" "}
                                            {ride.time1
                                                ? new Date(ride.time1).toLocaleDateString()
                                                : "N/A"}
                                        </p>
                                        <p style={{ margin: 0 }}>
                                            <strong>Back Date:</strong>{" "}
                                            {ride.time1
                                                ? new Date(ride.time1).toLocaleDateString()
                                                : "N/A"}
                                        </p>
                                        <p style={{ margin: 0 }}>
                                            <strong>Time:</strong> 18:00
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div
                            style={{
                                overflow: "hidden",
                                border: "1px solid #ddd",
                                padding: "10px",
                                margin: "20px 0",
                            }}>
                            <table
                                style={{
                                    width: "100%",
                                    borderCollapse: "collapse",
                                    textAlign: "center",
                                }}>
                                <thead>
                                    <tr style={{ backgroundColor: "#ddd" }}>
                                        <th style={{ padding: "4px", border: "1px solid #ddd" }}>
                                            No
                                        </th>
                                        <th style={{ padding: "4px", border: "1px solid #ddd" }}>
                                            Seats
                                        </th>
                                        <th style={{ padding: "4px", border: "1px solid #ddd" }}>
                                            Vehicle
                                        </th>

                                        <th style={{ padding: "4px", border: "1px solid #ddd" }}>
                                            Total
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        style={{
                                            borderBottom: "1px solid #ddd",
                                            textAlign: "center",
                                        }}>
                                        <td style={{ padding: "4px", border: "1px solid #ddd" }}>
                                            1
                                        </td>
                                        <td style={{ padding: "4px", border: "1px solid #ddd" }}>
                                            {data.carPassenger}
                                        </td>
                                        <td style={{ padding: "4px", border: "1px solid #ddd" }}>
                                            {data.carName}
                                        </td>

                                        <td style={{ padding: "4px", border: "1px solid #ddd" }}>
                                            {data.quotedPrice || data?.priceByAdmin}$
                                        </td>
                                    </tr>
                                    <tr
                                        style={{
                                            textAlign: "center",
                                            borderTop: "5px solid #ddd",
                                        }}>
                                        <td style={{ padding: "4px" }}></td>
                                        <td style={{ padding: "4px" }}></td>
                                        <td style={{ padding: "4px" }}>Tax</td>

                                        <td style={{ padding: "4px" }}>{data.tax}%</td>
                                    </tr>
                                    <tr
                                        style={{
                                            textAlign: "center",
                                        }}>
                                        <td style={{ padding: "4px" }}></td>
                                        <td style={{ padding: "4px" }}></td>
                                        <td style={{ padding: "4px" }}>Tips</td>

                                        <td style={{ padding: "4px" }}>{data.tips}$</td>
                                    </tr>
                                    <tr
                                        style={{
                                            borderTop: "1px solid #ddd",
                                            textAlign: "center",
                                        }}>
                                        <td style={{ padding: "4px" }}></td>
                                        <td style={{ padding: "4px" }}></td>
                                        <td style={{ padding: "4px" }}>Total</td>

                                        <td style={{ padding: "4px" }}>
                                            {(
                                                (Number(data.priceByAdmin) ||
                                                    Number(data.quotedPrice) ||
                                                    0) *
                                                (1 + Number(data?.tax || 0) / 100) +
                                                Number(data?.tips || 0)
                                            ).toFixed(2)}
                                            $
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div
                            style={{
                                overflow: "hidden",
                                border: "1px solid #ddd",
                                padding: "10px",
                                marginBottom: "20px",
                            }}>
                            <p style={{ margin: 0 }}>
                                <strong>Vehicle Description:</strong>
                            </p>
                            <p style={{ margin: 0 }}>
                                {data.carInfo.map((info) => info.feature).join(", ")}
                            </p>
                        </div>
                        <div
                            style={{
                                overflow: "hidden",
                                border: "1px solid #ddd",
                                padding: "10px",
                                marginBottom: "20px",
                            }}>
                            <p style={{ margin: 0 }}>
                                <strong>Notes:</strong>
                            </p>
                            <textarea
                                disabled={!isAdmin}
                                defaultValue={data.notes}
                                style={{
                                    width: "100%",
                                    height: "100px",
                                    border: "none",
                                    backgroundColor: "transparent",
                                    resize: "none",
                                    fontSize: "12px",
                                    fontFamily: "inherit",
                                }}
                                onBlur={(e) => {
                                    axios
                                        .patch(
                                            `${process.env.REACT_APP_API_PATH}/bookings/${data._id}`,
                                            { notes: e.target.value },
                                            {
                                                headers: {
                                                    Authorization: token,
                                                },
                                            },
                                        )
                                        .then((res) => {
                                            Swal.fire("Notes Updated!", "", "success");
                                        })
                                        .catch((err) => {
                                            Swal.fire("Failed to update notes", "", "error");
                                        });
                                }}></textarea>
                            {/*  <p style={{ margin: 0 }}>{data.comment}</p> */}
                        </div>
                        <div
                            style={{
                                overflow: "hidden",
                                border: "1px solid #ddd",
                                padding: "10px",
                                marginBottom: "20px",
                            }}>
                            <p style={{ margin: 0 }}>
                                <strong>Further Requirements:</strong> All vehicle pictures can
                                be found at:{" "}
                                <a
                                    href='https://elegancelimo.ca'
                                    target='_blank'
                                    rel='noreferrer'>
                                    https://elegancelimo.ca
                                </a>
                            </p>
                            <p style={{ margin: 0 }}>
                                It is customary to tip your limo/bus driver between 15% to 20%
                                based on your experience. Gratuities are not included in the
                                price of your charter.
                            </p>
                        </div>
                        <div
                            style={{
                                overflow: "hidden",
                                marginBottom: "20px",
                                textAlign: "center",
                            }}>
                            <h3 style={{ margin: 0 }}>
                                Thank you for choosing Elegance Limousine Services. <br />
                                We look forward to serving you.
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={submitting}>
                <CircularProgress color='inherit' />
            </Backdrop>
        </Box>
    );
};

export default QuotationPreview;
