import { Badge, Button, Divider, Grid } from "@mui/material";
import React from "react";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import GroupIcon from "@mui/icons-material/Group";
import LuggageIcon from "@mui/icons-material/Luggage";
import CheckIcon from "@mui/icons-material/Check";
import BeatLoader from "react-spinners/BeatLoader";
import GetData from "../../../../GetData";
import axios from "axios";
import Swal from "sweetalert2";
import SwiperCore, { Autoplay, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
SwiperCore.use([Autoplay, Pagination]);

const Vehicles = ({ data, id, token, handleClose }) => {
	const { allCars } = GetData();

	const [selectedId, setSelectedId] = React.useState(
		data?.carId || allCars?.[0]?.id,
	);

	React.useEffect(() => {
		setSelectedId(data?.carId || allCars?.[0]?.id);
	}, [allCars, data]);

	const setCar = (car) => {
		setSelectedId(car?.id);
		const data = {
			carId: car?.id,
			carName: car?.name,
			carInfo: car?.features,
			carPhoto1: car?.images?.[0]?.url,
			carPhoto2: car?.images?.[1]?.url,
			carPhoto3: car?.images?.[2]?.url,
			carPassenger: car?.carPassenger,
			carLuggage: car?.carLuggage,
		};
		axios
			.patch(
				`${process.env.REACT_APP_API_PATH}/bookings/${id}`,
				{
					...data,
				},
				{
					headers: {
						Authorization: token,
					},
				},
			)
			.then((res) => {
				Swal.fire({
					icon: "success",
					title: "Success",
					text: res.data.message,
				});
				handleClose();
			})
			.catch((err) => {
				Swal.fire({
					icon: "error",
					title: "Error",
					text: err.response.data.message,
				});
			});
	};

	return (
		<div>
			{allCars?.map((car, key) => (
				<Box key={key}>
					<Box
						sx={{
							textAlign: "left",
						}}>
						<Grid container spacing={2} sx={{ alignItems: "center" }}>
							<Grid item md={4} xs={12}>
								<Grid
									container
									spacing={2}
									sx={{
										alignItems: "center",
										"& .swiper-wrapper": {
											maxWidth: "350px !important",
											width: "100% !important",
										},
									}}>
									<Swiper
										grabCursor={true}
										loop={true}
										autoplay={{ delay: 3500 }}
										centeredSlides={true}
										slidesPerView={1}
										spaceBetween={30}
										pagination={{
											clickable: true,
										}}
										className='mySwiper'>
										{car?.images?.map((a, key) => (
											<SwiperSlide key={key}>
												<CardMedia
													sx={{ width: "100%" }}
													component='img'
													alt=''
													image={a?.url}
												/>
											</SwiperSlide>
										))}
									</Swiper>
								</Grid>
							</Grid>
							<Grid
								item
								md={5}
								xs={12}
								display='flex'
								sx={{
									flexDirection: "row",
									textAlign: "left",
									alignItems: "center",
								}}>
								<CardContent>
									<Typography variant='h6'>{car?.name}</Typography>
									{/* <ul>
										<li>
											<Typography variant='body2'>
												{car?.features?.[0]?.feature}
											</Typography>
										</li>
										<li>
											<Typography variant='body2'>
												{car?.features?.[1]?.feature}
											</Typography>
										</li>
										<li>
											<Typography variant='body2'>
												{car?.features?.[2]?.feature}
											</Typography>
										</li>
									</ul> */}
								</CardContent>
							</Grid>
							<Grid item md={3} xs={12}>
								<Grid container spacing={2} sx={{ mx: "auto" }}>
									<Grid item md={12} xs={6}>
										<Box sx={{ display: "flex" }}>
											<Badge
												badgeContent={car?.carPassenger}
												color='primary'
												sx={{ mx: 1 }}>
												<GroupIcon sx={{ fontSize: 55 }} />
											</Badge>
											<Badge
												badgeContent={car?.carLuggage}
												color='primary'
												sx={{ mx: 1 }}>
												<LuggageIcon sx={{ fontSize: 55 }} />
											</Badge>
										</Box>
									</Grid>
									<Grid item md={12} xs={6} sx={{ m: "auto" }}>
										<Box sx={{ mx: 2 }}>
											{selectedId === car?.id ? (
												<Button
													sx={{ px: 3, py: 1.5 }}
													variant='contained'
													className='activeButtonColor'
													onClick={() => setCar(car)}>
													<CheckIcon sx={{ mr: 0.7 }} /> Select
												</Button>
											) : (
												<Button
													style={{ backgroundColor: "transparent" }}
													sx={{
														px: 3,
														py: 1.5,
														bgcolor: "#EAECEE",
														color: "black",
														fontWeight: "bold",
														border: "1px solid",
													}}
													variant='contained'
													onClick={() => setCar(car)}>
													Select
												</Button>
											)}
										</Box>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Box>
					<Divider sx={{ my: 1.5 }} />
				</Box>
			))}
			<Box>{!allCars && <BeatLoader size={10} />}</Box>
		</div>
	);
};

export default Vehicles;
