import React, { useState, useEffect } from "react";
import { reactLocalStorage } from "reactjs-localstorage";

const ContactForm = ({ setStep }) => {
	const [formData, setFormData] = useState({
		firstName: "",
		lastName: "",
		userEmail: "",
		phone: "",
		comment: "",
		bookingType: "",
	});

	// Load default values from local storage when the component mounts
	useEffect(() => {
		const storedData = reactLocalStorage.getObject("contactDetails");
		if (storedData && Object.keys(storedData).length > 0) {
			setFormData(storedData);
		}
	}, []);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData((prevData) => {
			const newData = { ...prevData, [name]: value };
			reactLocalStorage.setObject("contactDetails", newData); // Update local storage
			return newData;
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		// Here, you could add any additional validation if necessary
		setStep("booking");
		localStorage.setItem("quickBookingStep", "booking");
	};

	return (
		<div
			style={{
				padding: "10px",
				fontFamily: "Arial, sans-serif",
				maxWidth: "400px",
				margin: "auto",
				color: "black",
			}}>
			<h2 style={{ textAlign: "center" }}>Booking For?</h2>
			<form onSubmit={handleSubmit}>
				<div style={{ marginBottom: "15px" }}>
					<input
						type='text'
						name='firstName'
						placeholder='Enter client First Name*'
						required
						value={formData.firstName}
						onChange={handleChange}
						style={{
							padding: "10px",
							width: "100%",
							borderRadius: "4px",
							border: "1px solid #ccc",
						}}
					/>
				</div>
				<div style={{ marginBottom: "15px" }}>
					<input
						type='text'
						name='lastName'
						placeholder='Enter client Last Name*'
						required
						value={formData.lastName}
						onChange={handleChange}
						style={{
							padding: "10px",
							width: "100%",
							borderRadius: "4px",
							border: "1px solid #ccc",
						}}
					/>
				</div>
				<div style={{ marginBottom: "15px" }}>
					<input
						type='email'
						name='userEmail'
						placeholder='Enter client Email*'
						required
						value={formData.userEmail}
						onChange={handleChange}
						style={{
							padding: "10px",
							width: "100%",
							borderRadius: "4px",
							border: "1px solid #ccc",
						}}
					/>
				</div>
				<div style={{ marginBottom: "15px" }}>
					<input
						type='text'
						name='phone'
						placeholder='Client Phone Number*'
						required
						value={formData.phone}
						onChange={handleChange}
						style={{
							padding: "10px",
							width: "100%",
							borderRadius: "4px",
							border: "1px solid #ccc",
						}}
					/>
				</div>
				<div style={{ marginBottom: "15px" }}>
					<select
						name='bookingType'
						value={formData.bookingType}
						onChange={handleChange}
						style={{
							padding: "10px",
							width: "100%",
							borderRadius: "4px",
							border: "1px solid #ccc",
						}}>
						<option value=''>Preferred Booking Type*</option>
						<option value='quatation'>Quatation</option>
						<option value='invoice'>Invoice</option>
					</select>
				</div>
				<div style={{ marginBottom: "15px" }}>
					<textarea
						name='comment'
						placeholder='Enter any additional comments'
						rows='7'
						value={formData.comment}
						onChange={handleChange}
						style={{
							padding: "10px",
							width: "100%",
							borderRadius: "4px",
							border: "1px solid #ccc",
						}}
					/>
				</div>
				<div
					style={{
						marginTop: "10px",
						position: "fixed",
						bottom: 0,
						left: "50%",
						transform: "translateX(-50%)",
						width: "100%",
						padding: "10px",
						backgroundColor: "#80808078",
						borderTop: "3px solid #80808078",
					}}>
					<div
						style={{
							maxWidth: "900px",
							margin: "0 auto",
							display: "flex",
							justifyContent: "space-between",
						}}>
						<button
							type='button'
							onClick={() => {
								setStep("person");
								localStorage.setItem("quickBookingStep", "person");
							}}
							style={{
								padding: "10px",
								backgroundColor: "black",
								color: "white",
								border: "none",
								borderRadius: "4px",
								cursor: "pointer",
							}}>
							&#8592; Back
						</button>
						<button
							type='button'
							style={{
								padding: "10px",
								backgroundColor: "black",
								color: "white",
								border: "none",
								borderRadius: "4px",
								cursor: "pointer",
							}}
							onClick={() => {
								reactLocalStorage.clear("vehicles");
								reactLocalStorage.clear("rideDetails");
								reactLocalStorage.clear("contactDetails");
								reactLocalStorage.clear("importantOpions");
								reactLocalStorage.clear("client");
								reactLocalStorage.clear("quickBookingStep");
								window.location.href = "/dashboard/QuickBooking";
							}}>
							Reset  &#x21bb;
						</button>
						<button
							type='submit'
							style={{
								padding: "10px",
								backgroundColor: "black",
								color: "white",
								border: "none",
								borderRadius: "4px",
								cursor: "pointer",
							}}>
							RIDE DETAILS &#8594;
						</button>
					</div>
				</div>
			</form>
		</div>
	);
};

export default ContactForm;
