import {
	Backdrop,
	Box,
	Button,
	CircularProgress,
	Container,
	Divider,
	Grid,
	Modal,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TextField,
	Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { Link } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import { CSVLink } from "react-csv";
import PersonIcon from "@mui/icons-material/Person";
import BusinessIcon from "@mui/icons-material/Business";
import AirplanemodeActiveIcon from "@mui/icons-material/AirplanemodeActive";
import { useData } from "../../../contex/contex";

const BookingsDriverView = () => {
	const [bookings, setBookings] = useState([]);

	/* 	const [done, setDone] = useState(false); */
	const { token, user } = useData();

	const [seletedBooking, setSelectedBooking] = useState({});

	const [open, setOpen] = React.useState(false);
	const handleOpen = (booking) => {
		setOpen(true);
		setSelectedBooking(booking);
	};
	const handleClose = () => {
		setOpen(false);
		setSelectedBooking({});
	};

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(
					`${process.env.REACT_APP_API_PATH}/filterbookings/${user?.userRole}/${user?.email}`,
					{
						headers: {
							Authorization: token,
						},
					},
				);

				setBookings(response.data);
				setNewList(response.data.reverse());
			} catch (error) {
				Swal.fire({
					icon: "error",
					title: "Error",
					text: error.response.data.message,
				});
			}
		};
		token && fetchData();
	}, [/* done, */ token, user, open]);

	const [search, setSearch] = React.useState("");
	const [newList, setNewList] = React.useState([]);
	const handleChange = (event) => {
		setSearch(event.target.value);
	};
	useEffect(() => {
		const searchs = bookings.filter(
			(booking) =>
				booking?.firstName?.toLowerCase().includes(search.toLowerCase()) ||
				booking?.lastName?.toLowerCase().includes(search.toLowerCase()) ||
				booking?.txn?.toLowerCase().includes(search.toLowerCase()) ||
				booking?.phone?.toLowerCase().includes(search.toLowerCase()) ||
				booking?.company?.toLowerCase().includes(search.toLowerCase()) ||
				booking?.rideType?.toLowerCase().includes(search.toLowerCase()),
		);
		setNewList(searchs);
	}, [bookings, search]);

	/* 	const handleDone = async (id) => {
		Swal.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, Mark as Completed!",
		}).then(async (result) => {
			if (result.isConfirmed) {
				try {
					const response = await axios.put(
						`${process.env.REACT_APP_API_PATH}/bookingsCompleted/${id}`,
						{
							position: "Completed",
						},
						{
							headers: {
								Authorization: token,
							},
						},
					);

					if (response.status) {
						Swal.fire({
							icon: "success",
							title: "That booking has been marked as Completed.",
							showConfirmButton: false,
							timer: 1500,
						});
						setDone(true);
					}
				} catch (error) {
					Swal.fire({
						icon: "error",
						title: "Error",
						text: error.response.data.message,
					});
				}
			}
		});
	}; */

	let count = 1;
	const headers = [
		{ label: "You Are ?", key: "forWho" },
		{ label: "Company", key: "company" },
		{ label: "First Name", key: "firstName" },
		{ label: "Last Name", key: "lastName" },
		{ label: "Email", key: "userEmail" },
		{ label: "Phone Number", key: "phone" },
		{ label: "Notes", key: "comment" },
		{ label: "Booking Time", key: "bookingTime" },
		{ label: "TXN", key: "txn" },
		{ label: "Booked By", key: "bookedBy" },
		{
			label: "Quoted?",
			key: "quotedPrice",
		},
		{
			label: "Price By Admin",
			key: "priceByAdmin",
		},
	];

	return (
		<Container sx={{ my: 2, minHeight: "100vh" }}>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'>
				<Box
					sx={{
						position: "absolute",
						top: "50%",
						left: "50%",
						transform: "translate(-50%, -50%)",
						minWidth: 400,
						width: "100%",
						bgcolor: "background.paper",
						border: "2px solid #000",
						boxShadow: 24,
						p: 4,
					}}>
					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							flexDirection: "column",
							gap: "10px",
						}}>
						<Typography
							sx={{
								textAlign: "center",
							}}
							id='modal-modal-title'
							variant='h6'
							component='h2'>
							Driver Assign Info
						</Typography>
					</Box>
					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							gap: "10px",
							overflowX: "auto",
						}}>
						{seletedBooking?.rides?.map((rideDetails) => (
							<Box
								sx={{
									border: "1px solid #A72C79",
									p: 2,
									my: 2,
									minWidth: "300px",
								}}>
								<Typography gutterBottom variant='body' component='div'>
									Assigned To
								</Typography>
								<Typography gutterBottom variant='body2' component='div'>
									{rideDetails?.assignedTo?.email || "N/A"}
								</Typography>
								<Divider sx={{ my: 1.5 }} />
								{rideDetails?.rideType === "Hourly" && (
									<>
										<Typography gutterBottom variant='body' component='div'>
											RIDE TYPE
										</Typography>
										<Typography gutterBottom variant='body2' component='div'>
											{rideDetails?.rideType}
										</Typography>
										<Divider sx={{ my: 1.5 }} />
										<Typography gutterBottom variant='body' component='div'>
											PICKUP LOCATION
										</Typography>
										<Typography gutterBottom variant='body2' component='div'>
											{rideDetails?.picupLocation2}
										</Typography>
										<Divider sx={{ my: 1.5 }} />
										<Typography gutterBottom variant='body' component='div'>
											DROP-OFF LOCATION
										</Typography>
										<Typography gutterBottom variant='body2' component='div'>
											{rideDetails?.dropOffLocation2}
										</Typography>
										<Divider sx={{ my: 1.5 }} />
										<Typography gutterBottom variant='body' component='div'>
											DURATION
										</Typography>
										<Typography gutterBottom variant='body2' component='div'>
											{rideDetails?.duration2}
										</Typography>
										<Divider sx={{ my: 1.5 }} />
										<Typography gutterBottom variant='body' component='div'>
											PICKUP DATE, TIME
										</Typography>
										<Typography gutterBottom variant='body2' component='div'>
											{new Date(rideDetails?.time2).toLocaleString("en-GB")}
										</Typography>
										<Divider sx={{ my: 1.5 }} />
									</>
								)}
								{rideDetails?.rideType === "Distance" && (
									<>
										<Typography gutterBottom variant='body' component='div'>
											RIDE TYPE
										</Typography>
										<Typography gutterBottom variant='body2' component='div'>
											{rideDetails?.rideType}
										</Typography>
										<Divider sx={{ my: 1.5 }} />
										<Typography gutterBottom variant='body' component='div'>
											PICKUP LOCATION
										</Typography>
										<Typography gutterBottom variant='body2' component='div'>
											{rideDetails?.picupLocation1}
										</Typography>
										<Divider sx={{ my: 1.5 }} />
										<Typography gutterBottom variant='body' component='div'>
											DROP-OFF LOCATION
										</Typography>
										<Typography gutterBottom variant='body2' component='div'>
											{rideDetails?.dropOffLocation1}
										</Typography>
										<Divider sx={{ my: 1.5 }} />
										<Typography gutterBottom variant='body' component='div'>
											EXTRA TIME
										</Typography>
										<Typography gutterBottom variant='body2' component='div'>
											{rideDetails?.extraTime1}
										</Typography>
										<Divider sx={{ my: 1.5 }} />
										<Typography gutterBottom variant='body' component='div'>
											PICKUP DATE, TIME
										</Typography>
										<Typography gutterBottom variant='body2' component='div'>
											{new Date(rideDetails?.time1).toLocaleString("en-GB")}
										</Typography>
										<Divider sx={{ my: 1.5 }} />
									</>
								)}
								{rideDetails?.rideType === "Flat Rate" && (
									<>
										<Typography gutterBottom variant='body' component='div'>
											RIDE TYPE
										</Typography>
										<Typography gutterBottom variant='body2' component='div'>
											{rideDetails?.rideType}
										</Typography>
										<Divider sx={{ my: 1.5 }} />

										<Typography gutterBottom variant='body' component='div'>
											SERVICE TYPE
										</Typography>
										<Typography gutterBottom variant='body2' component='div'>
											{rideDetails?.transferType3}
										</Typography>
										<Typography gutterBottom variant='body' component='div'>
											PICKUP LOCATION
										</Typography>
										<Typography gutterBottom variant='body2' component='div'>
											{rideDetails?.picupLocation3}
										</Typography>
										<Divider sx={{ my: 1.5 }} />
										<Typography gutterBottom variant='body' component='div'>
											DURATION
										</Typography>
										<Typography gutterBottom variant='body2' component='div'>
											{rideDetails?.duration3}
										</Typography>
										<Divider sx={{ my: 1.5 }} />
										<Typography gutterBottom variant='body' component='div'>
											PICKUP DATE, TIME
										</Typography>
										<Typography gutterBottom variant='body2' component='div'>
											{new Date(rideDetails?.time3).toLocaleString("en-GB")}
										</Typography>
										<Divider sx={{ my: 1.5 }} />
									</>
								)}
								{rideDetails?.rideType === "Chauffeur Services" && (
									<>
										<Typography gutterBottom variant='body' component='div'>
											RIDE TYPE
										</Typography>
										<Typography gutterBottom variant='body2' component='div'>
											{rideDetails?.rideType}
										</Typography>
										<Divider sx={{ my: 1.5 }} />

										<Typography gutterBottom variant='body' component='div'>
											SERVICE TYPE
										</Typography>
										<Typography gutterBottom variant='body2' component='div'>
											{rideDetails?.transferType4}
										</Typography>
										<Typography gutterBottom variant='body' component='div'>
											PICKUP LOCATION
										</Typography>
										<Typography gutterBottom variant='body2' component='div'>
											{rideDetails?.picupLocation4}
										</Typography>
										<Divider sx={{ my: 1.5 }} />
										<Typography gutterBottom variant='body' component='div'>
											DURATION
										</Typography>
										<Typography gutterBottom variant='body2' component='div'>
											{rideDetails?.duration4}
										</Typography>
										<Divider sx={{ my: 1.5 }} />
										<Typography gutterBottom variant='body' component='div'>
											PICKUP DATE, TIME
										</Typography>
										<Typography gutterBottom variant='body2' component='div'>
											{new Date(rideDetails?.time4).toLocaleString("en-GB")}
										</Typography>
										<Divider sx={{ my: 1.5 }} />
									</>
								)}
								{rideDetails?.rideType === "Airport Service" && (
									<>
										<Typography gutterBottom variant='body' component='div'>
											RIDE TYPE
										</Typography>
										<Typography gutterBottom variant='body2' component='div'>
											{rideDetails?.rideType}
										</Typography>
										<Divider sx={{ my: 1.5 }} />
										<Typography gutterBottom variant='body' component='div'>
											PICKUP DATE, TIME
										</Typography>
										<Typography gutterBottom variant='body2' component='div'>
											{new Date(rideDetails?.time5).toLocaleString("en-GB")}
										</Typography>
										<Divider sx={{ my: 1.5 }} />
										<Typography gutterBottom variant='body' component='div'>
											PICKUP LOCATION
										</Typography>
										<Typography gutterBottom variant='body2' component='div'>
											{rideDetails?.picupLocation5}
										</Typography>
										<Divider sx={{ my: 1.5 }} />
										<Typography gutterBottom variant='body' component='div'>
											AIRPORT
										</Typography>
										<Typography gutterBottom variant='body2' component='div'>
											{rideDetails?.airport}
										</Typography>
										<Divider sx={{ my: 1.5 }} />
										<Typography gutterBottom variant='body' component='div'>
											AIRLINE
										</Typography>
										<Typography gutterBottom variant='body2' component='div'>
											{rideDetails?.airline || "N/A"}
										</Typography>
										<Divider sx={{ my: 1.5 }} />
										<Typography gutterBottom variant='body' component='div'>
											FLIGHT NO
										</Typography>
										<Typography gutterBottom variant='body2' component='div'>
											{rideDetails?.flightNo || "N/A"}
										</Typography>
										<Divider sx={{ my: 1.5 }} />
										<Typography gutterBottom variant='body' component='div'>
											TERMINAL
										</Typography>
										<Typography gutterBottom variant='body2' component='div'>
											{rideDetails?.terminal || "N/A"}
										</Typography>
										<Divider sx={{ my: 1.5 }} />
										<Typography gutterBottom variant='body' component='div'>
											FLIGHT TIME
										</Typography>
										<Typography gutterBottom variant='body2' component='div'>
											{rideDetails?.flightTime || "N/A"}
										</Typography>
										<Divider sx={{ my: 1.5 }} />
										<Typography gutterBottom variant='body' component='div'>
											FLIGHT STATUS
										</Typography>
										<Typography gutterBottom variant='body2' component='div'>
											{rideDetails?.flightStatus || "N/A"}
										</Typography>
										<Divider sx={{ my: 1.5 }} />
										<Typography gutterBottom variant='body' component='div'>
											ORIGIN
										</Typography>
										<Typography gutterBottom variant='body2' component='div'>
											{rideDetails?.origin || "N/A"}
										</Typography>
										<Divider sx={{ my: 1.5 }} />
									</>
								)}
							</Box>
						))}
					</Box>
				</Box>
			</Modal>
			<Grid>
				<Typography
					sx={{ fontWeight: "bold" }}
					variant='h4'
					component='div'
					gutterBottom>
					Booking Requests
				</Typography>
				<Box>
					<Button
						className='buttonColor'
						sx={{
							fontWeight: "bold",
							border: "2px solid",
							backgroundColor: "transparent",
							borderRadius: "25px",
							mb: 1,
							display: "none",
						}}
						variant='contained'>
						<CSVLink
							filename={"bookings.csv"}
							data={bookings}
							headers={headers}
							className='buttonColor'
							style={{
								textDecoration: "none",
								color: "white",
							}}>
							Download CSV File
						</CSVLink>
					</Button>
				</Box>
				<Grid container spacing={2}>
					<Grid item md={6} xs={12} sx={{ mx: "auto" }}>
						<TextField
							size='small'
							sx={{ mb: 2, width: "100%" }}
							id='outlined-name'
							placeholder='Search by Name / TXN / Phone ? Company'
							value={search}
							onChange={handleChange}
						/>
					</Grid>
				</Grid>
				<Paper
					className='container'
					sx={{ overflow: "auto", maxHeight: "80vh", maxWidth: "90vw" }}>
					<Table size='small' aria-label='a dense table'>
						<TableHead sx={{ th: { fontWeight: "bold" } }}>
							<TableRow>
								<TableCell align='left'>No</TableCell>
								<TableCell align='left'>Name</TableCell>
								<TableCell align='left'>TXN</TableCell>
								<TableCell align='left'>Booking Time</TableCell>
								<TableCell align='left'>Check Assign</TableCell>
								{/* 	<TableCell align='left'>Assigned On</TableCell>
								<TableCell align='left'>Confirmed ?</TableCell>
								<TableCell align='left'>Confirmed On</TableCell> */}
								<TableCell align='center'>Action</TableCell>
							</TableRow>
						</TableHead>
						{newList?.length > 0 ? (
							<TableBody sx={{ td: { py: 1 } }}>
								{newList.map((booking) => (
									<TableRow
										key={booking?._id}
										sx={{
											"&:last-child td, &:last-child th": { border: 0 },
										}}>
										{booking?.position === "Completed" ? (
											<TableCell
												align='left'
												sx={{ bgcolor: "#252525", color: "white" }}>
												{count++}
											</TableCell>
										) : (
											<TableCell align='left'>{count++}</TableCell>
										)}
										{booking?.position === "Completed" ? (
											<TableCell
												align='left'
												sx={{ bgcolor: "#252525", color: "white" }}>
												{booking?.firstName + " " + booking?.lastName}
											</TableCell>
										) : (
											<TableCell align='left'>
												{booking?.firstName + " " + booking?.lastName}
											</TableCell>
										)}
										{booking?.forWho === "A Company" ? (
											<TableCell align='left'>
												<div
													style={{
														display: "flex",
														justifyContent: "center",
														alignItems: "center",
													}}>
													{booking?.rideType === "Airport Service" && (
														<AirplanemodeActiveIcon />
													)}
													<BusinessIcon sx={{ mr: 1 }} />
													{booking?.txn}
												</div>
											</TableCell>
										) : (
											<TableCell>
												<div
													style={{
														display: "flex",
														justifyContent: "center",
														alignItems: "center",
													}}>
													{booking?.rideType === "Airport Service" && (
														<AirplanemodeActiveIcon />
													)}
													<PersonIcon sx={{ mr: 1 }} />
													{booking?.txn}
												</div>
											</TableCell>
										)}

										<TableCell align='left'>{booking?.bookingTime}</TableCell>
										<TableCell align='left'>
											{" "}
											<Button
												className='buttonColor'
												sx={{
													fontWeight: "bold",
													border: "2px solid",
													backgroundColor: "transparent",
													borderRadius: "25px",
													m: 0.5,
													textTransform: "capitalize",
													fontSize: "12px",
												}}
												variant='contained'
												onClick={() => handleOpen(booking)}>
												Assign Info
											</Button>
										</TableCell>
										{/* <TableCell align='left'>
											{booking?.assignedTo?.assignedOn}
										</TableCell> */}

										{/* <TableCell align='left'>
											<Box
												sx={{
													display: "flex",
													justifyContent: "center",
													alignItems: "center",
												}}>
												<h4
													style={{
														color: booking?.assignedTo?.isConfirmed
															? "green"
															: "red",
													}}>
													{booking?.assignedTo?.isConfirmed ? "Yes" : "No"}
												</h4>

												{booking?.assignedTo?.email &&
													!booking?.assignedTo?.isConfirmed && (
														<button
															style={{
																backgroundColor: "transparent",
																fontWeight: "bold",
																cursor: "pointer",
																background: "green",
																color: "white",
																border: "1px solid black",
																borderRadius: "5px",
																marginLeft: "5px",
															}}
															onClick={() => {
																setSubmitting(false);
																const id = booking?._id;
																const updatePosition = async () => {
																	try {
																		const response = await axios.patch(
																			`${process.env.REACT_APP_API_PATH}/assignTo/${id}`,
																			{
																				...booking?.assignedTo,
																				isConfirmed: true,
																				confirmedOn: new Date(),
																			},
																			{
																				headers: {
																					Authorization: token,
																				},
																			},
																		);
																		if (response.status) {
																			Swal.fire({
																				icon: "success",
																				title: "Success",
																				text: "Confirmed Successfully",
																			});
																			setSubmitting(true);
																		}
																	} catch (error) {
																		Swal.fire({
																			icon: "error",
																			title: "Error",
																			text: error.response.data.message,
																		});
																	}
																};
																Swal.fire({
																	title: "Are you sure?",
																	text: "You want to confirm this booking",
																	icon: "warning",
																	showCancelButton: true,
																	confirmButtonColor: "#3085d6",
																	cancelButtonColor: "#d33",
																	confirmButtonText: "Yes, Confirm it!",
																}).then(async (result) => {
																	if (result.isConfirmed) {
																		updatePosition();
																	}
																});
															}}>
															Confirm
														</button>
													)}
											</Box>
										</TableCell> */}
										{/* 	<TableCell align='left'>
											{booking?.assignedTo?.confirmedOn}
										</TableCell> */}
										<TableCell align='center'>
											<Link
												to={`/driverDashboard/bookings/${booking?._id}`}
												style={{ textDecoration: "none" }}>
												<Button
													className='buttonColor'
													sx={{
														fontWeight: "bold",
														border: "2px solid",
														backgroundColor: "transparent",
														borderRadius: "25px",
														m: 0.5,
													}}
													variant='contained'>
													<RemoveRedEyeIcon />
												</Button>
											</Link>
											{/* {booking?.position === "Completed" ? (
												<></>
											) : (
												<Button
													className='buttonColor'
													onClick={() => handleDone(booking?._id)}
													sx={{
														fontWeight: "bold",
														border: "2px solid",
														backgroundColor: "transparent",
														borderRadius: "25px",
														m: 0.5,
													}}
													variant='contained'>
													<DoneOutlineIcon />
												</Button>
											)} */}
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						) : (
							<TableHead sx={{ th: { fontWeight: "bold" } }}>
								<TableRow>
									<TableCell align='left'>N/A</TableCell>
									<TableCell align='left'>N/A</TableCell>
									<TableCell align='left'>N/A</TableCell>
									<TableCell align='left'>N/A</TableCell>
									<TableCell align='left'>N/A</TableCell>
									<TableCell align='left'>N/A</TableCell>
									<TableCell align='left'>N/A</TableCell>
								</TableRow>
							</TableHead>
						)}
					</Table>
				</Paper>
			</Grid>
			{!bookings && <BeatLoader size={10} />}
			<Backdrop
				sx={{
					color: "#fff",
					zIndex: (theme) => theme.zIndex.drawer + 1,
				}}
				open={!bookings}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</Container>
	);
};

export default BookingsDriverView;
