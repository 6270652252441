import images from "./ImportImage";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
const GetData = () => {
	const [cars, setCars] = useState([]);
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(`${process.env.REACT_APP_API_PATH}/cars`);

				setCars(response.data.reverse());
			} catch (error) {
				Swal.fire({
					icon: "error",
					title: "Error",
					text: error.response.data.message,
				});
			}
		};
		fetchData();
	}, []);

	const services = [
		{
			id: 1,
			image: images?.corporate,
			service: "Corporate",
			details:
				"Elegance Limo Services has one goal. To exceed your expectations. When your business uses our services, you can be confident your vehicle will arrive on time and that a professionally trained chauffeur will ensure your ride is comfortable and safe. You’ll be able to make new reservations anywhere and anytime via phone, email or online sign up and we’ll focus on every detail to give you the best experience we can offer. We pride ourselves on delivering the most luxurious business meetings, conventions, tours, lunch meetings, spouse activities, airport transportation, etc.",
		},
		{
			id: 2,
			image: images?.daydriver,
			service: "Driver by Day",
			details:
				"It’s our pleasure to announce a new service, “driver by day services”. Which is when a driver is familiar to the client. We are presenting these services for people who want to go out with their families with some privacy. Especially tourist and archeological areas. We offer our fleet with professional drivers to take you anywhere you want, such as whinery, niagara falls, african lion safari, marineland, etc. Just make sure Elegance Limo is for you.",
		},
		{
			id: 3,
			image: images?.marriage,
			service: "Wedding",
			details:
				"This is one of the most important days of your life, so let Elegance Limo take care of transportation. We know how critical, reliable and polished service is on the days around a wedding and are fully equipped to work with you on your wedding planner to make sure every single detail is covered. Your day will go by as smoothly as it was memorable.  We will offer you a luxury van and luxury sprinter to make sure we fit your entire family. Plus, a modern luxury sedan to pick up the groom and bride to anywhere they want like hotels or their home.",
		},
		{
			id: 3,
			image: images?.funeral,
			service: "Funeral",
			details:
				"The death of loved ones is an immensely painful time and planning a funeral can be difficult. During this trying time, allow professionals to take on as many responsibilities as you see fit in order to minimize the stress and strain on our already distressed family. At Elegance Limo Services, our team can be there for you in your time of need and can handle your funeral transportation with wheelchair accessible or limo mini van or suv and beyond in most professional of manners. Kind, sophisticated and knowledgeable professional chauffeurs are at your service.",
		},
		{
			id: 3,
			image: images?.cruise,
			service: "Cruise",
			details:
				"Elegance Limo proudly provides these services for our expensive clients and citizens in our community. We want to build a bridge and strong relationship with each other by offering cruise control when a customer has been out and has consumed over the legal limit of alcohol and therefore can’t drive themselves home. We can provide a service with 2 drivers, 1 for driving the client home and 1 for driving the client’s vehicle home, all done safely of course. We are always here for you.",
		},
		{
			id: 3,
			image: images?.prom,
			service: "Prom",
			details:
				"One of the most enjoyable occasions in a high school career is the prom. There is a ton of planning that’s involved with the event. What to wear, who to go with, how to get there, etc. Elegance Limo can assist you with getting there however the rest is up to you. With our selection of limo fleet, suv, van, sprinter. All can be fit to your requirements. Getting to your high school prom can both be fun and luxurious. Show up at your prom with a smooth ride that will leave everyone envious and make your special occasion that much more memorable with Elegance Limo.",
		},
		{
			id: 3,
			image: images?.airport,
			service: "Airport Pick-ups And Drop-off Service",
			details:
				"Take advantage of Elegance Limo Services. Fleet to your next trip to or from the airport. Elegance Limo Services will go to any airport, including Metro Detroit Airport, Windsor Airport, London Airport and Toronto Pearson International Airport. Elegance Limo Services provides airport meet and greet services in which our driver will meet you inside the baggage claim area with a name sign prepared to carry the luggage for you. We are able to serve your sedan, suv, or executive mini van and luxury sprinter needs 24 hours a day. We also provide a car seat, infant baby seat and booster child seat. According to your requirements upon request.",
		},
		{
			id: 3,
			image: images?.wheelAccessible,
			service: "Wheel Accessible",
			details:
				"Its our pleasure to submit a service wheelchair accessible van to our clients so their ride can be easy, simple and comfortable. Choose your destination with our transit van. Each luxurious vehicle is driven by professionally trained and licensed drivers. We strive to provide quality services to every client. We want everyone to enjoy their time in any occasion.",
		},
		{
			id: 3,
			image: images?.sightseeingServices,
			service: "Sightseeing Services",
			details:
				"If you are looking to do some fun touring with the best driving accommodation possible, look no further. Our chauffeur will take you on an adventure like no other. Elegance Limo will make sure to take care of the stress of driving, parking, pick – ups and drop offs. Trust the professionals to make sure your events go off without a hitch. ",
		},
		{
			id: 3,
			image: images?.parcelDelivery,
			service: "Express Parcel Delivery",
			details:
				"Elegance Limo offers a wide range of delivery services. To suit your needs, we pride ourselves on offering courier services that are as flexible and convenient as possible for our customers. Our dedicated team delivers every parcel with care. We are a company with a personal touch offering a huge range of services.",
		},
	];
	return { allCars: cars, services };
};

export default GetData;
