import { Backdrop, CircularProgress, Container, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import Card from "./Card";
import { useData } from "../../../contex/contex";
import axios from "axios";
import Swal from "sweetalert2";

const Summary = () => {
	const [bookings, setBookings] = useState([]);
	const [submitting, setSubmitting] = useState(true);
	const { token, user } = useData();
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(
					`${process.env.REACT_APP_API_PATH}/bookings/${user?.userRole}/${user?.email}`,
					{
						headers: {
							Authorization: token,
						},
					},
				);
				setBookings(response.data.reverse());
				setSubmitting(false);
			} catch (error) {
				Swal.fire({
					icon: "error",
					title: "Error",
					text: error.response.data.message,
				});
			}
		};
		token && fetchData();
	}, [token, user]);

	function countRideType(rideTypeToCount) {
		return bookings?.reduce((count, booking) => {
			const rides = booking.rides || [];
			return (
				count +
				rides.reduce((rideCount, ride) => {
					return ride.rideType === rideTypeToCount ? rideCount + 1 : rideCount;
				}, 0)
			);
		}, 0);
	}

	const Distance = countRideType("Distance");
	const Hourly = countRideType("Hourly");
	const FlatRate = countRideType("Flat Rate");
	const ChauffeurServices = countRideType("Chauffeur Services");
	const FlightServices = countRideType("Airport Service");

	function calculateTotalAmount(status) {
		return bookings.reduce((totalAmount, booking) => {
			if (booking.position === status) {
				const amount = booking.priceByAdmin || booking.quotedPrice || 0;
				return totalAmount + parseFloat(amount);
			}
			return totalAmount;
		}, 0);
	}

	const pendingMoney = calculateTotalAmount("Pending");
	const completedMoney = calculateTotalAmount("Completed");

	return (
		<Container>
			<Grid container spacing={2} alignItems='center' sx={{ my: 1.5 }}>
				<Grid item md={12} xs={12}>
					<Card
						bookings={bookings}
						Distance={Distance}
						Hourly={Hourly}
						FlatRate={FlatRate}
						ChauffeurServices={ChauffeurServices}
						FlightServices={FlightServices}
						pendingMoney={pendingMoney}
						completedMoney={completedMoney}
					/>
				</Grid>
				{/* <Grid
					item
					md={6}
					xs={12}
					sx={{
						"& .MuiDialogActions-root": {
							display: "none !important",
						},
					}}>
					<Calander />
				</Grid>
				<Grid item md={6} xs={12}>
					<Chart
						bookings={bookings}
						Distance={Distance}
						Hourly={Hourly}
						FlatRate={FlatRate}
						ChauffeurServices={ChauffeurServices}
						FlightServices={FlightServices}
					/>
				</Grid> */}
			</Grid>
			<Backdrop
				sx={{
					color: "#fff",
					zIndex: (theme) => theme.zIndex.drawer + 1,
				}}
				open={submitting}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</Container>
	);
};

export default Summary;
