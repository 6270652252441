import * as React from "react";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import {
	Button,
	Container,
	FormControl,
	FormHelperText,
	Grid,
	MenuItem,
	Select,
} from "@mui/material";
import { useForm } from "react-hook-form";

export default function Tab2({ rides, setRides, ride }) {
	const [dateTime, setValue] = React.useState(new Date(ride?.time2) || "");

	const [newChange, setNewChange] = React.useState(false);
	const handleChange = (newValue) => {
		setValue(newValue);
		setNewChange(true);
	};
	const [duration, setDuration] = React.useState(ride?.duration2 || "");

	const handleExtraHourChange = (event) => {
		setDuration(event.target.value);
		setNewChange(true);
	};
	const { register, handleSubmit, reset } = useForm({
		defaultValues: {
			picupLocation2: "",
			dropOffLocation2: "",
		},
	});
	React.useEffect(() => {
		reset(ride);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		setValue(new Date(ride?.time2 || ""));
		setDuration(ride?.duration2 || "");
	}, [ride]);

	const onSubmit = ({ picupLocation2, dropOffLocation2 }) => {
		const data = {
			time2: dateTime,
			picupLocation2,
			dropOffLocation2,
			duration2: duration,
			rideType: "Hourly",
			tab: 1,
			rideId: Math.round(Math.random() * 10000),
			paidPrice: 0,
			paid: false,
		};
		setRides(
			rides.map((r) => {
				if (r.rideId === ride.rideId) {
					return data;
				}
				return r;
			}),
		);
		setNewChange(false);
		/* reactLocalStorage.setObject("rideDetails", data);
		navigate(destination); */
	};

	return (
		<Container>
			<form onSubmit={handleSubmit(onSubmit)}>
				<LocalizationProvider dateAdapter={AdapterDateFns}>
					<Grid container spacing={2}>
						<Grid item md={6} xs={12}>
							<FormHelperText>PICKUP DATE</FormHelperText>
							<DesktopDatePicker
								inputFormat='MM/dd/yyyy'
								value={dateTime}
								onChange={handleChange}
								renderInput={(params) => (
									<TextField required fullWidth {...params} />
								)}
							/>
						</Grid>

						<Grid item md={6} xs={12}>
							<FormHelperText>PICKUP TIME</FormHelperText>
							<TimePicker
								ampm={false}
								value={dateTime}
								onChange={handleChange}
								renderInput={(params) => <TextField fullWidth {...params} />}
							/>
						</Grid>
						<Grid item md={12} xs={12}>
							<FormHelperText>PICKUP LOCATION</FormHelperText>
							<TextField
								fullWidth
								id='filled-search'
								placeholder='ENTER A LOCATION'
								type='standard'
								{...register("picupLocation2", { required: true })}
								onChange={() => setNewChange(true)}
							/>
						</Grid>
						<Grid item md={12} xs={12}>
							<FormControl fullWidth>
								<FormHelperText sx={{ ml: 0 }}>
									DURATION (IN HOURS)
								</FormHelperText>
								<Select
									required
									value={duration}
									onChange={handleExtraHourChange}
									displayEmpty
									inputProps={{ "aria-label": "Without label" }}>
									<MenuItem value=''>
										<em>0 HOUR(S)</em>
									</MenuItem>
									<MenuItem value={"1 HOUR"}>1 HOUR(S)</MenuItem>
									<MenuItem value={"2 HOUR"}>2 HOUR(S)</MenuItem>
									<MenuItem value={"3 HOUR"}>3 HOUR(S)</MenuItem>
									<MenuItem value={"4 HOUR"}>4 HOUR(S)</MenuItem>
									<MenuItem value={"5 HOUR"}>5 HOUR(S)</MenuItem>
								</Select>
							</FormControl>
						</Grid>
						<Grid item md={12} xs={12}>
							<FormHelperText>DROP-OFF LOCATION</FormHelperText>
							<TextField
								fullWidth
								id='filled-search'
								placeholder='ENTER A LOCATION'
								type='standard'
								{...register("dropOffLocation2", { required: true })}
								onChange={() => setNewChange(true)}
							/>
						</Grid>
						<Grid item md={6} xs={12}>
							<Button
								type='submit'
								className='buttonColor'
								sx={{
									width: "100%",
									py: 1.7,
									display: !newChange && "none !important",
									backgroundColor: "red !important",
								}}
								variant='contained'>
								Next
							</Button>
						</Grid>
						<Grid item md={6} xs={12}>
							<Button
								onClick={() => {
									setRides([
										...rides,
										{
											rideId: Math.round(Math.random() * 10000),
										},
									]);
								}}
								className='buttonColor'
								variant='contained'
								sx={{
									width: "100%",
									py: 1.5,
									mb: 2,
									display:
										rides?.[rides?.length - 1]?.rideId !== ride?.rideId &&
										"none",
								}}>
								ADD NEW RIDE
							</Button>
						</Grid>
					</Grid>
				</LocalizationProvider>
			</form>
		</Container>
	);
}
