import { CardMedia, Container, Divider, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import SwiperCore, { Autoplay, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import GetData from "../GetData";
SwiperCore.use([Autoplay, Pagination]);

const OurFleet = () => {
	const { allCars } = GetData();

	return (
		<Container sx={{ pb: 7 }} data-aos='zoom-in'>
			<Typography
				className='textColor'
				sx={{ mb: 0.5, fontWeight: 800, my: 3 }}
				variant='h5'
				component='div'
				gutterBottom>
				OUR FLEET <Divider className='hr' />
			</Typography>
			<Swiper
				autoHeight={true}
				grabCursor={true}
				loop={true}
				autoplay={{ delay: 2500 }}
				centeredSlides={true}
				breakpoints={{
					350: {
						slidesPerView: 1,
					},
					480: {
						slidesPerView: 1,
					},
					768: {
						slidesPerView: 2,
					},
					1024: {
						slidesPerView: 3,
					},
				}}
				slidesPerView={4}
				spaceBetween={30}
				className='mySwiper'>
				{allCars?.map((a, key) => (
					<SwiperSlide key={key}>
						<Link to={`/Cars/${a?.name}`} style={{ textDecoration: "none" }}>
							<CardMedia
								component='img'
								image={a?.images?.[0]?.url}
								alt=''
								sx={{ width: "100%" }}
							/>
							<Typography
								sx={{ fontWeight: "bold", mt: 2 }}
								className='textColor'
								variant='h6'
								component='div'
								gutterBottom>
								{a?.name.toUpperCase()}
							</Typography>
						</Link>
					</SwiperSlide>
				))}
			</Swiper>
		</Container>
	);
};

export default OurFleet;
