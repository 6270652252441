import {
	Backdrop,
	Button,
	CircularProgress,
	Container,
	Grid,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import DeleteIcon from "@mui/icons-material/Delete";
import { useData } from "../../../contex/contex";

const AllDrivers = () => {
	const [submitting, setSubmitting] = useState(false);
	const [allDriverList, setAllDriverList] = useState([]);
	const { token } = useData();
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(
					`${process.env.REACT_APP_API_PATH}/AllDrivers`,
					{
						headers: {
							Authorization: token,
						},
					},
				);

				setAllDriverList(response.data.reverse());
			} catch (error) {
				Swal.fire({
					icon: "error",
					title: "Error",
					text: error.response.data.message,
				});
			}
		};
		token && fetchData();
	}, [token, submitting]);

	const handleDelete = async (id) => {
		Swal.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, delete it!",
		}).then(async (result) => {
			if (result.isConfirmed) {
				setSubmitting(true);
				try {
					const response = await axios.delete(
						`${process.env.REACT_APP_API_PATH}/users/${id}`,
						{
							headers: {
								Authorization: token,
							},
						},
					);

					if (response.status) {
						Swal.fire({
							icon: "success",
							title: "Deleted Successfully",
							showConfirmButton: false,
							timer: 1500,
						});
						setSubmitting(false);
					}
				} catch (error) {
					Swal.fire({
						icon: "error",
						title: "Error",
						text: error.response.data.message,
					});
				}
			}
		});
	};

	let count = 1;

	return (
		<Container sx={{ mt: 2, minHeight: "100vh" }}>
			<Grid>
				<Typography
					sx={{ mb: 3, fontWeight: "bold" }}
					variant='h4'
					component='div'
					gutterBottom>
					All Drivers
				</Typography>
				<Button variant='outlined' color='primary' sx={{ mb: 2 }}>
					<a
						href='/job order.pdf'
						download
						style={{
							textDecoration: "none",
						}}>
						Job Order PDF
					</a>
				</Button>
				<Paper
					className='container'
					sx={{ overflow: "auto", maxHeight: "80vh", maxWidth: "90vw" }}>
					<Table size='small' aria-label='a dense table'>
						<TableHead sx={{ th: { fontWeight: "bold" } }}>
							<TableRow>
								<TableCell align='left'>No</TableCell>
								<TableCell align='left'>Name</TableCell>
								<TableCell align='left'>Email</TableCell>
								<TableCell align='left'>Phone</TableCell>
								<TableCell align='left'>Joined On</TableCell>
								<TableCell align='center'>Action</TableCell>
							</TableRow>
						</TableHead>
						{allDriverList?.length > 0 ? (
							<TableBody sx={{ td: { py: 1 } }}>
								{allDriverList.map((driver) => (
									<TableRow
										key={driver?._id}
										sx={{
											"&:last-child td, &:last-child th": { border: 0 },
										}}>
										<TableCell align='left'>{count++}</TableCell>
										<TableCell align='left'>{driver?.displayName}</TableCell>
										<TableCell align='left'>{driver?.email}</TableCell>
										<TableCell align='left'>{driver?.phone}</TableCell>
										<TableCell align='left'>{driver?.joinedDate}</TableCell>
										<TableCell align='center'>
											<Button
												className='buttonColor'
												onClick={() => handleDelete(driver?._id)}
												sx={{
													fontWeight: "bold",
													border: "2px solid",
													backgroundColor: "transparent",
													borderRadius: "25px",
													m: 0.5,
												}}
												variant='contained'>
												<DeleteIcon />
											</Button>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						) : (
							<TableHead sx={{ th: { fontWeight: "bold" } }}>
								<TableRow>
									<TableCell align='left'>N/A</TableCell>
									<TableCell align='left'>N/A</TableCell>
									<TableCell align='left'>N/A</TableCell>
									<TableCell align='left'>N/A</TableCell>
									<TableCell align='left'>N/A</TableCell>
									<TableCell align='left'>N/A</TableCell>
								</TableRow>
							</TableHead>
						)}
					</Table>
				</Paper>
			</Grid>
			<Backdrop
				sx={{
					color: "#fff",
					zIndex: (theme) => theme.zIndex.drawer + 1,
				}}
				open={submitting || !allDriverList}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</Container>
	);
};

export default AllDrivers;
