import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import {
	Grid,
	TextField,
	Typography,
	Container,
	Backdrop,
	CircularProgress,
	Box,
} from "@mui/material";
import { reactLocalStorage } from "reactjs-localstorage";
import { useNavigate } from "react-router-dom";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import Swal from "sweetalert2";
import axios from "axios";
import { useData } from "../../../contex/contex";

const CustomerType = () => {
	/* 	const [whom, setWhom] = useState("");
	useEffect(() => {
		const data = { forWhom: whom };
		reactLocalStorage.setObject("forWhom", data);
	}, [whom]); */
	const { token, user } = useData();

	useEffect(() => {
		if (user?.userType === "Company") {
			setWho("A Company");
		} else if (user?.userType === "Personal") {
			setWho("A Person");
		}
	}, [user?.userType]);

	const [whom, setWhom] = useState("Booking on behalf");
	useEffect(() => {
		setWhom("Booking on behalf");
		const data = { forWhom: "Booking on behalf" };
		reactLocalStorage.setObject("forWhom", data);
	}, []);

	const [who, setWho] = useState("");
	useEffect(() => {
		const data = { forWho: who };
		reactLocalStorage.setObject("forWho", data);
	}, [who]);

	const [company, setCompany] = React.useState("");

	const [buttonSelected /* setButtonSelected */] = useState("");

	useEffect(() => {
		const data = { company: company };
		reactLocalStorage.setObject("company", data);
	}, [company]);

	const [reqCompany, setReqCompany] = React.useState("");
	const generateSecretCode = (reqCom) => {
		// Convert company name to uppercase and remove spaces
		const formattedCompanyName = reqCom.toUpperCase().replace(/\s/g, "");

		// Take the first three characters from the company name
		const shortName = formattedCompanyName.substring(0, 3);

		// Generate a random 3-digit number
		const randomNumber = Math.floor(100 + Math.random() * 900);

		// Combine the short name and random number to create the secret code
		const code = `${shortName}${randomNumber}`;

		setSecretCode(code);
	};
	const handleChangeReqQompany = (event) => {
		setReqCompany(event.target.value);
		generateSecretCode(event.target.value);
	};
	const [secretCode, setSecretCode] = useState("");

	useEffect(() => {
		const data = { reqCompany: reqCompany };
		reactLocalStorage.setObject("company", data);
	}, [reqCompany]);

	const [datas, setDatas] = useState();

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(
					`${process.env.REACT_APP_API_PATH}/companyAdmin/${user?.email}`,
					{
						headers: {
							Authorization: token,
						},
					},
				);

				setDatas(response.data);
			} catch (error) {
				console.log(error);
			}
		};
		token && fetchData();
	}, [token, user]);

	useEffect(() => {
		const data = {
			reqCompany: who === "A Company" ? datas?.name : "N/A",
		};
		setCompany(who === "A Company" ? datas?.name : "N/A");
		reactLocalStorage.setObject("company", data);
	}, [datas, who]);

	const navigate = useNavigate();
	const destination = "/contactdetails";

	const [txn, setTxn] = React.useState("");
	useEffect(() => {
		const randomNumber = Math.floor(Math.random() * 90000) + 10000;
		if (who === "A Company") {
			setTxn("COM" + randomNumber);
		} else {
			setTxn("PER" + randomNumber);
		}
	}, [who]);

	const [submitting, setSubmitting] = useState(false);

	const submit = async () => {
		const forWhom = reactLocalStorage.getObject("forWhom");
		const forWho = reactLocalStorage.getObject("forWho");
		const company = reactLocalStorage.getObject("company");
		const data = { ...forWhom, ...forWho, ...company, txn: txn };
		reactLocalStorage.setObject("client", data);
		const datas = {
			time: new Date().toLocaleString(),
			name: reqCompany,
			secretCode: secretCode,
			companyAdmin: user?.email,
		};
		if (buttonSelected === "Request to add") {
			setSubmitting(true);
			try {
				const response = await axios.post(
					`${process.env.REACT_APP_API_PATH}/companylist`,
					datas,
				);

				if (response.status === 201) {
					setSubmitting(false);
					Swal.fire({
						icon: "success",
						title: "Company Added Successfully",
						showConfirmButton: false,
						timer: 1500,
					}).then(function () {
						navigate(destination);
					});
				}
			} catch (error) {
				Swal.fire({
					icon: "error",
					title: "Error",
					text: error.response.data.message,
				});
			}
		} else {
			navigate(destination);
		}
	};

	return (
		<div>
			<Container sx={{ py: 5, minHeight: "50vh" }}>
				<Typography
					gutterBottom
					variant='h5'
					component='div'
					sx={{ fontWeight: "bold" }}>
					Welcome, {user?.displayName}
				</Typography>
				<Typography
					gutterBottom
					variant='h6'
					component='div'
					sx={{ fontWeight: "bold" }}>
					You are booking for{" "}
					{who === "A Company" ? "your company" : "yourself"}
				</Typography>
				<Grid container spacing={2}>
					<Grid item md={5} sm={7} xs={12} sx={{ mx: "auto" }}>
						<Grid container spacing={2}>
							{/* <Grid item md={12} xs={12}>
								<FormHelperText sx={{ textAlign: "center", fontSize: 23 }}>
									Booking For?
								</FormHelperText>
								<ButtonGroup variant='contained' sx={{ width: "100%" }}>
									{whom === "Booking for myself" ? (
										<Button
											startIcon={<PersonIcon />}
											className='buttonActiveColor'
											sx={{ width: "100%", py: 1.3 }}
											variant='contained'
											onClick={() => setWhom("Booking for myself")}>
											Booking for myself
										</Button>
									) : (
										<Button
											startIcon={<PersonIcon />}
											className='buttonColor'
											sx={{ width: "100%", py: 1.3 }}
											variant='contained'
											onClick={() => setWhom("Booking for myself")}>
											Booking for myself
										</Button>
									)}
									{whom === "Booking on behalf" ? (
										<Button
											startIcon={<PersonAddAltIcon />}
											className='buttonActiveColor'
											sx={{ width: "100%", py: 1.3 }}
											variant='contained'
											onClick={() => setWhom("Booking on behalf")}>
											Booking on behalf
										</Button>
									) : (
										<Button
											startIcon={<PersonAddAltIcon />}
											className='buttonColor'
											sx={{ width: "100%", py: 1.3 }}
											variant='contained'
											onClick={() => setWhom("Booking on behalf")}>
											Booking on behalf
										</Button>
									)}
								</ButtonGroup>
							</Grid> */}
							{/* 	{whom === "Booking on behalf" && (
								<Grid item md={12} xs={12}>
									<FormHelperText sx={{ textAlign: "center", fontSize: 23 }}>
										You Are?
									</FormHelperText>
									<ButtonGroup variant='contained' sx={{ width: "100%" }}>
										{who === "A Person" ? (
											<Button
												startIcon={<PersonIcon />}
												className='buttonActiveColor'
												variant='contained'
												sx={{ width: "100%", py: 1.3 }}
												onClick={() => setWho("A Person")}>
												Are you a person?
											</Button>
										) : (
											<Button
												startIcon={<PersonIcon />}
												className='buttonColor'
												variant='contained'
												sx={{ width: "100%", py: 1.3 }}
												onClick={() => setWho("A Person")}>
												Are you a person?
											</Button>
										)}
										{who === "A Company" ? (
											<Button
												startIcon={<BusinessIcon />}
												className='buttonActiveColor'
												sx={{ width: "100%", py: 1.3 }}
												variant='contained'
												onClick={() => setWho("A Company")}>
												Are You a company?
											</Button>
										) : (
											<Button
												startIcon={<BusinessIcon />}
												className='buttonColor'
												sx={{ width: "100%", py: 1.3 }}
												variant='contained'
												onClick={() => setWho("A Company")}>
												Are You a company?
											</Button>
										)}
									</ButtonGroup>
								</Grid>
							)} */}
							{who === "A Company" && (
								<Grid item md={12} xs={12}>
									<Typography
										gutterBottom
										variant='h5'
										component='div'
										sx={{ fontWeight: "bold" }}>
										Company :{(who === "A Company" && datas?.name) || "N/A"}
									</Typography>
									<Box
										sx={{
											display: "flex",
											justifyContent: "space-between",
											mt: 1.5,
										}}>
										{who === "A Company" && !datas?.name && (
											<Button
												className='buttonColor'
												variant='contained'
												sx={{
													width: "100%",
												}}
												onClick={() => {
													const role = user?.userRole;
													if (role === "Admin" || role === "Developer") {
														window.location.href = "/dashboard";
													} else if (role === "User") {
														window.location.href = "/userDashboard";
													} else if (role === "Driver") {
														window.location.href = "/driverDashboard";
													}
												}}>
												Not Listed? Request to add.
											</Button>
										)}

										{/* 	{buttonSelected !== "Request to add" && (
											<Button
												className='buttonColor'
												variant='contained'
												sx={{
													width: "100%",
												}}
												onClick={() => {
													setButtonSelected("Request to add");
													setReqCompany("");
												}}>
												Not Listed?
											</Button>
										)} */}
									</Box>
									{buttonSelected === "Request to add" && (
										<TextField
											size='small'
											sx={{ width: "100%", mt: 1.5 }}
											value={reqCompany}
											onChange={handleChangeReqQompany}
											placeholder='Request to add your Company'
											variant='outlined'
										/>
									)}
								</Grid>
							)}
							{(company && who === "A Company") ||
								buttonSelected === "Request to add" ||
								whom === "Booking for myself" ||
								who === "A Person" ? (
								<Grid item md={12} xs={12}>
									<Button
										endIcon={<ArrowRightIcon />}
										className='buttonColor'
										variant='contained'
										sx={{
											width: "100%",
											mb: 2,
											pointerEvents:
												buttonSelected === "Request to add" && !reqCompany
													? "none"
													: "auto",
										}}
										onClick={submit}>
										Continue Booking
									</Button>
									<Button
										className='buttonColor'
										variant='contained'
										sx={{
											width: "100%",
											mb: 4,
										}}
										onClick={() => {
											const role = user?.userRole;
											if (role === "Admin" || role === "Developer") {
												window.location.href = "/dashboard";
											} else if (role === "User") {
												window.location.href = "/userDashboard";
											} else if (role === "Driver") {
												window.location.href = "/driverDashboard";
											}
										}}>
										Your Profile
									</Button>
								</Grid>
							) : (
								<Grid item md={12} xs={12}>
									<Typography
										gutterBottom
										variant='h6'
										component='div'
										sx={{ color: "red" }}>
										Please Complete Your Profile to Continue Booking with us.
									</Typography>
									<Button
										className='buttonColor'
										variant='contained'
										sx={{
											width: "100%",
											mb: 4,
										}}
										onClick={() => {
											const role = user?.userRole;
											if (role === "Admin" || role === "Developer") {
												window.location.href = "/dashboard";
											} else if (role === "User") {
												window.location.href = "/userDashboard";
											} else if (role === "Driver") {
												window.location.href = "/driverDashboard";
											}
										}}>
										Complete Your Profile
									</Button>
								</Grid>
							)}
						</Grid>
					</Grid>
				</Grid>
				<Backdrop
					sx={{
						color: "#fff",
						zIndex: (theme) => theme.zIndex.drawer + 1,
					}}
					open={submitting}>
					<CircularProgress color='inherit' />
				</Backdrop>
			</Container>
		</div>
	);
};

export default CustomerType;
