import {
	Backdrop,
	Button,
	CircularProgress,
	Container,
	Grid,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TextField,
	Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import DeleteIcon from "@mui/icons-material/Delete";
import { useForm } from "react-hook-form";
import { useData } from "../../../contex/contex";

const CompanyList = () => {
	const [allUserList, setAllUserList] = useState([]);
	const [submitting, setSubmitting] = useState(false);
	const { token } = useData();
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(
					`${process.env.REACT_APP_API_PATH}/users`,
					{
						headers: {
							Authorization: token,
						},
					},
				);

				setAllUserList(response.data.reverse());
			} catch (error) {
				Swal.fire({
					icon: "error",
					title: "Error",
					text: error.response.data.message,
				});
			}
		};
		token && fetchData();
	}, [token, submitting]);

	const [companyList, setCompanyList] = useState([]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(
					`${process.env.REACT_APP_API_PATH}/companylist`,
					{
						headers: {
							Authorization: token,
						},
					},
				);

				setCompanyList(response.data.reverse());
			} catch (error) {
				Swal.fire({
					icon: "error",
					title: "Error",
					text: error.response.data.message,
				});
			}
		};
		token && fetchData();
	}, [token, submitting]);

	const handleDelete = async (id) => {
		Swal.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, delete it!",
		}).then(async (result) => {
			if (result.isConfirmed) {
				setSubmitting(true);
				try {
					const response = await axios.delete(
						`${process.env.REACT_APP_API_PATH}/companylist/${id}`,
						{
							headers: {
								Authorization: token,
							},
						},
					);

					if (response.status) {
						Swal.fire({
							icon: "success",
							title: "Deleted Successfully",
							showConfirmButton: false,
							timer: 1500,
						});
						setSubmitting(false);
					}
				} catch (error) {
					Swal.fire({
						icon: "error",
						title: "Error",
						text: error.response.data.message,
					});
				}
			}
		});
	};

	const { register, handleSubmit, reset } = useForm();

	const generateSecretCode = (reqCom) => {
		// Convert company name to uppercase and remove spaces
		const formattedCompanyName = reqCom.toUpperCase().replace(/\s/g, "");

		// Take the first three characters from the company name
		const shortName = formattedCompanyName.substring(0, 3);

		// Generate a random 3-digit number
		const randomNumber = Math.floor(100 + Math.random() * 900);

		// Combine the short name and random number to create the secret code
		const code = `${shortName}${randomNumber}`;

		return code;
	};
	const onSubmit = async ({ name }) => {
		const data = {
			time: new Date().toLocaleString(),
			name,
			secretCode: generateSecretCode(name),
		};
		setSubmitting(true);
		try {
			const response = await axios.post(
				`${process.env.REACT_APP_API_PATH}/companylist`,
				data,
				{
					headers: {
						Authorization: token,
					},
				},
			);

			if (response.status === 201) {
				setSubmitting(false);
				Swal.fire({
					icon: "success",
					title: "New Company Added Successfully",
					showConfirmButton: false,
					timer: 1500,
				}).then(function () {
					reset();
				});
			}
		} catch (error) {
			Swal.fire({
				icon: "error",
				title: "Error",
				text: error.response.data.message,
			});
		}
	};

	let count = 1;

	return (
		<Container sx={{ mt: 2, minHeight: "100vh" }}>
			<Grid container spacing={2} sx={{ mb: 3 }}>
				<Grid item md={6} xs={12} sx={{ mx: "auto" }}>
					<Typography
						sx={{ mb: 3, fontWeight: "bold" }}
						variant='h4'
						component='div'
						gutterBottom>
						Add New Company
					</Typography>
					<form onSubmit={handleSubmit(onSubmit)}>
						<TextField
							required
							sx={{ width: "100%", mb: 2 }}
							id='"outlined-multiline-flexible'
							label='Company Name'
							{...register("name", { required: true })}
						/>
						<Button
							type='submit'
							variant='contained'
							className='buttonColor'
							sx={{
								width: "100%",
								mb: 2,
								px: 3,
								fontWeight: "bold",
								border: "2px solid",
								borderRadius: "25px",
							}}>
							ADD COMPANY
						</Button>
					</form>
				</Grid>
			</Grid>
			<Grid>
				<Typography
					sx={{ mb: 3, fontWeight: "bold" }}
					variant='h4'
					component='div'
					gutterBottom>
					Company List
				</Typography>
				<Paper
					className='container'
					sx={{ overflow: "auto", maxHeight: "80vh", maxWidth: "90vw" }}>
					<Table size='small' aria-label='a dense table'>
						<TableHead sx={{ th: { fontWeight: "bold" } }}>
							<TableRow>
								<TableCell align='left'>No</TableCell>
								<TableCell align='left'>Added Time</TableCell>
								<TableCell align='left'>Name</TableCell>
								<TableCell align='left'>Company Code</TableCell>
								<TableCell align='left'>Company Admin</TableCell>
								<TableCell align='center'>Action</TableCell>
							</TableRow>
						</TableHead>
						{companyList?.length > 0 ? (
							<TableBody sx={{ td: { py: 1 } }}>
								{companyList.map((company) => (
									<TableRow
										key={company?._id}
										sx={{
											"&:last-child td, &:last-child th": { border: 0 },
										}}>
										<TableCell align='left'>{count++}</TableCell>
										<TableCell align='left'>{company?.time}</TableCell>
										<TableCell align='left'>{company?.name}</TableCell>
										<TableCell align='left'>{company?.secretCode}</TableCell>
										<TableCell align='left'>
											<select
												style={{
													border: "none",
													backgroundColor: "transparent",
													fontWeight: "bold",
													color: company?.companyAdmin ? "green" : "red",
												}}
												value={company?.companyAdmin}
												name='position'
												id='position'
												onChange={(e) => {
													const position = e.target.value;
													const id = company?._id;
													const updatePosition = async () => {
														setSubmitting(true);
														try {
															const response = await axios.patch(
																`${process.env.REACT_APP_API_PATH}/makeCompanyAdmin/${id}`,
																{
																	companyAdmin: position,
																},
																{
																	headers: {
																		Authorization: token,
																	},
																},
															);
															if (response.status) {
																Swal.fire({
																	icon: "success",
																	title: "Success",
																	text: "Assigned Successfully",
																});
																setSubmitting(false);
															}
														} catch (error) {
															Swal.fire({
																icon: "error",
																title: "Error",
																text: error.response.data.message,
															});
														}
													};
													Swal.fire({
														title: "Are you sure?",
														text: "You want to assign this user as company admin to this company!",
														icon: "warning",
														showCancelButton: true,
														confirmButtonColor: "#3085d6",
														cancelButtonColor: "#d33",
														confirmButtonText: "Yes, Assign it!",
													}).then(async (result) => {
														if (result.isConfirmed) {
															updatePosition();
														}
													});
												}}>
												<option value=''>Select New</option>
												{allUserList?.map((usr) => (
													<option value={usr?.email}>
														{usr?.displayName}-{usr?.email}
													</option>
												))}
											</select>
										</TableCell>
										<TableCell align='center'>
											<Button
												className='buttonColor'
												onClick={() => handleDelete(company?._id)}
												sx={{
													fontWeight: "bold",
													border: "2px solid",
													backgroundColor: "transparent",
													borderRadius: "25px",
													m: 0.5,
												}}
												variant='contained'>
												<DeleteIcon />
											</Button>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						) : (
							<TableHead sx={{ th: { fontWeight: "bold" } }}>
								<TableRow>
									<TableCell align='left'>N/A</TableCell>
									<TableCell align='left'>N/A</TableCell>
									<TableCell align='left'>N/A</TableCell>
									<TableCell align='left'>N/A</TableCell>
									<TableCell align='left'>N/A</TableCell>
								</TableRow>
							</TableHead>
						)}
					</Table>
				</Paper>
			</Grid>
			<Backdrop
				sx={{
					color: "#fff",
					zIndex: (theme) => theme.zIndex.drawer + 1,
				}}
				open={submitting || !companyList}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</Container>
	);
};

export default CompanyList;
