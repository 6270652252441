import { Box, Button, Container, Grid, Typography } from "@mui/material";
import React from "react";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { Link } from "react-router-dom";

const WhatAboutPricing = () => {
	return (
		<Box
			sx={{
				backgroundColor: "#969590",
				my: 7,
				textAlign: { md: "left", xs: "center" },
			}}>
			<Container>
				<Grid
					container
					spacing={{ md: 0, xs: 2 }}
					sx={{ alignItems: "center", pb: { xs: 2, sm: 0 } }}>
					<Grid item xs={12} md={3} data-aos='flip-right'>
						<Typography
							sx={{ fontWeight: "bold", color: "white" }}
							variant='h6'
							component='div'
							gutterBottom>
							WHAT ABOUT PRICING?
						</Typography>
					</Grid>
					<Grid item xs={12} md={7} data-aos='flip-up'>
						<Typography
							sx={{ color: "white" }}
							variant='subtitle2'
							component='div'
							gutterBottom>
							Don’t worry. We provide very nice deals and the most competative
							price just for you!
						</Typography>
					</Grid>
					<Grid item xs={12} md={2} data-aos='flip-left'>
						<Link to='/startBooking' style={{ textDecoration: "none" }}>
							{" "}
							<Button
								variant='contained'
								disableElevation
								sx={{
									backgroundColor: "#000000",
									color: "white",
									fontWeight: "bold",
									"&:hover": {
										backgroundColor: "#000000",
									},
									height: "100%",
									py: 5,
								}}>
								Book Now <ArrowRightAltIcon sx={{ color: "white", ml: 2 }} />
							</Button>
						</Link>
					</Grid>
				</Grid>
			</Container>
		</Box>
	);
};

export default WhatAboutPricing;
