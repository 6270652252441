import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import CssBaseline from "@mui/material/CssBaseline";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Fade from "@mui/material/Fade";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import { CardMedia, Grid } from "@mui/material";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import { Link, useLocation } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Cookies from "js-cookie";

function ScrollTop(props) {
	const { children, window } = props;
	const trigger = useScrollTrigger({
		target: window ? window() : undefined,
		disableHysteresis: true,
		threshold: 150,
	});

	const handleClick = (event) => {
		const anchor = (event.target.ownerDocument || document).querySelector(
			"#back-to-top-anchor",
		);

		if (anchor) {
			anchor.scrollIntoView({
				block: "center",
			});
		}
	};

	return (
		<Fade in={trigger}>
			<Box
				onClick={handleClick}
				role='presentation'
				sx={{ position: "fixed", bottom: 16, right: 16 }}>
				{children}
			</Box>
		</Fade>
	);
}

ScrollTop.propTypes = {
	children: PropTypes.element.isRequired,
	window: PropTypes.func,
};

export default function Navbar(props) {
	const [anchorElNav, setAnchorElNav] = React.useState(false);
	const handleOpenNavMenu = (event) => {
		setAnchorElNav(!anchorElNav);
	};

	const location = useLocation();
	if (
		location.pathname.includes("/dashboard") ||
		location.pathname.includes("/userDashboard") ||
		location.pathname.includes("/driverDashboard")
	)
		return null;

	const loggedInUser =
		Cookies.get("user")?.length > 0 ? JSON.parse(Cookies.get("user")) : null;

	return (
		<React.Fragment>
			<CssBaseline />
			<AppBar sx={{ pt: 2 }} position='static'>
				<Grid container spacing={2} className='bgColor'>
					<Grid item xs={6} md={4} data-aos='fade-right'>
						<Typography variant='body2' gutterBottom>
							نتكلم العربية
						</Typography>
						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
								cursor: "pointer",
							}}
							onClick={() => window.open(`tel:1-519-969-4444`)}>
							<CallIcon sx={{ color: "white", mr: 0.3 }} />
							<Typography variant='body2' gutterBottom>
								1-519-969-4444
							</Typography>
						</Box>
					</Grid>
					<Grid item xs={6} md={4} data-aos='fade-down'>
						<Box
							sx={{
								width: { md: 100, xs: 75 },
								height: { md: 100, xs: 75 },
								mx: "auto",
							}}>
							<CardMedia component='img' image='/logo.png' alt='logo' />
						</Box>
					</Grid>
					<Grid item xs={12} md={4} data-aos='fade-left'>
						{/* <Box
							sx={{
								display: "flex",
								justifyContent: "center",
								cursor: "pointer",
							}}
							onClick={() => window.open(`tel:1-888-969-0045`)}>
							<CallIcon sx={{ color: "white", mr: 0.3 }} />
							<Typography variant='body2' gutterBottom>
								Toll free : 1-888-969-0045
							</Typography>
						</Box> */}
						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
								cursor: "pointer",
							}}
							onClick={() => window.open(`mailto:info@elegancelimo.ca`)}>
							<EmailIcon sx={{ color: "white", mr: 0.3 }} />
							<Typography variant='body2' gutterBottom>
								info@elegancelimo.ca
							</Typography>
						</Box>
					</Grid>
				</Grid>
			</AppBar>
			<Box
				id='back-to-top-anchor'
				maxWidth='xl'
				className='bgColor'
				sx={{ position: "sticky", top: 0, zIndex: 99, maxWidth: "100%" }}>
				<Toolbar
					data-aos='zoom-in'
					disableGutters
					sx={{ justifyContent: "center", alignItems: "center" }}>
					<Box
						sx={{
							flexGrow: 1,
							display: { xs: "none", md: "flex" },
							justifyContent: "center",
							alignItems: "center",
						}}>
						<Link to='/' style={{ textDecoration: "none" }}>
							<Button
								sx={{
									my: 2,
									color: "#A2A2A2",
									":hover": { color: "white" },
									display: "block",
									fontWeight: "bold",
								}}>
								Home
							</Button>
						</Link>
						<Link to='/AboutUs' style={{ textDecoration: "none" }}>
							<Button
								sx={{
									my: 2,
									color: "#A2A2A2",
									":hover": { color: "white" },
									display: "block",
									fontWeight: "bold",
								}}>
								About Us
							</Button>
						</Link>
						<Box sx={{ position: "relative", zIndex: 99 }} id='nestedTop'>
							<Box
								sx={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}>
								<Button
									sx={{
										my: 2,
										color: "#A2A2A2",
										":hover": { color: "white" },
										display: "block",
										fontWeight: "bold",
										pr: 0,
									}}>
									Our services
								</Button>
								<KeyboardArrowDownIcon sx={{ color: "#A2A2A2" }} />
							</Box>

							<Box
								id='nestedBottom'
								className='bgColor'
								sx={{
									position: "absolute",
									width: "max-content",
								}}>
								<Box sx={{ position: "relative" }} id='nestedTop2'>
									<Box
										sx={{
											display: "flex",
											justifyContent: "flex-start",
											alignItems: "center",
										}}>
										<Button
											sx={{
												my: 0.5,
												color: "#A2A2A2",
												":hover": { color: "white" },
												display: "block",
												fontWeight: "bold",
												textTransform: "none !important",
												pr: 0,
											}}>
											Chauffeur Services
										</Button>
										<KeyboardArrowRightIcon sx={{ color: "#A2A2A2" }} />
									</Box>
									<Box
										id='nestedBottom2'
										className='bgColor'
										sx={{
											position: "absolute",
											right: 3,
											top: 0,
											width: "max-content",
											zIndex: 99,
											border: "1px solid white",
										}}>
										<Link
											to={`/OurServices/Corporate`}
											style={{ textDecoration: "none" }}>
											<Button
												sx={{
													my: 0.5,
													color: "#A2A2A2",
													":hover": { color: "white" },
													display: "block",
													fontWeight: "bold",
													textTransform: "none !important",
												}}>
												Corporate
											</Button>
										</Link>
										<Link
											to={`/OurServices/Driver by Day`}
											style={{ textDecoration: "none" }}>
											<Button
												sx={{
													my: 0.5,
													color: "#A2A2A2",
													":hover": { color: "white" },
													display: "block",
													fontWeight: "bold",
													textTransform: "none !important",
												}}>
												Driver by Day
											</Button>
										</Link>
										<Link
											to={`/OurServices/Wedding`}
											style={{ textDecoration: "none" }}>
											<Button
												sx={{
													my: 0.5,
													color: "#A2A2A2",
													":hover": { color: "white" },
													display: "block",
													fontWeight: "bold",
													textTransform: "none !important",
												}}>
												Wedding
											</Button>
										</Link>
										<Link
											to={`/OurServices/Funeral`}
											style={{ textDecoration: "none" }}>
											<Button
												sx={{
													my: 0.5,
													color: "#A2A2A2",
													":hover": { color: "white" },
													display: "block",
													fontWeight: "bold",
													textTransform: "none !important",
												}}>
												Funeral
											</Button>
										</Link>
										<Link
											to={`/OurServices/Cruise`}
											style={{ textDecoration: "none" }}>
											<Button
												sx={{
													my: 0.5,
													color: "#A2A2A2",
													":hover": { color: "white" },
													display: "block",
													fontWeight: "bold",
													textTransform: "none !important",
												}}>
												Cruise
											</Button>
										</Link>
										<Link
											to={`/OurServices/Prom`}
											style={{ textDecoration: "none" }}>
											<Button
												sx={{
													my: 0.5,
													color: "#A2A2A2",
													":hover": { color: "white" },
													display: "block",
													fontWeight: "bold",
													textTransform: "none !important",
												}}>
												Prom
											</Button>
										</Link>
									</Box>
								</Box>

								<Link
									to={`/OurServices/Airport Pick-ups And Drop-off Service`}
									style={{ textDecoration: "none" }}>
									<Button
										sx={{
											my: 0.5,
											color: "#A2A2A2",
											":hover": { color: "white" },
											display: "block",
											fontWeight: "bold",
											textTransform: "none !important",
										}}>
										Airport Pick-ups And Drop-off Service
									</Button>
								</Link>
								<Link
									to={`/OurServices/Wheel Accessible`}
									style={{ textDecoration: "none" }}>
									<Button
										sx={{
											my: 0.5,
											color: "#A2A2A2",
											":hover": { color: "white" },
											display: "block",
											fontWeight: "bold",
											textTransform: "none !important",
										}}>
										Wheel Accessible
									</Button>
								</Link>
								<Link
									to={`/OurServices/Sightseeing Services`}
									style={{ textDecoration: "none" }}>
									<Button
										sx={{
											my: 0.5,
											color: "#A2A2A2",
											":hover": { color: "white" },
											display: "block",
											fontWeight: "bold",
											textTransform: "none !important",
										}}>
										Sightseeing Services
									</Button>
								</Link>
								<Link
									to={`/OurServices/Express Parcel Delivery`}
									style={{ textDecoration: "none" }}>
									<Button
										sx={{
											my: 0.5,
											color: "#A2A2A2",
											":hover": { color: "white" },
											display: "block",
											fontWeight: "bold",
											textTransform: "none !important",
										}}>
										Express Parcel Delivery
									</Button>
								</Link>
							</Box>
						</Box>
						<Box sx={{ position: "relative", zIndex: 99 }} id='nestedTop'>
							<Box
								sx={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}>
								<Button
									sx={{
										my: 2,
										color: "#A2A2A2",
										":hover": { color: "white" },
										display: "block",
										fontWeight: "bold",
										pr: 0,
									}}>
									Fleet
								</Button>
								<KeyboardArrowDownIcon sx={{ color: "#A2A2A2" }} />
							</Box>

							<Box
								id='nestedBottom'
								className='bgColor'
								sx={{
									position: "absolute",
									width: "max-content",
								}}>
								<Link
									to={`/Cars/Wheel Chair Accessible`}
									style={{ textDecoration: "none" }}>
									<Button
										sx={{
											my: 0.5,
											color: "#A2A2A2",
											":hover": { color: "white" },
											display: "block",
											fontWeight: "bold",
											textTransform: "none !important",
										}}>
										Wheel Chair Accessible
									</Button>
								</Link>
								<Link to={`/Cars/Pacifica`} style={{ textDecoration: "none" }}>
									<Button
										sx={{
											my: 0.5,
											color: "#A2A2A2",
											":hover": { color: "white" },
											display: "block",
											fontWeight: "bold",
											textTransform: "none !important",
										}}>
										Pacifica
									</Button>
								</Link>
								<Link
									to={`/Cars/Sprinter Minivan`}
									style={{ textDecoration: "none" }}>
									<Button
										sx={{
											my: 0.5,
											color: "#A2A2A2",
											":hover": { color: "white" },
											display: "block",
											fontWeight: "bold",
											textTransform: "none !important",
										}}>
										Sprinter Minivan
									</Button>
								</Link>
								<Link to={`/Cars/Ford SUV`} style={{ textDecoration: "none" }}>
									<Button
										sx={{
											my: 0.5,
											color: "#A2A2A2",
											":hover": { color: "white" },
											display: "block",
											fontWeight: "bold",
											textTransform: "none !important",
										}}>
										Ford SUV
									</Button>
								</Link>
								<Link to={`/Cars/Yukun`} style={{ textDecoration: "none" }}>
									<Button
										sx={{
											my: 0.5,
											color: "#A2A2A2",
											":hover": { color: "white" },
											display: "block",
											fontWeight: "bold",
											textTransform: "none !important",
										}}>
										Yukun
									</Button>
								</Link>
							</Box>
						</Box>
						<Link to='/ContactUs' style={{ textDecoration: "none" }}>
							<Button
								sx={{
									my: 2,
									color: "#A2A2A2",
									":hover": { color: "white" },
									display: "block",
									fontWeight: "bold",
								}}>
								Contact Us
							</Button>
						</Link>
						<Link to='/startBooking' style={{ textDecoration: "none" }}>
							<Button
								variant='contained'
								disableElevation
								sx={{
									backgroundColor: "#E5E5E5",
									color: "#292929",
									fontWeight: "bold",
									"&:hover": {
										backgroundColor: "#E5E5E5",
									},
									height: 50,
									borderRadius: 15,
								}}>
								Book Now
							</Button>
						</Link>{" "}
						{loggedInUser && (
							<Button
								onClick={() => {
									const role = loggedInUser?.userRole;
									if (role === "Admin" || role === "Developer") {
										window.location.href = "/dashboard";
									} else if (role === "User") {
										window.location.href = "/userDashboard";
									} else if (role === "Driver") {
										window.location.href = "/driverDashboard";
									}
								}}
								sx={{
									my: 2,
									color: "#A2A2A2",
									":hover": { color: "white" },
									display: "block",
									fontWeight: "bold",
								}}>
								Profile
							</Button>
						)}
						{loggedInUser && (
							<>
								<Button
									variant='contained'
									disableElevation
									sx={{
										backgroundColor: "#a93939",
										color: "white",
										fontWeight: "bold",
										"&:hover": {
											backgroundColor: "#a93939",
										},
										height: 40,
										borderRadius: 15,
										mx: 1,
									}}
									onClick={() => {
										Cookies.remove("user");
										window.location.reload();
									}}>
									LogOut
								</Button>
							</>
						)}
					</Box>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
						}}>
						<Tooltip
							title='Open settings'
							sx={{ display: { xs: "flex", md: "none" } }}>
							<IconButton
								size='large'
								aria-label='account of current user'
								aria-controls='menu-appbar'
								aria-haspopup='true'
								onClick={handleOpenNavMenu}
								color='inherit'>
								<MenuIcon
									sx={{ color: "#A2A2A2", ":hover": { color: "white" } }}
								/>
							</IconButton>
						</Tooltip>
						{anchorElNav && (
							<Box
								sx={{
									flexGrow: 1,
									display: { md: "none", xs: "flex" },
									flexDirection: "column",
									justifyContent: "center",
									alignItems: "center",
								}}>
								<Link to='/' style={{ textDecoration: "none" }}>
									<Button
										sx={{
											color: "#A2A2A2",
											":hover": { color: "white" },
											display: "block",
											fontWeight: "bold",
										}}>
										Home
									</Button>
								</Link>
								<Link to='/AboutUs' style={{ textDecoration: "none" }}>
									<Button
										sx={{
											color: "#A2A2A2",
											":hover": { color: "white" },
											display: "block",
											fontWeight: "bold",
										}}>
										About Us
									</Button>
								</Link>
								<Box sx={{ position: "relative", zIndex: 999 }} id='nestedTop'>
									<Box
										sx={{
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
										}}>
										<Button
											sx={{
												color: "#A2A2A2",
												":hover": { color: "white" },
												display: "block",
												fontWeight: "bold",
												pr: 0,
											}}>
											Our services
										</Button>
										<KeyboardArrowDownIcon sx={{ color: "#A2A2A2" }} />
									</Box>

									<Box
										id='nestedBottom'
										className='bgColor'
										sx={{
											position: "absolute",
											width: "max-content",
										}}>
										<Box sx={{ position: "relative" }} id='nestedTop2'>
											<Box
												sx={{
													display: "flex",
													justifyContent: "flex-start",
													alignItems: "center",
												}}>
												<Button
													sx={{
														my: 0.5,
														color: "#A2A2A2",
														":hover": { color: "white" },
														display: "block",
														fontWeight: "bold",
														textTransform: "none !important",
														pr: 0,
													}}>
													Chauffeur Services
												</Button>
												<KeyboardArrowRightIcon sx={{ color: "#A2A2A2" }} />
											</Box>
											<Box
												id='nestedBottom2'
												className='bgColor'
												sx={{
													position: "absolute",
													right: 50,
													top: 0,
													width: "max-content",
													zIndex: 99,
													border: "1px solid white",
												}}>
												<Link
													to={`/OurServices/Corporate`}
													style={{ textDecoration: "none" }}>
													<Button
														sx={{
															my: 0.5,
															color: "#A2A2A2",
															":hover": { color: "white" },
															display: "block",
															fontWeight: "bold",
															textTransform: "none !important",
														}}>
														Corporate
													</Button>
												</Link>
												<Link
													to={`/OurServices/Driver by Day`}
													style={{ textDecoration: "none" }}>
													<Button
														sx={{
															my: 0.5,
															color: "#A2A2A2",
															":hover": { color: "white" },
															display: "block",
															fontWeight: "bold",
															textTransform: "none !important",
														}}>
														Driver by Day
													</Button>
												</Link>
												<Link
													to={`/OurServices/Wedding`}
													style={{ textDecoration: "none" }}>
													<Button
														sx={{
															my: 0.5,
															color: "#A2A2A2",
															":hover": { color: "white" },
															display: "block",
															fontWeight: "bold",
															textTransform: "none !important",
														}}>
														Wedding
													</Button>
												</Link>
												<Link
													to={`/OurServices/Funeral`}
													style={{ textDecoration: "none" }}>
													<Button
														sx={{
															my: 0.5,
															color: "#A2A2A2",
															":hover": { color: "white" },
															display: "block",
															fontWeight: "bold",
															textTransform: "none !important",
														}}>
														Funeral
													</Button>
												</Link>
												<Link
													to={`/OurServices/Cruise`}
													style={{ textDecoration: "none" }}>
													<Button
														sx={{
															my: 0.5,
															color: "#A2A2A2",
															":hover": { color: "white" },
															display: "block",
															fontWeight: "bold",
															textTransform: "none !important",
														}}>
														Cruise
													</Button>
												</Link>
												<Link
													to={`/OurServices/Prom`}
													style={{ textDecoration: "none" }}>
													<Button
														sx={{
															my: 0.5,
															color: "#A2A2A2",
															":hover": { color: "white" },
															display: "block",
															fontWeight: "bold",
															textTransform: "none !important",
														}}>
														Prom
													</Button>
												</Link>
											</Box>
										</Box>

										<Link
											to={`/OurServices/Airport Pick-ups And Drop-off Service`}
											style={{ textDecoration: "none" }}>
											<Button
												sx={{
													my: 0.5,
													color: "#A2A2A2",
													":hover": { color: "white" },
													display: "block",
													fontWeight: "bold",
													textTransform: "none !important",
												}}>
												Airport Pick-ups And Drop-off Service
											</Button>
										</Link>
										<Link
											to={`/OurServices/Wheel Accessible`}
											style={{ textDecoration: "none" }}>
											<Button
												sx={{
													my: 0.5,
													color: "#A2A2A2",
													":hover": { color: "white" },
													display: "block",
													fontWeight: "bold",
													textTransform: "none !important",
												}}>
												Wheel Accessible
											</Button>
										</Link>
										<Link
											to={`/OurServices/Sightseeing Services`}
											style={{ textDecoration: "none" }}>
											<Button
												sx={{
													my: 0.5,
													color: "#A2A2A2",
													":hover": { color: "white" },
													display: "block",
													fontWeight: "bold",
													textTransform: "none !important",
												}}>
												Sightseeing Services
											</Button>
										</Link>
										<Link
											to={`/OurServices/Express Parcel Delivery`}
											style={{ textDecoration: "none" }}>
											<Button
												sx={{
													my: 0.5,
													color: "#A2A2A2",
													":hover": { color: "white" },
													display: "block",
													fontWeight: "bold",
													textTransform: "none !important",
												}}>
												Express Parcel Delivery
											</Button>
										</Link>
									</Box>
								</Box>
								<Box sx={{ position: "relative", zIndex: 99 }} id='nestedTop'>
									<Box
										sx={{
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
										}}>
										<Button
											sx={{
												color: "#A2A2A2",
												":hover": { color: "white" },
												display: "block",
												fontWeight: "bold",
												pr: 0,
											}}>
											Fleet
										</Button>
										<KeyboardArrowDownIcon sx={{ color: "#A2A2A2" }} />
									</Box>

									<Box
										id='nestedBottom'
										className='bgColor'
										sx={{
											position: "absolute",
											width: "max-content",
										}}>
										<Link
											to={`/Cars/Wheel Chair Accessible`}
											style={{ textDecoration: "none" }}>
											<Button
												sx={{
													my: 0.5,
													color: "#A2A2A2",
													":hover": { color: "white" },
													display: "block",
													fontWeight: "bold",
													textTransform: "none !important",
												}}>
												Wheel Chair Accessible
											</Button>
										</Link>
										<Link
											to={`/Cars/Pacifica`}
											style={{ textDecoration: "none" }}>
											<Button
												sx={{
													my: 0.5,
													color: "#A2A2A2",
													":hover": { color: "white" },
													display: "block",
													fontWeight: "bold",
													textTransform: "none !important",
												}}>
												Pacifica
											</Button>
										</Link>
										<Link
											to={`/Cars/Sprinter Minivan`}
											style={{ textDecoration: "none" }}>
											<Button
												sx={{
													my: 0.5,
													color: "#A2A2A2",
													":hover": { color: "white" },
													display: "block",
													fontWeight: "bold",
													textTransform: "none !important",
												}}>
												Sprinter Minivan
											</Button>
										</Link>
										<Link
											to={`/Cars/Ford SUV`}
											style={{ textDecoration: "none" }}>
											<Button
												sx={{
													my: 0.5,
													color: "#A2A2A2",
													":hover": { color: "white" },
													display: "block",
													fontWeight: "bold",
													textTransform: "none !important",
												}}>
												Ford SUV
											</Button>
										</Link>
										<Link to={`/Cars/Yukun`} style={{ textDecoration: "none" }}>
											<Button
												sx={{
													my: 0.5,
													color: "#A2A2A2",
													":hover": { color: "white" },
													display: "block",
													fontWeight: "bold",
													textTransform: "none !important",
												}}>
												Yukun
											</Button>
										</Link>
									</Box>
								</Box>
								<Link to='/ContactUs' style={{ textDecoration: "none" }}>
									<Button
										sx={{
											color: "#A2A2A2",
											":hover": { color: "white" },
											display: "block",
											fontWeight: "bold",
										}}>
										Contact Us
									</Button>
								</Link>
								<Link to='/startBooking' style={{ textDecoration: "none" }}>
									{" "}
									<Button
										variant='contained'
										disableElevation
										sx={{
											backgroundColor: "#E5E5E5",
											color: "#292929",
											fontWeight: "bold",
											"&:hover": {
												backgroundColor: "#E5E5E5",
											},
											height: 50,
											borderRadius: 15,
											mb: 2,
										}}>
										Book Now
									</Button>
								</Link>
								{loggedInUser && (
									<Button
										onClick={() => {
											const role = loggedInUser?.userRole;
											if (role === "Admin" || role === "Developer") {
												window.location.href = "/dashboard";
											} else if (role === "User") {
												window.location.href = "/userDashboard";
											} else if (role === "Driver") {
												window.location.href = "/driverDashboard";
											}
										}}
										sx={{
											my: 2,
											color: "#A2A2A2",
											":hover": { color: "white" },
											display: "block",
											fontWeight: "bold",
										}}>
										Profile
									</Button>
								)}
								{loggedInUser && (
									<Button
										variant='contained'
										disableElevation
										sx={{
											backgroundColor: "#a93939",
											color: "white",
											fontWeight: "bold",
											"&:hover": {
												backgroundColor: "#a93939",
											},
											height: 40,
											borderRadius: 15,
											mx: 1,
										}}
										onClick={() => {
											Cookies.remove("user");
											window.location.reload();
										}}>
										LogOut
									</Button>
								)}
							</Box>
						)}
					</Box>
				</Toolbar>
			</Box>
			<ScrollTop {...props}>
				<Fab size='small' aria-label='scroll back to top'>
					<KeyboardArrowUpIcon />
				</Fab>
			</ScrollTop>
		</React.Fragment>
	);
}
