import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Link, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import axios from "axios";
import Cookies from "js-cookie";
import { Backdrop, CircularProgress } from "@mui/material";

export default function Login() {
	const [submitting, setSubmitting] = React.useState(false);
	const { register, handleSubmit } = useForm();
	const location = useLocation();

	const onSubmit = async (data) => {
		setSubmitting(true);
		try {
			const response = await axios.post(
				`${process.env.REACT_APP_API_PATH}/login`,
				data,
			);

			if (response.status === 201) {
				Swal.fire({
					icon: "success",
					title: "Success",
					text: "Logged in successfully.",
				});
				Cookies.set("user", JSON.stringify(response.data));
				const role = response?.data?.userRole;
				if (role === "Admin" || role === "Developer") {
					window.location.href =
						location.state?.from?.pathname === "/startBooking"
							? location.state?.from?.pathname
							: "/dashboard";
				} else if (role === "User") {
					window.location.href =
						location.state?.from?.pathname === "/startBooking"
							? location.state?.from?.pathname
							: "/userDashboard";
				} else if (role === "Driver") {
					window.location.href =
						location.state?.from?.pathname === "/startBooking"
							? location.state?.from?.pathname
							: "/driverDashboard";
				} else {
					window.location.href =
						location.state?.from?.pathname === "/startBooking"
							? location.state?.from?.pathname
							: "/";
				}
			}
			setSubmitting(false);
		} catch (error) {
			Swal.fire({
				icon: "error",
				title: "Error",
				text: error.response.data.message,
			});
			setSubmitting(false);
		}
	};
	return (
		<Container
			component='main'
			maxWidth='xs'
			sx={{
				minHeight: "50vh",
			}}>
			<CssBaseline />
			<Box
				sx={{
					marginTop: 8,
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
				}}>
				<Avatar sx={{ m: 1 }} className='buttonColor'>
					<LockOutlinedIcon />
				</Avatar>
				<Typography component='h1' variant='h5'>
					Sign in
				</Typography>
				<form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: 3 }}>
					<TextField
						margin='normal'
						required
						fullWidth
						id='email'
						label='Email Address'
						name='email'
						autoComplete='email'
						autoFocus
						{...register("email", { required: true })}
					/>

					<Link to='/ResetPassEmail' variant='body2'>
						Forgot password?
					</Link>
					<TextField
						margin='normal'
						required
						fullWidth
						name='password'
						label='Password'
						type='password'
						id='password'
						autoComplete='current-password'
						{...register("password", { required: true })}
					/>

					<Link to='/Signup' variant='body2'>
						Don't have an account? Sign Up
					</Link>
					<Button
						type='submit'
						fullWidth
						variant='contained'
						className='buttonColor'
						sx={{
							my: 2,
						}}>
						Sign In
					</Button>
				</form>
			</Box>
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={submitting}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</Container>
	);
}
