const images = {
	fordSub1: require("./Files/ford-suv/ford-suv-1.png"),
	fordSub2: require("./Files/ford-suv/ford-suv-2.png"),
	fordSub3: require("./Files/ford-suv/ford-suv-3.png"),
	fordSub4: require("./Files/ford-suv/ford-suv-4.png"),
	pecifica1: require("./Files/pecifica/pecifica-1.png"),
	pecifica2: require("./Files/pecifica/pecifica-2.png"),
	pecifica3: require("./Files/pecifica/pecifica-3.png"),
	pecifica4: require("./Files/pecifica/pecifica-4.png"),
	sprinterMinivan1: require("./Files/sprinter-minivan/sprinter-minivan-1.png"),
	sprinterMinivan2: require("./Files/sprinter-minivan/sprinter-minivan-2.png"),
	sprinterMinivan3: require("./Files/sprinter-minivan/sprinter-minivan-3.png"),
	sprinterMinivan4: require("./Files/sprinter-minivan/sprinter-minivan-4.png"),
	sprinterMinivan5: require("./Files/sprinter-minivan/sprinter-minivan-5.png"),
	sprinterMinivan6: require("./Files/sprinter-minivan/sprinter-minivan-6.png"),
	sprinterMinivan7: require("./Files/sprinter-minivan/sprinter-minivan-7.png"),
	wheelChairAcc1: require("./Files/wheel-chair-acc/wheel-chair-acc-1.png"),
	wheelChairAcc2: require("./Files/wheel-chair-acc/wheel-chair-acc-2.png"),
	wheelChairAcc3: require("./Files/wheel-chair-acc/wheel-chair-acc-3.png"),
	yukun1: require("./Files/yukun/yukun-1.png"),
	yukun2: require("./Files/yukun/yukun-2.png"),
	yukun3: require("./Files/yukun/yukun-3.png"),
	casinoWindsor: require("./Files/casino-windsor-1.png"),
	detroitDownTown: require("./Files/detroit-down-town-1.png"),
	detroitRiver: require("./Files/detroit-river-1.png"),
	logoBottom: require("./Files/logo-bottom.png"),
	logoTop: require("./Files/logo-top.png"),
	carIcon: require("./Files/car-icon.png"),
	calender: require("./Files/calender1.png"),
	starIcon: require("./Files/star-icon.png"),
	visaIcon: require("./Files/visa-icon.png"),
	visaMastercard: require("./Files/visa-mastercard.png"),
	airport: require("./Files/services/airport.png"),
	corporate: require("./Files/services/corporate.png"),
	corporateShufer: require("./Files/services/corporate-shufer.png"),
	cruise: require("./Files/services/cruise.png"),
	daydriver: require("./Files/services/daydriver.png"),
	funeral: require("./Files/services/funeral.png"),
	marriage: require("./Files/services/marriage.png"),
	parcelDelivery: require("./Files/services/parcel-delivery.png"),
	prom: require("./Files/services/prom.png"),
	sightseeingServices: require("./Files/services/sightseeing-services.png"),
	wheelAccessible: require("./Files/services/wheel-accessible.png"),
	weeding: require("./Files/weeding.png"),
	detroit: require("./Files/detroit.png"),
	metro: require("./Files/metro.png"),
	prom2: require("./Files/prom.png"),
};
export default images;
