import React, { useEffect, useState } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { Dialog, TextField } from "@mui/material";
import { reactLocalStorage } from "reactjs-localstorage";

export default function RideDetails({ setStep }) {
	const [rides, setRides] = useState([]);
	console.log("🚀 ~ RideDetails ~ rides:", rides);

	useEffect(() => {
		const data = reactLocalStorage.getObject("rideDetails") || [];
		if (data?.length > 0) {
			setRides(data);
		}
	}, []);

	const initialRideState = {
		time: "",
		duration: "",
		transferType: "",
		picupLocation: "",
		dropOffLocation: "",
		airport: "",
		airline: "",
		flightNo: "",
		terminal: "",
		flightTime: "",
		flightStatus: "",
		origin: "",
	};

	const [currentRide, setCurrentRide] = useState(initialRideState);
	const [editRow, setEditRow] = useState(null);
	const [rideType, setRideType] = useState("");
	const [dateTime, setDateTime] = useState(new Date()?.toLocaleString());

	useEffect(() => {
		if (rides?.length > 0 && editRow !== null) {
			const rideDetails = rides.find((r) => r.rideId === editRow);
			if (rideDetails) {
				setCurrentRide(rideDetails);
				setRideType(rideDetails.rideType);
			}
		}
	}, [rides, editRow]);

	const handleChange = (field) => (e) => {
		const value = e.target.value;
		setCurrentRide((prev) => ({
			...prev,
			[field]: value,
		}));
	};

	const [open, setOpen] = React.useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const onSubmit = (e) => {
		e.preventDefault();
		let data;
		switch (rideType) {
			case "Distance":
				data = {
					time1: new Date(dateTime).toLocaleString(),
					picupLocation1: currentRide.picupLocation,
					dropOffLocation1: currentRide.dropOffLocation,
					extraTime1: currentRide.extraTime,
					rideType: "Distance",
					rideId: Math.round(Math.random() * 10000),
					paidPrice: 0,
					paid: false,
					tab: 0,
				};
				break;

			case "Hourly":
				data = {
					time2: new Date(dateTime).toLocaleString(),
					picupLocation2: currentRide.picupLocation,
					dropOffLocation2: currentRide.dropOffLocation,
					duration2: currentRide.duration,
					rideType: "Hourly",
					rideId: Math.round(Math.random() * 10000),
					paidPrice: 0,
					paid: false,
					tab: 1,
				};
				break;

			case "Flat Rate":
				data = {
					time3: new Date(dateTime).toLocaleString(),
					transferType3: currentRide.transferType,
					duration3: currentRide.duration,
					picupLocation3: currentRide.picupLocation,
					rideType: "Flat Rate",
					rideId: Math.round(Math.random() * 10000),
					paidPrice: 0,
					paid: false,
					tab: 2,
				};
				break;

			case "Chauffeur Services":
				data = {
					time4: new Date(dateTime).toLocaleString(),
					transferType4: currentRide.transferType,
					duration4: currentRide.duration,
					picupLocation4: currentRide.picupLocation,
					rideType: "Chauffeur Services",
					rideId: Math.round(Math.random() * 10000),
					paidPrice: 0,
					paid: false,
					tab: 3,
				};
				break;

			case "Airport Service":
				data = {
					time5: new Date(dateTime).toLocaleString(),
					picupLocation5: currentRide.picupLocation,
					airport: currentRide.airport,
					airline: currentRide.airline,
					flightNo: currentRide.flightNo,
					terminal: currentRide.terminal,
					flightTime: currentRide.flightTime,
					flightStatus: currentRide.flightStatus,
					origin: currentRide.origin,
					rideType: "Airport Service",
					rideId: Math.round(Math.random() * 10000),
					paidPrice: 0,
					paid: false,
					tab: 4,
				};
				break;

			default:
				return;
		}

		if (editRow !== null) {
			setRides((prev) => prev.map((r) => (r.rideId === editRow ? data : r)));
			setEditRow(null);
		} else {
			setRides((prev) => [...prev, data]);
		}

		setCurrentRide(initialRideState);
		setRideType("");
		handleClose();
	};

	return (
		<div>
			<div
				style={{
					padding: "10px",
				}}>
				<button
					onClick={handleClickOpen}
					style={{
						padding: "10px",
						backgroundColor: "black",
						color: "white",
						border: "none",
						borderRadius: "4px",
						cursor: "pointer",
					}}>
					Add Ride
				</button>
				<Dialog
					open={open}
					onClose={handleClose}
					aria-labelledby='alert-dialog-title'
					aria-describedby='alert-dialog-description'
					sx={{
						"& .MuiDialog-paper": {
							width: "100%",
							maxWidth: "350px",
							borderRadius: "8px",
						},
					}}>
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<form
							onSubmit={onSubmit}
							style={{
								padding: "10px",
								border: "1px solid #ccc",
								borderRadius: "8px",
								boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
							}}>
							<h2
								style={{
									textAlign: "center",
									fontSize: "16px",
									marginBottom: "10px",
								}}>
								{editRow ? "Edit Ride" : "Add New Ride"}
							</h2>

							<div
								style={{
									display: "flex",
									alignItems: "center",
									marginBottom: "5px",
								}}>
								<label style={{ width: "100px", fontSize: "14px" }}>
									RIDE TYPE
								</label>
								<select
									value={rideType}
									onChange={(e) => setRideType(e.target.value)}
									required
									style={{
										flex: "1",
										padding: "4px",
										borderRadius: "4px",
										border: "1px solid #ccc",
										fontSize: "12px",
									}}>
									<option value=''>Select</option>
									<option value='Distance'>Distance</option>
									<option value='Hourly'>Hourly</option>
									<option value='Flat Rate'>Flat Rate</option>
									<option value='Chauffeur Services'>Chauffeur Services</option>
									<option value='Airport Service'>Airport Service</option>
								</select>
							</div>

							<div
								style={{
									display: "flex",
									alignItems: "center",
									marginBottom: "5px",
								}}>
								<label style={{ width: "100px", fontSize: "14px" }}>
									PICKUP DATE
								</label>
								<DesktopDatePicker
									inputFormat='MM/dd/yyyy'
									value={currentRide.time}
									onChange={(date) => {
										setDateTime(date);
										setCurrentRide((prev) => ({
											...prev,
											time: date,
										}));
									}}
									renderInput={(params) => (
										<TextField
											sx={{
												"& .MuiInputBase-root input": {
													py: 0.3,
												},
											}}
											required
											fullWidth
											{...params}
										/>
									)}
								/>
							</div>

							<div
								style={{
									display: "flex",
									alignItems: "center",
									marginBottom: "5px",
								}}>
								<label style={{ width: "100px", fontSize: "14px" }}>
									PICKUP TIME
								</label>
								<TimePicker
									ampm={false}
									value={currentRide.time}
									onChange={(time) => {
										setDateTime(time);
										setCurrentRide((prev) => ({
											...prev,
											time,
										}));
									}}
									renderInput={(params) => (
										<TextField
											sx={{
												"& .MuiInputBase-root input": {
													py: 0.3,
												},
											}}
											required
											fullWidth
											{...params}
										/>
									)}
								/>
							</div>

							<div
								style={{
									display: "flex",
									alignItems: "center",
									marginBottom: "5px",
								}}>
								<label style={{ width: "100px", fontSize: "14px" }}>
									PICKUP LOC.
								</label>
								<input
									type='text'
									placeholder='LOCATION'
									value={currentRide.picupLocation}
									onChange={handleChange("picupLocation")}
									required
									style={{
										flex: "1",
										padding: "4px",
										borderRadius: "4px",
										border: "1px solid #ccc",
										fontSize: "12px",
									}}
								/>
							</div>

							<div
								style={{
									display: "flex",
									alignItems: "center",
									marginBottom: "5px",
								}}>
								<label style={{ width: "100px", fontSize: "14px" }}>
									DROP-OFF LOC.
								</label>
								<input
									type='text'
									placeholder='DROP-OFF LOCATION'
									value={currentRide.dropOffLocation}
									onChange={handleChange("dropOffLocation")}
									required
									style={{
										flex: "1",
										padding: "4px",
										borderRadius: "4px",
										border: "1px solid #ccc",
										fontSize: "12px",
									}}
								/>
							</div>
							{rideType === "Distance" && (
								<div
									style={{
										display: "flex",
										alignItems: "center",
										marginBottom: "5px",
									}}>
									<label style={{ width: "100px", fontSize: "14px" }}>
										EXTRA TIME
									</label>
									<select
										value={currentRide.extraTime}
										onChange={handleChange("extraTime")}
										required
										style={{
											flex: "1",
											padding: "4px",
											borderRadius: "4px",
											border: "1px solid #ccc",
											fontSize: "12px",
										}}>
										<option value=''>Select</option>
										<option value='1 HOUR'>1</option>
										<option value='2 HOUR'>2</option>
										<option value='3 HOUR'>3</option>
										<option value='4 HOUR'>4</option>
										<option value='5 HOUR'>5</option>
									</select>
								</div>
							)}

							{rideType === "Hourly" && (
								<div
									style={{
										display: "flex",
										alignItems: "center",
										marginBottom: "5px",
									}}>
									<label style={{ width: "100px", fontSize: "14px" }}>
										DURATION
									</label>
									<select
										value={currentRide.duration}
										onChange={handleChange("duration")}
										required
										style={{
											flex: "1",
											padding: "4px",
											borderRadius: "4px",
											border: "1px solid #ccc",
											fontSize: "12px",
										}}>
										<option value=''>Select</option>
										<option value='1 HOUR'>1</option>
										<option value='2 HOUR'>2</option>
										<option value='3 HOUR'>3</option>
										<option value='4 HOUR'>4</option>
										<option value='5 HOUR'>5</option>
									</select>
								</div>
							)}

							{rideType === "Flat Rate" && (
								<>
									<div
										style={{
											display: "flex",
											alignItems: "center",
											marginBottom: "5px",
										}}>
										<label style={{ width: "100px", fontSize: "14px" }}>
											SERVICE TYPE
										</label>
										<select
											value={currentRide.transferType}
											onChange={handleChange("transferType")}
											required
											style={{
												flex: "1",
												padding: "4px",
												borderRadius: "4px",
												border: "1px solid #ccc",
												fontSize: "12px",
											}}>
											<option value=''>Select</option>
											<option value={"Corporate"}>Corporate</option>
											<option value={"Driver by Day"}>Driver by Day</option>
											<option value={"Wedding"}>Wedding</option>
											<option value={"Funeral"}>Funeral</option>
											<option value={"Cruise"}>Cruise</option>
											<option value={"Prom"}>Prom</option>
										</select>
									</div>
									<div
										style={{
											display: "flex",
											alignItems: "center",
											marginBottom: "5px",
										}}>
										<label style={{ width: "100px", fontSize: "14px" }}>
											DURATION
										</label>
										<select
											value={currentRide.duration}
											onChange={handleChange("duration")}
											required
											style={{
												flex: "1",
												padding: "4px",
												borderRadius: "4px",
												border: "1px solid #ccc",
												fontSize: "12px",
											}}>
											<option value=''>Select</option>
											<option value='1 HOUR'>1</option>
											<option value='2 HOUR'>2</option>
											<option value='3 HOUR'>3</option>
											<option value='4 HOUR'>4</option>
											<option value='5 HOUR'>5</option>
										</select>
									</div>
								</>
							)}

							{rideType === "Chauffeur Services" && (
								<>
									<div
										style={{
											display: "flex",
											alignItems: "center",
											marginBottom: "5px",
										}}>
										<label style={{ width: "100px", fontSize: "14px" }}>
											SERVICE TYPE
										</label>
										<select
											value={currentRide.transferType}
											onChange={handleChange("transferType")}
											required
											style={{
												flex: "1",
												padding: "4px",
												borderRadius: "4px",
												border: "1px solid #ccc",
												fontSize: "12px",
											}}>
											<option value=''>Select</option>
											<option value={"Corporate"}>Corporate</option>
											<option value={"Driver by Day"}>Driver by Day</option>
											<option value={"Wedding"}>Wedding</option>
											<option value={"Funeral"}>Funeral</option>
											<option value={"Cruise"}>Cruise</option>
											<option value={"Prom"}>Prom</option>
										</select>
									</div>
									<div
										style={{
											display: "flex",
											alignItems: "center",
											marginBottom: "5px",
										}}>
										<label style={{ width: "100px", fontSize: "14px" }}>
											DURATION
										</label>
										<select
											value={currentRide.duration}
											onChange={handleChange("duration")}
											required
											style={{
												flex: "1",
												padding: "4px",
												borderRadius: "4px",
												border: "1px solid #ccc",
												fontSize: "12px",
											}}>
											<option value=''>Select</option>
											<option value='1 HOUR'>1</option>
											<option value='2 HOUR'>2</option>
											<option value='3 HOUR'>3</option>
											<option value='4 HOUR'>4</option>
											<option value='5 HOUR'>5</option>
										</select>
									</div>
								</>
							)}

							{rideType === "Airport Service" && (
								<>
									<div
										style={{
											display: "flex",
											alignItems: "center",
											marginBottom: "5px",
										}}>
										<label style={{ width: "100px", fontSize: "14px" }}>
											AIRPORT
										</label>
										<input
											type='text'
											placeholder='AIRPORT'
											value={currentRide.airport}
											onChange={handleChange("airport")}
											required
											style={{
												flex: "1",
												padding: "4px",
												borderRadius: "4px",
												border: "1px solid #ccc",
												fontSize: "12px",
											}}
										/>
									</div>

									<div
										style={{
											display: "flex",
											alignItems: "center",
											marginBottom: "5px",
										}}>
										<label style={{ width: "100px", fontSize: "14px" }}>
											AIRLINE
										</label>
										<input
											type='text'
											placeholder='AIRLINE'
											value={currentRide.airline}
											onChange={handleChange("airline")}
											required
											style={{
												flex: "1",
												padding: "4px",
												borderRadius: "4px",
												border: "1px solid #ccc",
												fontSize: "12px",
											}}
										/>
									</div>

									<div
										style={{
											display: "flex",
											alignItems: "center",
											marginBottom: "5px",
										}}>
										<label style={{ width: "100px", fontSize: "14px" }}>
											FLIGHT NO
										</label>
										<input
											type='text'
											placeholder='FLIGHT NO'
											value={currentRide.flightNo}
											onChange={handleChange("flightNo")}
											required
											style={{
												flex: "1",
												padding: "4px",
												borderRadius: "4px",
												border: "1px solid #ccc",
												fontSize: "12px",
											}}
										/>
									</div>

									<div
										style={{
											display: "flex",
											alignItems: "center",
											marginBottom: "5px",
										}}>
										<label style={{ width: "100px", fontSize: "14px" }}>
											TERMINAL
										</label>
										<input
											type='text'
											placeholder='TERMINAL'
											value={currentRide.terminal}
											onChange={handleChange("terminal")}
											required
											style={{
												flex: "1",
												padding: "4px",
												borderRadius: "4px",
												border: "1px solid #ccc",
												fontSize: "12px",
											}}
										/>
									</div>

									<div
										style={{
											display: "flex",
											alignItems: "center",
											marginBottom: "5px",
										}}>
										<label style={{ width: "100px", fontSize: "14px" }}>
											FLIGHT TIME
										</label>
										<input
											type='text'
											placeholder='FLIGHT TIME. Ex: 05:20 pm'
											value={currentRide.flightTime}
											onChange={handleChange("flightTime")}
											required
											style={{
												flex: "1",
												padding: "4px",
												borderRadius: "4px",
												border: "1px solid #ccc",
												fontSize: "12px",
											}}
										/>
									</div>

									<div
										style={{
											display: "flex",
											alignItems: "center",
											marginBottom: "5px",
										}}>
										<label style={{ width: "100px", fontSize: "14px" }}>
											FLIGHT STATUS
										</label>
										<input
											type='text'
											placeholder='FLIGHT STATUS'
											value={currentRide.flightStatus}
											onChange={handleChange("flightStatus")}
											required
											style={{
												flex: "1",
												padding: "4px",
												borderRadius: "4px",
												border: "1px solid #ccc",
												fontSize: "12px",
											}}
										/>
									</div>

									<div
										style={{
											display: "flex",
											alignItems: "center",
											marginBottom: "5px",
										}}>
										<label style={{ width: "100px", fontSize: "14px" }}>
											ORIGIN
										</label>
										<input
											type='text'
											placeholder='ORIGIN'
											value={currentRide.origin}
											onChange={handleChange("origin")}
											required
											style={{
												flex: "1",
												padding: "4px",
												borderRadius: "4px",
												border: "1px solid #ccc",
												fontSize: "12px",
											}}
										/>
									</div>
								</>
							)}

							<button
								type='submit'
								style={{
									backgroundColor: "red",
									color: "white",
									padding: "6px",
									border: "none",
									borderRadius: "4px",
									width: "100%",
									cursor: "pointer",
									fontSize: "14px",
								}}>
								{editRow ? "Update Ride" : "Add Ride"}
							</button>
						</form>
					</LocalizationProvider>
				</Dialog>
				<div
					style={{
						marginBottom: "50px",
					}}>
					<RidesTable rides={rides} setRides={setRides} setStep={setStep} />
				</div>
				<div
					style={{
						marginTop: "10px",
						position: "fixed",
						bottom: 0,
						left: "50%",
						transform: "translateX(-50%)",
						width: "100%",
						padding: "10px",
						backgroundColor: "#80808078",
						borderTop: "3px solid #80808078",
					}}>
					<div
						style={{
							maxWidth: "900px",
							margin: "0 auto",
							display: "flex",
							justifyContent: "space-between",
						}}>
						<button
							type='button'
							onClick={() => {
								setStep("contact");
								localStorage.setItem("quickBookingStep", "contact");
							}}
							style={{
								padding: "10px",
								backgroundColor: "black",
								color: "white",
								border: "none",
								borderRadius: "4px",
								cursor: "pointer",
							}}>
							&#8592; Back
						</button>
						<button
							type='button'
							style={{
								padding: "10px",
								backgroundColor: "black",
								color: "white",
								border: "none",
								borderRadius: "4px",
								cursor: "pointer",
							}}
							onClick={() => {
								reactLocalStorage.clear("vehicles");
								reactLocalStorage.clear("rideDetails");
								reactLocalStorage.clear("contactDetails");
								reactLocalStorage.clear("importantOpions");
								reactLocalStorage.clear("client");
								reactLocalStorage.clear("quickBookingStep");
								window.location.href = "/dashboard/QuickBooking";
							}}>
							Reset  &#x21bb;
						</button>
						<button
							onClick={() => {
								reactLocalStorage.setObject("rideDetails", rides);
								setStep("choosevehicle");
								localStorage.setItem("quickBookingStep", "choosevehicle");
							}}
							style={{
								padding: "10px",
								backgroundColor: "black",
								color: "white",
								border: "none",
								borderRadius: "4px",
								cursor: "pointer",
							}}>
							RIDE DETAILS &#8594;
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}

const RidesTable = ({ rides, setRides, setStep }) => {
	const [editRideId, setEditRideId] = useState(null);
	const [editedRide, setEditedRide] = useState({});

	const handleEdit = (ride) => {
		setEditRideId(ride.rideId);
		setEditedRide(ride);
	};

	const handleChange = (field) => (e) => {
		setEditedRide((prev) => ({
			...prev,
			[field]: e.target.value,
		}));
	};

	const handleUpdate = () => {
		setRides((prev) =>
			prev.map((ride) => (ride.rideId === editRideId ? editedRide : ride)),
		);
		setEditRideId(null);
		setEditedRide({});
	};

	const handleDelete = (rideId) => {
		setRides((prev) => prev.filter((ride) => ride.rideId !== rideId));
	};

	const rideTypes = [
		"Distance",
		"Hourly",
		"Flat Rate",
		"Chauffeur Services",
		"Airport Service",
	];

	const fieldMapping = {
		Distance: ["time1", "picupLocation1", "dropOffLocation1", "extraTime1"],
		Hourly: ["time2", "picupLocation2", "dropOffLocation2", "duration2"],
		"Flat Rate": ["time3", "transferType3", "duration3", "picupLocation3"],
		"Chauffeur Services": [
			"time4",
			"transferType4",
			"duration4",
			"picupLocation4",
		],
		"Airport Service": [
			"time5",
			"picupLocation5",
			"airport",
			"airline",
			"flightNo",
			"terminal",
			"flightTime",
			"flightStatus",
			"origin",
		],
	};

	// Filter to only show ride types that have data
	const availableRideTypes = rideTypes.filter((type) =>
		rides.some((ride) => ride.rideType === type),
	);

	return (
		<div style={{ maxWidth: "90vw", overflowX: "auto" }}>
			{availableRideTypes.map((type) => (
				<div key={type} style={{ marginBottom: "5px" }}>
					<h3 style={{ fontWeight: "500", margin: "0px", color: "#333" }}>
						{type} Rides
					</h3>
					<table>
						<thead>
							<tr>
								{fieldMapping[type].map((field) => (
									<th
										key={field}
										style={{
											border: "2px solid #ddd",
											padding: "5px",
											textTransform: "capitalize",
											color: "#555",
											fontWeight: "bold",
											fontSize: "12px",
										}}>
										{field.replace(/[0-9]/g, "").replace(/([A-Z])/g, " $1")}
									</th>
								))}
								<th
									style={{
										border: "2px solid #ddd",
										padding: "5px",
										color: "#555",
										fontWeight: "bold",
										fontSize: "12px",
									}}>
									Actions
								</th>
							</tr>
						</thead>
						<tbody>
							{rides
								.filter((ride) => ride.rideType === type)
								.map((ride) => (
									<tr key={ride.rideId} style={{ backgroundColor: "#fff" }}>
										{fieldMapping[type].map((field) => (
											<td
												key={field}
												style={{
													border: "1px solid #ddd",
													padding: "5px",
													fontSize: "12px",
													color: "#333",
												}}>
												{editRideId === ride.rideId ? (
													// Input elements based on field type
													field.includes("duration") ? (
														<select
															value={editedRide[field] || ride[field] || ""}
															onChange={handleChange(field)}
															style={{
																padding: "6px",
																borderRadius: "4px",
																border: "1px solid #ccc",
																fontSize: "13px",
															}}>
															<option value=''>Select</option>
															{[1, 2, 3, 4, 5].map((hour) => (
																<option key={hour} value={`${hour} HOUR`}>
																	{hour} HOUR
																</option>
															))}
														</select>
													) : field.includes("time") ? (
														<LocalizationProvider dateAdapter={AdapterDateFns}>
															<div
																style={{
																	display: "flex",
																	alignItems: "center",
																	gap: "5px",
																}}>
																<DesktopDatePicker
																	inputFormat='MM/dd/yyyy'
																	value={editedRide[field]}
																	onChange={(date) => {
																		setEditedRide((prev) => ({
																			...prev,
																			[field]: new Date(date).toLocaleString(),
																		}));
																	}}
																	renderInput={(params) => (
																		<TextField
																			sx={{
																				"& .MuiInputBase-root input": {
																					py: 0.3,
																					width: "200px",
																				},
																			}}
																			required
																			fullWidth
																			{...params}
																		/>
																	)}
																/>
																<TimePicker
																	ampm={false}
																	value={editedRide[field]}
																	onChange={(time) => {
																		setEditedRide((prev) => ({
																			...prev,
																			[field]: new Date(time).toLocaleString(),
																		}));
																	}}
																	renderInput={(params) => (
																		<TextField
																			sx={{
																				"& .MuiInputBase-root input": {
																					py: 0.3,
																				},
																			}}
																			required
																			fullWidth
																			{...params}
																		/>
																	)}
																/>
															</div>
														</LocalizationProvider>
													) : (
														<input
															type='text'
															value={editedRide[field] || ride[field] || ""}
															onChange={handleChange(field)}
															style={{
																padding: "6px",
																borderRadius: "4px",
																border: "1px solid #ccc",
																fontSize: "13px",
															}}
														/>
													)
												) : (
													ride[field] || ""
												)}
											</td>
										))}
										<td
											style={{
												border: "1px solid #ddd",
												padding: "8px",
												display: "flex",
												justifyContent: "center",
											}}>
											{editRideId === ride.rideId ? (
												<button
													onClick={handleUpdate}
													style={{
														backgroundColor: "#4CAF50",
														color: "white",
														padding: "6px 10px",
														borderRadius: "4px",
														marginRight: "5px",
														fontSize: "13px",
													}}>
													Update
												</button>
											) : (
												<button
													onClick={() => handleEdit(ride)}
													style={{
														backgroundColor: "#007BFF",
														color: "white",
														padding: "6px 10px",
														borderRadius: "4px",
														marginRight: "5px",
														fontSize: "13px",
													}}>
													Edit
												</button>
											)}
											<button
												onClick={() => handleDelete(ride.rideId)}
												style={{
													backgroundColor: "#FF4C4C",
													color: "white",
													padding: "6px 10px",
													borderRadius: "4px",
													fontSize: "13px",
												}}>
												Delete
											</button>
										</td>
									</tr>
								))}
						</tbody>
					</table>
				</div>
			))}
		</div>
	);
};
