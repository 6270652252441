import { Box, CardMedia, Container, Grid, Typography } from "@mui/material";
import React from "react";
import images from "../ImportImage";

const Places = () => {
	const allPlaces = [
		{
			id: 1,
			name: "DETROIT AIRPORT",
			image: images?.detroitRiver,
			animation: "flip-left",
		},
		{
			id: 2,
			name: "CASINO WINDSOR",
			image: images?.casinoWindsor,
			animation: "flip-up",
		},
		{
			id: 3,
			name: "DETROIT DOWN TOWN",
			image: images?.detroitDownTown,
			animation: "flip-right",
		},
	];
	return (
		<Container sx={{ py: 7 }}>
			<Grid container spacing={2}>
				{allPlaces?.map((place, i) => (
					<Grid key={i} item xs={12} md={4} data-aos={place?.animation}>
						<Typography
							sx={{ fontWeight: "bold", textAlign: "left", mb: 2 }}
							className='textColor'
							variant='h5'
							component='div'
							gutterBottom>
							{place?.name.toUpperCase()}
						</Typography>
						<Box
							sx={{
								overflow: "hidden",
							}}>
							<CardMedia
								component='img'
								image={place?.image}
								alt=''
								sx={{
									width: "100%",
									transition: "transform .5s",
									"&:hover": {
										transform: "scale(1.1)",
										transition: "transform .5s",
									},
								}}
							/>
						</Box>
					</Grid>
				))}
			</Grid>
		</Container>
	);
};

export default Places;
