import * as React from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Button, Container, Grid } from "@mui/material";
import Tab1 from "./Tab1";
import Tab2 from "./Tab2";
import Tab3 from "./Tab3";
import { reactLocalStorage } from "reactjs-localstorage";
import Step1 from "../Steps/Step1";
import Tab4 from "./Tab4";
import Tab5 from "./Tab5";
import { Link, useNavigate } from "react-router-dom";
import SendIcon from "@mui/icons-material/Send";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function a11yProps(index) {
	return {
		id: `full-width-tab-${index}`,
		"aria-controls": `full-width-tabpanel-${index}`,
	};
}

export default function RideDetails() {
	const [rides, setRides] = React.useState([]);
	const [loadaed, setLoaded] = React.useState(false);
	React.useEffect(() => {
		const data = reactLocalStorage.getObject("rideDetails") || [];
		if (data?.length > 0 && !loadaed) {
			setRides(data);
			setLoaded(true);
		}
	}, [loadaed]);
	console.log("🚀 ~ RideDetails ~ rides:", rides);

	const navigate = useNavigate();
	const destination = "/choosevehicle";

	const handleNext = () => {
		reactLocalStorage.setObject("rideDetails", rides);
		navigate(destination);
	};

	return (
		<Container>
			<Box sx={{ my: 2.5 }}>
				<Step1 />
			</Box>
			<Grid container spacing={2} sx={{ mb: 3 }}>
				<Grid item md={12} xs={12} sx={{ mx: "auto" }}>
					<Box sx={{ bgcolor: "background.paper" }}>
						<Box
							className='headline'
							sx={{
								width: "100%",
								py: 1.5,
							}}>
							<Typography variant='body' component='div'>
								RIDE DETAILS
							</Typography>
						</Box>

						{rides?.length < 1 && (
							<Button
								onClick={() => {
									setRides([
										...rides,
										{
											rideId: Math.round(Math.random() * 10000),
										},
									]);
								}}
								className='buttonColor'
								variant='contained'
								sx={{
									py: 1.5,
									mb: 2,
								}}>
								ADD A RIDE
							</Button>
						)}

						{rides?.map((ride) => (
							<SIngleRide rides={rides} setRides={setRides} ride={ride} />
						))}
					</Box>
				</Grid>
				<Grid item md={6} xs={12}>
					<Link to='/contactdetails' style={{ textDecoration: "none" }}>
						<Button
							className='buttonColor'
							variant='contained'
							sx={{ width: "100%", py: 1.7 }}>
							<ArrowBackIcon sx={{ mr: 1 }} />
							Back
						</Button>
					</Link>
				</Grid>
				{rides?.length > 0 && (
					<Grid item md={6} xs={12}>
						<Button
							onClick={handleNext}
							className='buttonColor'
							sx={{ width: "100%", py: 1.7 }}
							variant='contained'
							endIcon={<SendIcon />}>
							CHOOSE A VEHICLE
						</Button>
					</Grid>
				)}
			</Grid>
		</Container>
	);
}

const SIngleRide = ({ rides, setRides, ride }) => {
	const theme = useTheme();
	const [value, setValue] = React.useState(ride?.tab || 0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const handleChangeIndex = (index) => {
		setValue(index);
	};

	const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

	return (
		<Box
			sx={{
				borderBottom: "2px solid black",
				p: 1,
				position: "relative",
			}}>
			<Button
				onClick={async () => {
					const data = rides.filter((r) => r.rideId !== ride.rideId);
					await delay(0);
					setRides([]);
					await delay(100);
					setRides(data);
				}}
				variant='contained'
				sx={{
					position: "absolute",
					top: -9,
					right: 0,
					py: 0.5,
					px: 1,
					fontSize: "0.7rem",
					zIndex: 1,
					backgroundColor: "red",
				}}>
				DELETE
			</Button>
			<AppBar position='static' className='bgColor'>
				<Tabs
					value={value}
					onChange={handleChange}
					indicatorColor='secondary'
					textColor='inherit'
					variant='scrollable'
					scrollButtons
					allowScrollButtonsMobile
					aria-label='full width tabs example'
					className='bgColor'
					sx={{
						mx: "auto",
						width: "100%",
					}}>
					<Tab label='DISTANCE' {...a11yProps(0)} />
					<Tab label='HOURLY' {...a11yProps(1)} />
					<Tab label='FLAT RATE' {...a11yProps(2)} />
					<Tab label='CHAUFFEUR SERVICES' {...a11yProps(3)} />
					<Tab label='AIRPORT SERVICES' {...a11yProps(4)} />
				</Tabs>
			</AppBar>
			<SwipeableViews
				axis={theme.direction === "rtl" ? "x-reverse" : "x"}
				index={value}
				onChangeIndex={handleChangeIndex}>
				<TabPanel value={value} index={0} dir={theme.direction}>
					<Tab1 rides={rides} setRides={setRides} ride={ride} />
				</TabPanel>
				<TabPanel value={value} index={1} dir={theme.direction}>
					<Tab2 rides={rides} setRides={setRides} ride={ride} />
				</TabPanel>
				<TabPanel value={value} index={2} dir={theme.direction}>
					<Tab3 rides={rides} setRides={setRides} ride={ride} />
				</TabPanel>
				<TabPanel value={value} index={3} dir={theme.direction}>
					<Tab4 rides={rides} setRides={setRides} ride={ride} />
				</TabPanel>
				<TabPanel value={value} index={4} dir={theme.direction}>
					<Tab5 rides={rides} setRides={setRides} ride={ride} />
				</TabPanel>
			</SwipeableViews>
		</Box>
	);
};
