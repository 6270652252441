import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { Backdrop, CircularProgress, Grid } from "@mui/material";
import axios from "axios";
import Swal from "sweetalert2";

export default function Signup() {
	const [submitting, setSubmitting] = React.useState(false);
	const { register, handleSubmit } = useForm();
	const onSubmit = async (data) => {
		setSubmitting(true);
		const userData = { ...data, userRole: "User", joinedDate: new Date() };
		try {
			const response = await axios.post(
				`${process.env.REACT_APP_API_PATH}/signup`,
				userData,
			);

			if (response.status === 201) {
				Swal.fire({
					icon: "success",
					title: "Success",
					text: "User created successfully. Please login to continue.",
				});
				window.location.href = "/login";
			}
			setSubmitting(false);
		} catch (error) {
			Swal.fire({
				icon: "error",
				title: "Error",
				text: error.response.data.message,
			});
			setSubmitting(false);
		}
	};
	return (
		<Container
			component='main'
			maxWidth='xs'
			sx={{
				minHeight: "50vh",
			}}>
			<CssBaseline />
			<Box
				sx={{
					marginTop: 8,
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
				}}>
				<Avatar sx={{ m: 1 }} className='buttonColor'>
					<LockOutlinedIcon />
				</Avatar>
				<Typography component='h1' variant='h5'>
					Sign Up
				</Typography>
				<form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: 3 }}>
					<TextField
						size='small'
						margin='normal'
						required
						fullWidth
						id='displayName'
						label='Your Name'
						name='displayName'
						autoComplete='displayName'
						autoFocus
						{...register("displayName", { required: true })}
					/>
					<TextField
						size='small'
						margin='normal'
						required
						fullWidth
						id='phone'
						label='Phone Number'
						name='phone'
						autoComplete='phone'
						autoFocus
						{...register("phone", { required: true })}
					/>
					<TextField
						size='small'
						margin='normal'
						required
						fullWidth
						id='email'
						label='Email Address'
						name='email'
						autoComplete='email'
						autoFocus
						{...register("email", { required: true })}
					/>
					<TextField
						size='small'
						margin='normal'
						required
						fullWidth
						name='password'
						label='Password'
						type='password'
						id='password'
						autoComplete='current-password'
						{...register("password", { required: true })}
					/>
					<Grid item xs>
						<Link to='/login' variant='body2'>
							Already have an account? Sign In
						</Link>
					</Grid>
					<Button
						type='submit'
						fullWidth
						variant='contained'
						className='buttonColor'
						sx={{
							my: 2,
						}}>
						Sign Up
					</Button>
				</form>
			</Box>
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={submitting}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</Container>
	);
}
