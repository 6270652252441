import {
	Box,
	Button,
	Container,
	Grid,
	TextField,
	Typography,
} from "@mui/material";
import React from "react";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
import axios from "axios";
import Swal from "sweetalert2";

const ContactUs = () => {
	const onSubmit = async (e) => {
		e.preventDefault();
		const datas = {
			name: e.target.name.value,
			email: e.target.email.value,
			subject: e.target.subject.value,
			message: e.target.message.value,
			submittedOn: new Date().toLocaleString("en-GB"),
		};
		try {
			const response = await axios.post(
				`${process.env.REACT_APP_API_PATH}/emails`,
				datas,
			);

			if (response.status === 201) {
				Swal.fire({
					icon: "success",
					title: "Success",
					text: "Email sent successfully.",
				});
				e.target.reset();
			}
		} catch (error) {
			Swal.fire({
				icon: "error",
				title: "Error",
				text: error.response.data.message,
			});
		}
	};

	return (
		<Container sx={{ py: 5, textAlign: "left" }}>
			<Grid container spacing={2}>
				<Grid item xs={12} md={8} data-aos='fade-left'>
					<Typography
						sx={{ fontWeight: "bold", mb: 2 }}
						className='textColor'
						variant='h4'
						component='div'
						gutterBottom>
						Contact Us
					</Typography>
					<Typography
						sx={{ lineHeight: 2, textAlign: "justify" }}
						className='textColor'
						variant='subtitle2'
						component='div'
						gutterBottom>
						We welcome every visitor to the website that has been designed by
						us. We look forward to providing you maximum assistance in your
						mission to find the best organization that addresses your needs
						accordingly. Pearson Limo Team have a strong belief that we are
						certain to be the businesses that would be preferred by the users.
						We provide an unparalleled level of customer service that has a
						combination of suitable pricing levels. We aim to be the no.1
						service in our niche.
					</Typography>
					<Typography
						sx={{ lineHeight: 2, mt: 2, textAlign: "justify" }}
						className='textColor'
						variant='subtitle2'
						component='div'
						gutterBottom>
						Elegance limo Team has met the needs of Windsor and Essex areas for
						several years and now wants to earn more business targets. Our loyal
						customers have loved the personalized services provided by us, as it
						has made it possible for them to come back each time whenever a new
						issue arose. We treat our customers as a part of our big family.
					</Typography>
					<Typography
						sx={{ fontWeight: "bold", mt: 5 }}
						className='textColor'
						variant='h4'
						component='div'
						gutterBottom>
						Please fulfil the form below.
					</Typography>
					<form onSubmit={onSubmit}>
						<TextField
							required
							placeholder='Name'
							type='text'
							variant='outlined'
							fullWidth
							size='small'
							name='name'
							sx={{ my: 1 }}
						/>
						<TextField
							required
							placeholder='Email'
							type='email'
							variant='outlined'
							fullWidth
							size='small'
							name='email'
							sx={{ my: 1 }}
						/>
						<TextField
							required
							placeholder='Subject'
							type='text'
							variant='outlined'
							fullWidth
							size='small'
							name='subject'
							sx={{ my: 1 }}
						/>
						<TextField
							required
							placeholder='Message'
							type='text'
							variant='outlined'
							fullWidth
							size='small'
							multiline
							rows={5}
							name='message'
							sx={{ my: 1 }}
						/>
						<Button
							type='submit'
							variant='contained'
							disableElevation
							sx={{
								backgroundColor: "#000000",
								color: "white",
								fontWeight: "bold",
								"&:hover": {
									backgroundColor: "#000000",
								},
								py: 1.5,
								width: "100px",
							}}>
							SEND
						</Button>
					</form>
				</Grid>
				<Grid item xs={12} md={4} data-aos='fade-right'>
					<Box sx={{ mb: 5 }}>
						<Typography
							sx={{ fontWeight: "bold" }}
							className='textColor'
							variant='subtitle1'
							component='div'
							gutterBottom>
							Before Contacting Us
						</Typography>
						<Typography
							sx={{ lineHeight: 2, textAlign: "justify" }}
							className='textColor'
							variant='subtitle2'
							component='div'
							gutterBottom>
							You may contact us by filling in this form any time you need
							professional support or have any questions. You can also fill in
							the form to leave your comments or feedback
						</Typography>
					</Box>
					<Box sx={{ mb: 5 }}>
						<Typography
							sx={{ fontWeight: "bold" }}
							className='textColor'
							variant='subtitle1'
							component='div'
							gutterBottom>
							Contact Information
						</Typography>
						<Typography
							sx={{ lineHeight: 2, textAlign: "justify" }}
							className='textColor'
							variant='subtitle2'
							component='div'
							gutterBottom>
							Address: <br />
							Elegance Limo Services <br />
							515 Riverside Dr W, Windsor, ON N9A 5K3 <br />
						</Typography>
					</Box>
					<Box sx={{ mb: 5 }}>
						<Typography
							sx={{ fontWeight: "bold" }}
							className='textColor'
							variant='subtitle1'
							component='div'
							gutterBottom>
							For Reservations
						</Typography>
						<Box
							sx={{
								display: "flex",
								justifyContent: "flex-start",
								cursor: "pointer",
								mt: 2,
							}}
							onClick={() => window.open(`tel:519-969-4444`)}>
							<CallIcon sx={{ mr: 0.7 }} />
							<Typography
								variant='body2'
								gutterBottom
								sx={{ color: "#9B9B9B" }}>
								519-969-4444
							</Typography>
						</Box>
						{/* <Box
							sx={{
								display: "flex",
								justifyContent: "flex-start",
								cursor: "pointer",
								mt: 1,
							}}
							onClick={() => window.open(`tel:1-888-969-0045`)}>
							<CallIcon sx={{ mr: 0.7 }} />
							<Typography
								variant='body2'
								gutterBottom
								sx={{ color: "#9B9B9B" }}>
								Toll free : 1-888-969-0045
							</Typography>
						</Box> */}
						<Box
							sx={{
								display: "flex",
								justifyContent: "flex-start",
								cursor: "pointer",
								mt: 1,
							}}
							onClick={() => window.open(`mailto:info@elegancelimo.ca`)}>
							<EmailIcon sx={{ mr: 0.7 }} />
							<Typography
								variant='body2'
								gutterBottom
								sx={{ color: "#9B9B9B" }}>
								info@elegancelimo.ca
							</Typography>
						</Box>
					</Box>
					<Box sx={{ mb: 5 }}>
						<Typography
							sx={{ fontWeight: "bold" }}
							className='textColor'
							variant='subtitle1'
							component='div'
							gutterBottom>
							Social Media
						</Typography>
						<Box>
							<FacebookIcon
								fontSize='large'
								sx={{ mx: 0.5 }}
								onClick={() =>
									window.open(
										`https://www.facebook.com/profile.php?id=100063715848744`,
									)
								}
							/>
							<TwitterIcon fontSize='large' sx={{ mx: 0.5 }} />
							<InstagramIcon fontSize='large' sx={{ mx: 0.5 }} />
						</Box>
					</Box>
				</Grid>
				<Grid item xs={12} md={12} sx={{ my: 5 }}>
					<Grid container spacing={2}>
						<Grid item xs={12} md={4} data-aos='flip-left'>
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
									alignItems: "center",
									backgroundColor: "#F5F5F5",
									py: 3,
									height: "100%",
								}}>
								<Box
									sx={{
										backgroundColor: "#E2C474",
										width: "max-content",
										borderRadius: "50%",
										p: 1.5,
										display: "flex",
										justifyContent: "center",
									}}>
									<EmailIcon sx={{ color: "white", fontSize: "40px" }} />
								</Box>
								<Typography
									sx={{ fontWeight: "bold", my: 2 }}
									className='textColor'
									variant='h6'
									component='div'
									gutterBottom>
									Contact By Email
								</Typography>
								<Typography
									sx={{ color: "#9B9B9B" }}
									variant='subtitle2'
									component='div'
									onClick={() => window.open(`mailto:info@elegancelimo.ca`)}>
									info@elegancelimo.ca
								</Typography>
							</Box>
						</Grid>
						<Grid item xs={12} md={4} data-aos='flip-up'>
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
									alignItems: "center",
									backgroundColor: "#F5F5F5",
									py: 3,
									height: "100%",
								}}>
								<Box
									sx={{
										backgroundColor: "#E2C474",
										width: "max-content",
										borderRadius: "50%",
										p: 1.5,
										display: "flex",
										justifyContent: "center",
									}}>
									<CallIcon sx={{ color: "white", fontSize: "40px" }} />
								</Box>
								<Typography
									sx={{ fontWeight: "bold", my: 2 }}
									className='textColor'
									variant='h6'
									component='div'
									gutterBottom>
									Contact By Phone
								</Typography>
								<Typography
									sx={{ color: "#9B9B9B" }}
									variant='subtitle2'
									component='div'
									onClick={() => window.open(`tel:519-969-4444`)}>
									519-969-4444
								</Typography>
								{/* <Typography
									sx={{ color: "#9B9B9B" }}
									variant='subtitle2'
									component='div'
									onClick={() => window.open(`tel:888-969-0045`)}>
									888-969-0045
								</Typography> */}
							</Box>
						</Grid>
						<Grid item xs={12} md={4} data-aos='flip-right'>
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
									alignItems: "center",
									backgroundColor: "#F5F5F5",
									py: 3,
									height: "100%",
								}}>
								<Box
									sx={{
										backgroundColor: "#E2C474",
										width: "max-content",
										borderRadius: "50%",
										p: 1.5,
										display: "flex",
										justifyContent: "center",
									}}>
									<MapsHomeWorkIcon sx={{ color: "white", fontSize: "40px" }} />
								</Box>
								<Typography
									sx={{ fontWeight: "bold", my: 2 }}
									className='textColor'
									variant='h6'
									component='div'
									gutterBottom>
									Come To See Us
								</Typography>
								<Typography
									sx={{ color: "#9B9B9B" }}
									variant='subtitle2'
									component='div'>
									515 Riverside Dr W, Windsor, ON N9A 5K3
								</Typography>
							</Box>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Container>
	);
};

export default ContactUs;
