import React, { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { useData } from "../../../contex/contex";
import {
	Container,
	CssBaseline,
	Avatar,
	Typography,
	TextField,
	Button,
	Backdrop,
	CircularProgress,
	Select,
	MenuItem,
	InputLabel,
	FormControl,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

const Profile = () => {
	const [editing, setEditing] = useState(false);
	const [loading, setLoading] = useState(false);
	const [userData, setUserData] = useState({
		displayName: "",
		phone: "",
		email: "",
		password: "",
	});

	const { user, token } = useData();

	const [haveCompany, setHaveCompany] = useState({});
	const [userType, setUserType] = useState("");
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(
					`${process.env.REACT_APP_API_PATH}/companyAdmin/${user?.email}`,
					{
						headers: {
							Authorization: token,
						},
					},
				);

				setHaveCompany(response.data);
				if (response.data?.email && !response.data?.userType) {
					Swal.fire({
						icon: "error",
						title: "Error",
						text: "Please select user type first.",
					});
				}
			} catch (error) {
				console.log(error);
			}
		};
		token && fetchData();
	}, [token, user?.email, loading]);

	const [submitting /* setSubmitting */] = useState(false);
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(
					`${process.env.REACT_APP_API_PATH}/userByEmail/${user?.email}`,
					{
						headers: {
							Authorization: token,
						},
					},
				);

				setUserData(response?.data);
				setUserType(response?.data?.userType);
			} catch (error) {
				Swal.fire({
					icon: "error",
					title: "Error",
					text: error.response.data.message,
				});
			}
		};
		user?.email && token && fetchData();
	}, [token, submitting, user]);

	const handleEditClick = () => {
		setEditing(true);
	};

	const handleCancelClick = () => {
		setEditing(false);
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		setUserData((prevData) => ({
			...prevData,
			[name]: value,
		}));
	};

	const onSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);
		try {
			axios
				.patch(
					`${process.env.REACT_APP_API_PATH}/updateProfile`,
					{
						displayName: userData.displayName,
						phone: userData.phone,
						email: userData.email,
						userType: userData.userType,
					},
					{
						headers: {
							Authorization: token,
						},
					},
				)
				.then((res) => {
					Swal.fire({
						icon: "success",
						title: "Success",
						text: res.data.message,
					});
				})
				.catch((err) => {
					Swal.fire({
						icon: "error",
						title: "Error",
						text: err.response.data.message,
					});
				});

			Swal.fire({
				icon: "success",
				title: "Success",
				text: "Profile updated successfully.",
			});
			setEditing(false);
		} catch (error) {
			Swal.fire({
				icon: "error",
				title: "Error",
				text: error.response.data.message,
			});
		}

		setLoading(false);
	};

	const [company, setCompany] = useState("");

	return (
		<Container component='main' maxWidth='xs'>
			<CssBaseline />
			<div
				style={{
					marginTop: 8,
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
				}}>
				<Avatar sx={{ m: 1 }} className='buttonColor'>
					<LockOutlinedIcon />
				</Avatar>
				<Typography component='h1' variant='h5'>
					Profile
				</Typography>
				<form onSubmit={onSubmit} style={{ marginTop: 3 }}>
					<TextField
						size='small'
						margin='normal'
						required
						fullWidth
						id='displayName'
						label='Your Name'
						name='displayName'
						autoComplete='displayName'
						autoFocus
						value={userData.displayName}
						onChange={handleChange}
						disabled={!editing}
					/>
					<TextField
						size='small'
						margin='normal'
						fullWidth
						id='phone'
						label='Your Phone Number'
						name='phone'
						autoComplete='phone'
						autoFocus
						value={userData.phone}
						onChange={handleChange}
						disabled={!editing}
					/>
					<TextField
						size='small'
						margin='normal'
						required
						fullWidth
						id='email'
						label='Your Email Address'
						name='email'
						autoComplete='email'
						autoFocus
						value={userData.email}
						onChange={handleChange}
						disabled={true}
					/>

					<FormControl
						size='small'
						sx={{
							width: "100%",
							marginTop: 1,
							marginBottom: 1,
						}}>
						<InputLabel id='userType'>Choose User Type</InputLabel>
						<Select
							size='small'
							margin='normal'
							required
							fullWidth
							id='userType'
							label='Choose User Type'
							name='userType'
							autoComplete='userType'
							autoFocus
							value={userData.userType || ""}
							defaultValue={userData.userType}
							onChange={handleChange}
							disabled={!editing || userType === "Company"}>
							<MenuItem value=''>Select User Type</MenuItem>
							<MenuItem value='Personal'>Personal</MenuItem>
							<MenuItem value='Company'>Company</MenuItem>
						</Select>
					</FormControl>
					{userData.userType === "Company" && haveCompany && (
						<TextField
							size='small'
							margin='normal'
							required
							fullWidth
							id='company'
							label='Your Company Name'
							name='company'
							autoComplete='company'
							autoFocus
							value={haveCompany.name}
							disabled={true}
						/>
					)}
					{userData.userType === "Company" && haveCompany && (
						<Typography
							sx={{
								marginTop: 1,
								marginBottom: 1,
								fontWeight: "bold",
							}}
							variant='h6'
							component='div'
							gutterBottom>
							Company Code : {haveCompany.secretCode}
						</Typography>
					)}
					{userData.userType === "Company" && !haveCompany && (
						<>
							<TextField
								size='small'
								margin='normal'
								required
								fullWidth
								id='company'
								label='Your Company Name'
								name='company'
								autoComplete='company'
								autoFocus
								value={company}
								onChange={(e) => setCompany(e.target.value)}
								disabled={!editing}
							/>
							<Button
								disabled={!editing}
								size='small'
								margin='normal'
								fullWidth
								className='buttonColor'
								sx={{
									color: "white",
									marginTop: 1,
								}}
								onClick={async () => {
									const datas = {
										time: new Date().toLocaleString(),
										name: company,
										companyAdmin: user?.email,
									};
									setLoading(true);
									try {
										const response = await axios.post(
											`${process.env.REACT_APP_API_PATH}/companylist`,
											datas,
										);

										if (response.status === 201) {
											setLoading(false);
											Swal.fire({
												icon: "success",
												title: "Company Added Successfully",
												showConfirmButton: false,
												timer: 1500,
											});
										}
									} catch (error) {
										Swal.fire({
											icon: "error",
											title: "Error",
											text: error.response.data.message,
										});
									}
								}}>
								Submit Company
							</Button>
						</>
					)}

					{editing ? (
						<>
							<Button
								type='submit'
								fullWidth
								variant='contained'
								className='buttonColor'
								sx={{ my: 2 }}>
								Save Changes
							</Button>
							<Button
								fullWidth
								variant='outlined'
								onClick={handleCancelClick}
								sx={{ my: 2 }}>
								Cancel
							</Button>
						</>
					) : (
						<Button
							fullWidth
							variant='outlined'
							onClick={handleEditClick}
							sx={{ my: 2 }}>
							Edit Profile
						</Button>
					)}
				</form>
			</div>
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={loading}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</Container>
	);
};

export default Profile;
